import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { ISettings } from '../../../../core/interfaces/customer/settings';

@Component({
  selector: 'app-dialog-calendar-with-time-slots',
  templateUrl: './calendar-with-time-slots.component.html',
  styleUrls: ['./calendar-with-time-slots.component.scss']
})
export class CalendarWithTimeSlotsComponent implements OnInit {
  public settings: ISettings = null;
  public appointment: IAppointment = null;
  public allSelectedOperators: IOperator[] = [];
  public allOfficeOperatorWorkSchedule: IOfficeOperatorWorkSchedule[] = [];

  constructor(public dialogRef: MatDialogRef<CalendarWithTimeSlotsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (data == null) {
      return;
    }

    this.settings = data.settings;
    this.appointment = data.appointment;
    this.allSelectedOperators = data.allSelectedOperators;
    this.allOfficeOperatorWorkSchedule = data.allOfficeOperatorWorkSchedule;
  }

  ngOnInit() {
  }

  onEditedData($event) {
    this.dialogRef.close($event);
  }
}
