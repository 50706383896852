import { createAction, props } from '@ngrx/store';
import { Language } from '../models/language.model';

export const SET_LANGUAGE = "[Language] Set";

export const set = createAction(
  SET_LANGUAGE,
  props<{ language: Language }>()
);




