import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ExceptionDayType } from '../../../core/constants/exception-day.constants';
import { ExceptionDayDataSource } from '../../../core/datasource/customer/exception-day.datasource';
import { GetDayWithoutTime } from '../../../core/functions/date-function';
import { IFilterGridData } from '../../../core/interfaces/common/filter-grid-data';
import { IExceptionDay } from '../../../core/interfaces/customer/exception-day';
import { ExceptionDayService } from '../../../core/services/customer/exception-day.service';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';
import { ExceptionDaysCalendarComponent } from '../dialogs/exception-days-calendar/exception-days-calendar.component';
import { FilterGridFormService } from '../filter-grid/filter-grid-form.service';

@Component({
  selector: 'app-exception-days',
  templateUrl: './exception-days.component.html',
  styleUrls: ['./exception-days.component.scss'],
  providers: [
    ExceptionDayService,
    FilterGridFormService
  ]
})
export class ExceptionDaysComponent implements OnInit {
  @Input() officeId: string = null;
  @Input() operatorId: string = null;
  @Input() type: string = null;

  public dataSource: ExceptionDayDataSource | null;

  public exceptionDays: IExceptionDay[] = [];

  public exceptionDaySbj: BehaviorSubject<IExceptionDay[]> = new BehaviorSubject<IExceptionDay[]>([]);

  public displayedColumns: string[] = ["Date", "Time", "Actions"];

  public loading: boolean = false;

  public page: number = 1;
  public pageSize: number = 20;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public filterArray: IFilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;
  public totalItems: number;

  public today: Date = new Date();

  public ExceptionDayType = ExceptionDayType;

  public showOfficeInfo: boolean = false;
  public componentLoaded: boolean = false;

  constructor(
    readonly translate: TranslateService,
    private exceptionDayService: ExceptionDayService,
    private dialog: MatDialog,
    private filterGridFormService: FilterGridFormService
  ) { }

  ngOnInit() {
    if (this.officeId == null && this.type == ExceptionDayType.Office) {
      this.showOfficeInfo = true;
      this.componentLoaded = true;

      return;
    }

    this.dataSource = new ExceptionDayDataSource(this.exceptionDaySbj);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p === null) {
        return;
      }

      this.loadExceptionDays();
    });

    this.loadExceptionDays();
  }

  loadExceptionDays() {
    this.loading = true;
    this.page = this.page !== null ? this.page : 1;

    if (this.type != null) {
      const typeFilter = this.filterGridFormService.createFilter('String', 'Type', 'Equals', this.type);
      this.filterArray.push(typeFilter);
    }

    if (this.officeId != null) {
      const officeFilter = this.filterGridFormService.createFilter('String', 'OfficeId', 'Equals', this.officeId);
      this.filterArray.push(officeFilter);
    }

    if (this.operatorId != null) {
      const operatorFilter = this.filterGridFormService.createFilter('String', 'OperatorId', 'Equals', this.operatorId);
      this.filterArray.push(operatorFilter);
    }

    const request = this.filterGridFormService.createRequest(true, null, this.filterArray, null, false,
      this.page, this.pageSize, this.sortDirection, this.sortField);

    this.exceptionDayService.getExceptionDaysWithRequest(request).subscribe({
      next: data => {
        data.data.filter(x => !x.isAllDay).forEach(x => {
          x.startHour = x.startHour.toString().slice(0, 5);
          x.endHour = x.endHour.toString().slice(0, 5);
        });

        this.exceptionDaySbj.next(data.data);
        this.totalItems = data.total;
        this.loading = false;
        this.componentLoaded = true;
      }
    });
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  addFilterComponent(event: IFilterGridData) {
    if (event.value === null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName !== event.propertyName);
    }
    else {
      const existingFilter = this.filterArray.filter(x => x.propertyName === event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName !== event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.page = 1;
    this.loadExceptionDays();
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.loadExceptionDays();
  }

  edit(date: Date) {
    this.openExceptionDayCalendarDialog(date);
  }

  openExceptionDayCalendarDialog(date: Date) {
    const data = {
      officeId: this.officeId,
      operatorId: this.operatorId,
      type: this.type,
      date: date
    };

    const dialogRef = this.dialog.open(ExceptionDaysCalendarComponent, {
      width: "50%",
      data: data
    });

    dialogRef.afterClosed().subscribe(response => {
      if (!response) {
        return;
      }

      this.loadExceptionDays();
    });
  }

  openDeleteDialog(element: IExceptionDay) {
    let dateMessage = `${GetDayWithoutTime(new Date(element.date))}`;
    if (!element.isAllDay) {
      dateMessage += `, ${element.startHour}-${element.endHour}`;
    }

    this.translate.get("confirmation.deleteExceptionQuestion").subscribe(x => {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        minWidth: "25rem",
        minHeight: "7rem",
        data: `${x} ${dateMessage}?`
      });

      dialogRef.afterClosed().subscribe(response => {
        if (!response) {
          return;
        }

        this.delete(element);
      });
    });
  }

  delete(element: IExceptionDay) {
    this.exceptionDayService.delete(element).subscribe(x => {
      if (x) {
        this.loadExceptionDays();
      }
    }, error => {
      console.log('delete error = ', error);
    });
  }
}
