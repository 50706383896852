import { Injectable } from '@angular/core';
import { IExceptionDay } from '../../../../../core/interfaces/customer/exception-day';
import { ExceptionsByDayFormService } from './exceptions-by-day-form.service';

@Injectable()
export class ExceptionsByDayLoaderService {

  constructor(private exceptionsByDayFormService: ExceptionsByDayFormService) { }

  loadExceptionsByDay(exceptionDays: IExceptionDay[]) {
    this.exceptionsByDayFormService.form.patchValue({
      isAllDay: this.checkIfIsAllDay(exceptionDays),
    });

    this.patchTimesArray(exceptionDays);
  }

  checkIfIsAllDay(exceptionDays: IExceptionDay[]) {
    if (exceptionDays != null && exceptionDays.length == 1) {
      return exceptionDays[0].isAllDay
    }

    return false;
  }

  patchTimesArray(exceptionDays: IExceptionDay[]) {
    if (exceptionDays != null && exceptionDays.length > 0) {
      try {
        Object(exceptionDays).forEach(x => {
          this.exceptionsByDayFormService.addTimeWithValue(x.startHour, x.endHour);
        });
      }
      catch (err) {
        console.log('Exception days split error = ', err);
      }
    }
  }
}
