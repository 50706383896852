import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { IClient } from '../../interfaces/customer/client';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import {IResponseCallWithId, IResponseCall } from '../../interfaces/common/response-call.interface';

@Injectable()
export class ClientService {
  private _baseUrl: string = `${ApiBaseUrl}client`;

  constructor(private httpClient: HttpClient) { }

  getClients(): Observable<IClient[]> {
    return this.httpClient.get<IClient[]>(`${this._baseUrl}`);
  }

  getClientsWithRequest(request: IRequest): Observable<DataSourceResponse<IClient>> {
    return this.httpClient.post<DataSourceResponse<IClient>>(`${this._baseUrl}`, request);
  }

  getWithVerification(client: IClient): Observable<IClient> {
    return this.httpClient.post<IClient>(`${this._baseUrl}/get-with-verify`, client);
  }

  getClient(id: any): Observable<IClient> {
    return this.httpClient.get<IClient>(`${this._baseUrl}/${id}`);
  }

  getClientWithCustomerCode(): Observable<IClient> {
    return this.httpClient.get<IClient>(`${this._baseUrl}/withCustomerCode`);
  }

  create(client: IClient): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/create`, client);
  }

  createWithVerification(client: IClient): Observable<IResponseCallWithId> {
    return this.httpClient.post<IResponseCallWithId>(`${this._baseUrl}/create-with-verify`, client);
  }

  update(client: IClient): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, client);
  }

  updateWithVerification(client: IClient): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/update-with-verify`, client);
  }

  delete(client: IClient): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, client);
  }

  getClientUrlSufix(): Observable<string> {
    return this.httpClient.get<string>(`${this._baseUrl}/url-suffix`);
  }
}
