import { WeekDay } from '@angular/common';
import { Injectable } from '@angular/core';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { WorkingHoursFormService } from './working-hours-form.service';

@Injectable()
export class WorkingHoursLoaderService {

  constructor(private workingHoursFormService: WorkingHoursFormService) { }

  loadFirstWorkingHours() {
    this.workingHoursFormService.form.patchValue({
      monday: true,
      tuesday: true,
      wednesday: true,
      thursday: true,
      friday: true,
      saturday: false,
      sunday: false
    });
  }

  loadWorkingHours(workSchedule: IOfficeOperatorWorkSchedule[]) {
    this.workingHoursFormService.form.patchValue({
      monday: this.checkWeekday(WeekDay.Monday, workSchedule),
      tuesday: this.checkWeekday(WeekDay.Tuesday, workSchedule),
      wednesday: this.checkWeekday(WeekDay.Wednesday, workSchedule),
      thursday: this.checkWeekday(WeekDay.Thursday, workSchedule),
      friday: this.checkWeekday(WeekDay.Friday, workSchedule),
      saturday: this.checkWeekday(WeekDay.Saturday, workSchedule),
      sunday: this.checkWeekday(WeekDay.Sunday, workSchedule)
    });
  }

  checkWeekday(weekday: number, workSchedule: IOfficeOperatorWorkSchedule[]) {
    var schedules = workSchedule.filter(x => x.dayOfTheWeek == weekday);

    if (schedules.length > 0) {
      return true;
    }

    return false;
  }
}
