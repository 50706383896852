import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IClient } from '../../../core/interfaces/customer/client';
import * as uuid from 'uuid';

@Injectable()
export class ClientFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [
        Validators.required,
        Validators.pattern("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$")
      ]],
      description: null
    });
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createClientDto(id, dbClient:IClient=null): IClient {
    const data = this.form.value;

    if (data.createdOnUtc == null) {
      data.createdOnUtc = new Date();
    }

    const client: IClient = {
      id: id,
      customerCode: uuid.v4(),
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
      email: data.email,
      description: data.description,
      parentCustomerCode: null,
      createdOnUtc:dbClient !=null? dbClient.createdOnUtc: new Date()
    };

    return client;
  }
}
