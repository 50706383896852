import { Injectable } from '@angular/core';
import { IAppointment } from '../../../core/interfaces/customer/appointment';
import { AppointmentFormService } from './appointment-form.service';

@Injectable()
export class AppointmentLoaderService {

  constructor(private appointmentFormService: AppointmentFormService) { }

  loadNewAppoitnment(appointment: IAppointment) {
    this.appointmentFormService.form.patchValue({
      date: this.getNewDate(appointment.date),
      startHour: appointment.startHour,
      endHour: appointment.endHour
    });
  }

  loadAppointment(appointment: IAppointment) {
    this.appointmentFormService.form.patchValue({
      date: this.getNewDate(appointment.date),
      startHour: appointment.startHour.toString().slice(0, 5),
      endHour: appointment.endHour.toString().slice(0, 5),
      notes: appointment.notes,
      createdOnUtc: appointment.createdOnUtc,
      mainAppointmentId: appointment.mainAppointmentId,
      clientAppointmentId: appointment.clientAppointmentId,
      clientStartHour: appointment.clientStartHour,
      clientEndHour: appointment.clientEndHour,
      office: appointment.office,
      service: appointment.service,
      serviceDuration: appointment.service.appointmentDuration,
      operator: appointment.operator,
      status: appointment.status,
      client: appointment.client,
      eventId: appointment.eventId,
      uniqueCode: appointment.uniqueCode
    });
  }

  getNewDate(d: Date) {
    const date = new Date(d);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
}
