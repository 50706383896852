import { Component } from '@angular/core';
import { I18nComponent } from '../../../../../../i18n/containers/i18n.component';
import { UserDataSource } from '../../../../../core/datasource/users/user.datasource';
import { IUser } from '../../../../../core/interfaces/users/user';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../../core/services/users/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as fromI18n from '../../../../../../i18n/reducers';
import { IFilterGridData } from '../../../../../core/interfaces/common/filter-grid-data';
import { FilterGridFormService } from '../../../filter-grid/filter-grid-form.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [
    FilterGridFormService
  ]
})
export class ListComponent extends I18nComponent {
  public dataSource: UserDataSource | null;
  public users: IUser[] = [];
  public userSbj: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);
  public displayedColumns: string[] = ["Email", "FirstName", "LastName", "Actions"];

  public loading: boolean = false;

  public page: number = 1;
  public pageSize: number = 20;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public filterArray: IFilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;
  public totalItems: number;

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private filterGridFormService: FilterGridFormService
  ) {
    super(store, translate);

    //this.userService.getUsers().subscribe(x => this.userSbj.next((this.users = x)));
  }

  ngOnInit() {
    super.ngOnInit();

    this.translate.get("list.title").subscribe(x => this.titleService.setTitle(x));

    this.dataSource = new UserDataSource(this.userSbj);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p == null) {
        return;
      }

      this.loadUsers();
    });

    this.loadUsers();
  }

  loadUsers() {
    this.loading = true;
    this.page = this.page != null ? this.page : 1;

    const request = this.filterGridFormService.createRequest(true, null, this.filterArray, null, false,
      this.page, this.pageSize, this.sortDirection, this.sortField);

    this.userService.getUsersWithRequest(request).subscribe({
      next: data => {
        this.userSbj.next(data.data);
        this.totalItems = data.total;
        this.loading = false;
      }
    });
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  addFilterComponent(event: IFilterGridData) {
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      const existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.page = 1;
    this.loadUsers();
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.loadUsers();
  }

  edit(id) {
    this.router.navigate([`./edit/${id}`], { relativeTo: this.route })
  }

  settings(customerCode) {
    this.router.navigate([`./settings/${customerCode}`], { relativeTo: this.route })
  }

  delete(element: IUser) {
    this.userService.delete(element).subscribe(x => {
      if (x == true) {
        const arr = this.userSbj.getValue();

        arr.forEach((item, index) => {
          if (item === element) { arr.splice(index, 1); }
        });

        this.userSbj.next((this.users = arr));
      }
    }, error => {
      //Todo: display error somewhere on the interface
      console.log('delete error = ', error);
    });
  }
}
