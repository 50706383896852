import { Component, Inject, OnInit } from '@angular/core';
import { MatCalendarCellCssClasses, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ExceptionDayType } from '../../../../core/constants/exception-day.constants';
import { GetDayWithoutTime } from '../../../../core/functions/date-function';
import { IFilterGridData } from '../../../../core/interfaces/common/filter-grid-data';
import { IExceptionDay } from '../../../../core/interfaces/customer/exception-day';
import { ExceptionDayService } from '../../../../core/services/customer/exception-day.service';
import { FilterGridFormService } from '../../filter-grid/filter-grid-form.service';

@Component({
  selector: 'app-exception-days-calendar',
  templateUrl: './exception-days-calendar.component.html',
  styleUrls: ['./exception-days-calendar.component.scss'],
  providers: [
    ExceptionDayService,
    FilterGridFormService
  ]
})
export class ExceptionDaysCalendarComponent implements OnInit {
  public exceptionDays: IExceptionDay[] = [];
  public exceptionsByday: IExceptionDay[] = [];
  public exceptionDaysForInsert: IExceptionDay[] = [];
  public exceptionDaysForDelete: IExceptionDay[] = [];

  public officeId: string;
  public operatorId: string;
  public type: string;

  public date: Date;
  public dates: Date[] = [];
  public calendar: any;

  public ExceptionDayType = ExceptionDayType;

  public isFormValid: boolean;
  public componentLoaded: boolean;

  constructor(public dialogRef: MatDialogRef<ExceptionDaysCalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    private exceptionDayService: ExceptionDayService,
    private filterGridFormService: FilterGridFormService
  ) {

    if (data == null) {
      return;
    }

    this.officeId = data.officeId;
    this.operatorId = data.operatorId;
    this.type = data.type;
    this.date = new Date(data.date);
  }

  ngOnInit() {
    this.loadExceptionDays();
  }

  loadExceptionDays() {
    let filterArray: IFilterGridData[] = [];

    if (this.type != null) {
      const typeFilter = this.filterGridFormService.createFilter('String', 'Type', 'Equals', this.type);
      filterArray.push(typeFilter);
    }

    if (this.officeId != null) {
      const officeFilter = this.filterGridFormService.createFilter('String', 'OfficeId', 'Equals', this.officeId);
      filterArray.push(officeFilter);
    }

    if (this.operatorId != null) {
      const operatorFilter = this.filterGridFormService.createFilter('String', 'OperatorId', 'Equals', this.operatorId);
      filterArray.push(operatorFilter);
    }

    const request = this.filterGridFormService.createRequest(false, null, filterArray);

    this.exceptionDayService.getExceptionDaysWithRequest(request).subscribe({
      next: data => {

        if (data.data.length > 0) {
          this.exceptionDays = data.data;

          this.dates = this.getUniqueDays(this.exceptionDays);

          //this.exceptionDaysCalendarLoaderService.loadExceptionDays(this.exceptionDays);

          this.loadExceptionsByday();
        }

        this.componentLoaded = true;
      }
    });
  }

  getUniqueDays(exceptionDays: IExceptionDay[]) {
    if (exceptionDays.length > 0) {
      const uniqueDays: Date[] = exceptionDays.map(item => item.date)
        .filter((value, index, self) => self.indexOf(value) === index);

      return uniqueDays;
    }

    return [];
  }

  loadExceptionsByday() {
    //check if exist exceptions for the selected date in exceptions for insert
    const inInsertList = this.exceptionDaysForInsert.filter(x => new Date(x.date).getFullYear() == this.date.getFullYear() &&
      new Date(x.date).getMonth() == this.date.getMonth() && new Date(x.date).getDate() == this.date.getDate());

    if (inInsertList.length > 0) {
      this.exceptionsByday = inInsertList;

      return;
    }

    //check if exist exceptions for the selected date in exceptions
    const inList = this.exceptionDays.filter(x => new Date(x.date).getFullYear() == this.date.getFullYear() &&
      new Date(x.date).getMonth() == this.date.getMonth() && new Date(x.date).getDate() == this.date.getDate());

    if (inList.length > 0) {
      //check if exist exceptions for the selected date in exceptions for delete
      const inDeleteList = this.exceptionDaysForDelete.filter(x => new Date(x.date).getFullYear() == this.date.getFullYear() &&
        new Date(x.date).getMonth() == this.date.getMonth() && new Date(x.date).getDate() == this.date.getDate());

      if (inDeleteList.length == 0) {
        this.exceptionsByday = inList;

        return;
      }
    }

    this.exceptionsByday = [];
  }

  onSelectDate(date: Date, calendar: any) {
    this.date = date;

    calendar.updateTodaysDate();

    this.loadExceptionsByday();
  }

  dateClass() {
    if (this.dates.length > 0) {
      return (date: Date): MatCalendarCellCssClasses => {
        const highlightDate = this.dates.map(strDate => new Date(strDate))
          .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());

        return highlightDate ? 'selected' : '';
      };
    }
  }

  onEditedExceptionDays(editedExceptionDays: IExceptionDay[]) {
    //check if exist exceptions for the selected date in exceptions for delete
    const inDeleteList = this.exceptionDaysForDelete.filter(x => new Date(x.date).getFullYear() == this.date.getFullYear() &&
      new Date(x.date).getMonth() == this.date.getMonth() && new Date(x.date).getDate() == this.date.getDate());

    if (inDeleteList.length == 0) {
      //add exceptions for the selected date in exceptions for delete
      const toDeleteList = this.exceptionDays.filter(x => new Date(x.date).getFullYear() == this.date.getFullYear() &&
        new Date(x.date).getMonth() == this.date.getMonth() && new Date(x.date).getDate() == this.date.getDate());

      if (toDeleteList.length > 0) {
        this.exceptionDaysForDelete.push(...toDeleteList);
      }
    }

    //remove exceptions for the selected day from insert list
    const listInInsert = this.exceptionDaysForInsert.filter(x => new Date(x.date).getFullYear() == this.date.getFullYear() &&
      new Date(x.date).getMonth() == this.date.getMonth() && new Date(x.date).getDate() == this.date.getDate());

    this.exceptionDaysForInsert = this.exceptionDaysForInsert.filter(x => !listInInsert.includes(x));

    //add the new exceptions for the selected day
    if (editedExceptionDays.length > 0) {
      this.exceptionDaysForInsert.push(...editedExceptionDays);

      this.dates.push(this.date);

      this.dateClass();
    }
    else {
      const index = this.dates.findIndex(x => new Date(x).getDate() === this.date.getDate() &&
        new Date(x).getMonth() === this.date.getMonth() && new Date(x).getFullYear() === this.date.getFullYear());

      if (index >= 0) this.dates.splice(index, 1);
    }
  }

  onIsFormValid(isFormValid: boolean) {
    this.isFormValid = isFormValid;
  }

  save() {
    if (this.exceptionDaysForDelete.length > 0) {
      this.exceptionDayService.deletelist(this.exceptionDaysForDelete).subscribe(x => {
        
      }, error => {
        console.log('error = ', error);
      });
    }

    if (this.exceptionDaysForInsert.length > 0) {
      Object(this.exceptionDaysForInsert).forEach(x => {
        x.date = GetDayWithoutTime(x.date);
      });

      this.exceptionDayService.createlist(this.exceptionDaysForInsert).subscribe(x => {
        
      }, error => {
        console.log('error = ', error);
      });
    }

    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
