import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AddCreditFormService } from '../add-credit-form.service';
import { CreditService } from '../../../core/services/customer/credit.service';

@Component({
  selector: 'app-add-credit',
  templateUrl: './add-credit.component.html',
  styleUrls: ['./add-credit.component.scss'],
  providers: [AddCreditFormService, CreditService]
})
export class AddCreditComponent implements OnInit {
  private customerEmail: string = "";
  public customerCode: string = "";
  public errorMessage = "";

  constructor(public dialogRef: MatDialogRef<AddCreditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    private formService: AddCreditFormService,
    private creditService: CreditService) {

    this.customerEmail = data.email;
    this.customerCode = data.customerCode;

  }

  ngOnInit() {

    this.email.setValue(this.customerEmail);
  }

  get form() {
    return this.formService.form;
  }

  get email() {
    return this.formService.getFormControl("email");
  }

  get credit() {
    return this.formService.getFormControl("credit");
  }

  cancel() {
    this.dialogRef.close({ saved: false, transaction: null });
  }

  submit() {
    let transaction = this.formService.createTransactionDto(this.customerCode);

    this.creditService.addTransaction(transaction).subscribe(x => {
      if (x == null) {
        this.errorMessage = "The transaction could not pe processed!";

        return;
      }

      transaction.id = x;

      this.dialogRef.close({ saved: true, transaction: transaction });
    })
  }

}
