import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { HttpClient } from '@angular/common/http';
import { IRequest } from '../../interfaces/common/request';
import { Observable } from 'rxjs';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { IAppointmentNotification } from '../../interfaces/customer/appointment-notification.interface';

@Injectable()
export class AppointmentNotificationService {

  private _baseUrl: string = `${ApiBaseUrl}appointmentnotification`;

  constructor(private httpClient: HttpClient) { }

  get(request: IRequest, channel:string): Observable<DataSourceResponse<IAppointmentNotification>> {
    return this.httpClient.post<DataSourceResponse<IAppointmentNotification>>(`${this._baseUrl}/requests/${channel}`, request);
  }

  
}
