import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ICustomerCreditTransaction } from '../../core/interfaces/customer/customer-credit-transaction.interface';

@Injectable()
export class AddCreditFormService {

  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      credit: [null, Validators.required],
      email: [{ value: null, disabled: true }],
      details: null
    });
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createTransactionDto(customerCode): ICustomerCreditTransaction {
    const data = this.form.getRawValue();
    var today = new Date();
    const transaction: ICustomerCreditTransaction = {
      id: null,
      customerCode: customerCode,
      details: data.details,
      amount: data.credit,
      amountWithVat: data.credit,
      isProcessed: false,
      createdOnUtc:new Date(today.toUTCString())
    };

    return transaction;
  }
}
