import { MatTableDataSource } from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { IAppointment } from "../../interfaces/customer/appointment";

export class AppointmentDataSource extends MatTableDataSource<IAppointment> {
  constructor(private subject: BehaviorSubject<IAppointment[]>) {
    super();
  }

  connect(): BehaviorSubject<IAppointment[]> {
    return this.subject;
  }

  disconnect() { }
}
