import { UserFormService } from "./user-form.service";
import { Injectable } from "@angular/core";
import { IUser } from '../../../../../core/interfaces/users/user';

@Injectable()
export class UserLoaderService {
  constructor(private userFormService: UserFormService) { }

  loadUser(user: IUser) {
    this.userFormService.form.patchValue({
      id: user.id,
      customerCode: user.customerCode,
      email: user.email,
      phone: user.phone,
      isActivated: user.isActivated,
      isLicenseAccepted: user.isLicenseAccepted,
      accountExpiresOnUtc: user.accountExpiresOnUtc,
      webApiKey: user.webApiKey,
      userName: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      password: user.password,
      customerCodeDillo: user.customerCodeDillo,
      webApiKeyDillo: user.webApiKeyDillo,
      dilloDid:user.inboundDilloNumber
    });
  }
}
