import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { FilterGridOperatorList, FilterOperatorType } from '../../../core/constants/filter-operator-type';
import { IFilterGridData } from '../../../core/interfaces/common/filter-grid-data';
import { FilterGridFormService } from './filter-grid-form.service';
import * as fromI18n from '../../../../i18n/reducers';

@Component({
  selector: 'app-filter-grid',
  templateUrl: './filter-grid.component.html',
  styleUrls: ['./filter-grid.component.scss'],
  providers: [FilterGridFormService]
})
export class FilterGridComponent implements OnInit {
  @Input() propertyName: string;
  @Input() propertyType: string;
  @Input() isExpression: boolean = false;

  @Output() filter = new EventEmitter<IFilterGridData>();

  //public operators = FilterGridOperatorList;
  public operators: object[] = [];
  public isOpen: Boolean = false;
  public isApplied: Boolean = false;

  public operatorsSbj: BehaviorSubject<object[]> = new BehaviorSubject<object[]>([]);

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private formService: FilterGridFormService    
  ) { }

  ngOnInit() {
    if (this.propertyType == FilterOperatorType.Boolean) {
      this.operatorName.setValue("Equals");
    }

    this.store.pipe(select(fromI18n.getCurrentLanguage)).subscribe(x => {
      this.translateOperators();
    });
  }

  translateOperators() {
    this.operators = [];

    Object(FilterGridOperatorList[this.propertyType]).forEach(operator => {
      const type = operator.replace(/\s/g, "");
      this.translate.get(`filter.${type}`).subscribe(translatedOperator => {
        const obj: object = {
          value: operator,
          name: translatedOperator
        };

        this.operators.push(obj);
      });
    });
  }

  get form() {
    return this.formService.form;
  }

  get operatorName() {
    return this.formService.getFormControl('operatorName');
  }

  get operatorValue() {
    return this.formService.getFormControl('operatorValue');
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  apply() {
    this.isApplied = true;

    let opValue = this.operatorValue.value;

    if (this.propertyType == FilterOperatorType.Timestamp || this.propertyType == FilterOperatorType.Date) {
      const today = new Date();
      opValue = new Date(this.operatorValue.value.setHours(today.getUTCHours()));
    }

    const filter: IFilterGridData = {
      propertyType: this.propertyType,
      propertyName: this.getPropertyName(),
      operatorName: this.operatorName.value,
      value: opValue,
      isExpression: this.isExpression
    }

    this.filter.emit(filter);
    this.isOpen = false;
  }

  clear() {
    this.isApplied = false;

    //reset the form first
    this.form.reset();

    const filter: IFilterGridData = {
      propertyType: this.propertyType,
      propertyName: this.getPropertyName(),
      operatorName: this.operatorName.value,
      value: null
    }

    this.filter.emit(filter);
    this.isOpen = false;
  }

  resetFilter() {
    this.isApplied = false;

    //reset the form first
    this.form.reset();

    const filter: IFilterGridData = {
      propertyType: this.propertyType,
      propertyName: this.propertyName,
      operatorName: this.operatorName.value,
      value: null
    }
  }

  getPropertyName(): string {
    let propertyName = this.propertyName;

    if (this.isExpression == true && this.propertyName.toLowerCase() == 'status') {
      propertyName = " concat(ToString(c.FinalStatus.PrimaryStatus.Code), (IS_NULL(c.FinalStatus.SecondaryStatus) ? '' : concat('.', ToString(c.FinalStatus.SecondaryStatus.Code))))";
    }

    if (this.isExpression == true && this.propertyName == 'ParticipantDuration') {
      propertyName = " IS_NULL(c.Duration)=false and c.Duration";
    }

    if (this.isExpression == true && this.propertyName == 'ConferenceDuration') {
      propertyName = "IS_NULL(c.Duration)=false and c.Duration";
    }


    if (this.isExpression == true && this.propertyName == 'CallDuration') {
      propertyName = "IS_NULL(c.Duration)=false and c.Duration";
    }

    if (this.isExpression && this.propertyName == 'agentStatus') {
      propertyName = "IS_NULL(c.EndedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'DeletedOnUtc') {
      propertyName = "IS_NULL(c.DeletedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'ExpiresOnUtc') {
      propertyName = "IS_NULL(c.ExpiresOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'StartedOnUtc') {
      propertyName = "IS_NULL(c.StartedOnUtc)";
    }

    if (this.isExpression && this.propertyName == 'EndedOnUtc') {
      propertyName = "IS_NULL(c.EndedOnUtc)";
    }

    return propertyName;
  }
}
