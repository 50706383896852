import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IExceptionDay } from '../../../../../core/interfaces/customer/exception-day';

@Injectable()
export class ExceptionsByDayFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      isAllDay: false,
      times: this.fb.array([])
    });
  }

  get timesForm() {
    return this.form.get('times') as FormArray;
  }

  addTime() {
    const time = this.fb.group({
      startHour: [null, Validators.required],
      endHour: [null, Validators.required]
    });

    this.timesForm.push(time);
    this.form.markAsDirty();
  }

  addTimeWithValue(startHour: string, endHour: string) {
    const obj = this.fb.group({
      startHour: [startHour, Validators.required],
      endHour: [endHour, Validators.required]
    });

    this.timesForm.push(obj);
    this.form.markAsDirty();
  }

  removeTime(index: number) {
    this.timesForm.removeAt(index);
    this.form.markAsDirty();
  }

  removeAllTimes() {
    this.timesForm.clear();
    this.form.markAsDirty();
  }

  changeTimeValue(index: number, name: string, value: string) {
    var control = this.timesForm.at(index).get(name);
    control.setValue(value);
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createExceptionDayDtos(officeId: string, operatorId: string, date: Date, type: string): IExceptionDay[] {
    const data = this.form.value;

    let exceptionDays: IExceptionDay[] = [];

    if (data.isAllDay) {
      const exceptionDay: IExceptionDay = {
        id: null,
        officeId: officeId,
        operatorId: operatorId,
        serviceId: null,
        startHour: null,
        endHour: null,
        date: date,
        isAllDay: data.isAllDay,
        type: type
      };

      exceptionDays.push(exceptionDay);
    }
    else if (data.times != null && data.times.length > 0) {
      Object(data.times).forEach(x => {
        const exceptionDay: IExceptionDay = {
          id: null,
          officeId: officeId,
          operatorId: operatorId,
          serviceId: null,
          startHour: x.startHour,
          endHour: x.endHour,
          date: date,
          isAllDay: data.isAllDay,
          type: type
        };

        exceptionDays.push(exceptionDay);
      });
    }

    return exceptionDays;
  }
}
