import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-colors-picker',
  templateUrl: './colors-picker.component.html',
  styleUrls: ['./colors-picker.component.scss']
})
export class ColorsPickerComponent implements OnInit {
  @Input() color: string = null;
  @Input() title: string = null;

  @Output() editedColor = new EventEmitter<string>();

  public selectedColor: string = "#ECEFF1";
  public componentLoaded: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.color != null) {
      this.selectedColor = this.color;
    }

    this.componentLoaded = true;
  }

  onChange($event) {
    this.editedColor.emit($event);
  }
}
