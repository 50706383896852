import { ICustomField } from "../interfaces/common/custom-field";

export const CustomFieldsType = {
  Client: 'Client',
  Appointment: 'Appointment',
  Office: 'Office',
  Service: 'Service',
  Operator: 'Operator'
};

export const CustomFieldsTypeList = [
  CustomFieldsType.Client,
  CustomFieldsType.Appointment,
  CustomFieldsType.Office,
  CustomFieldsType.Service,
  CustomFieldsType.Operator
]

export const ClientCustomFieldsCodes = {
  ClientFirstName: '#[ClientFirstName]',
  ClientLastName: '#[ClientLastName]',
  ClientPhone: '#[ClientPhone]',
  ClientEmail: '#[ClientEmail]'
};

export const AppointmentCustomFieldsCodes = {
  AppointmentDate: '#[AppointmentDate]',
  AppointmentStartHour: '#[AppointmentStartHour]',
  AppointmentEndHour: '#[AppointmentEndHour]',
  AppointmentNote: '#[AppointmentNote]',
  AppointmentCancelUrl: '#[AppointmentCancelUrl]',
  AppointmentUniqueCode: '#[AppointmentUniqueCode]'
};

export const OfficeCustomFieldsCodes = {
  OfficeName: '#[OfficeName]',
  OfficeAddress: '#[OfficeAddress]',
  OfficePhone: '#[OfficePhone]',
  OfficeDescription: '#[OfficeDescription]',
  OfficeEmail: '#[OfficeEmail]'
};

export const ServiceCustomFieldsCodes = {
  ServiceName: '#[ServiceName]',
  ServiceDuration: '#[ServiceDuration]',
  ServicePrice: '#[ServicePrice]',
  ServiceDescription: '#[ServiceDescription]'
};

export const OperatorCustomFieldsCodes = {
  OperatorFirstName: '#[OperatorFirstName]',
  OperatorLastName: '#[OperatorLastName]',
  OperatorEmail: '#[OperatorEmail]',
  OperatorPhone: '#[OperatorPhone]'
};

export const ClientCustomFields: ICustomField[] = [
  { code: ClientCustomFieldsCodes.ClientFirstName, translateCode: 'client.firstName', name: 'Client first name' },
  { code: ClientCustomFieldsCodes.ClientLastName, translateCode: 'client.lastName', name: 'Client last name' },
  { code: ClientCustomFieldsCodes.ClientPhone, translateCode: 'client.phone', name: 'Client phone' },
  { code: ClientCustomFieldsCodes.ClientEmail, translateCode: 'client.email', name: 'Client email' }
]

export const AppointmentCustomFields: ICustomField[] = [
  { code: AppointmentCustomFieldsCodes.AppointmentDate, translateCode: 'appointment.date', name: 'Appointment date' },
  { code: AppointmentCustomFieldsCodes.AppointmentStartHour, translateCode: 'appointment.startHour', name: 'Appointment start hour' },
  { code: AppointmentCustomFieldsCodes.AppointmentEndHour, translateCode: 'appointment.endHour', name: 'Appointment end hour' },
  { code: AppointmentCustomFieldsCodes.AppointmentNote, translateCode: 'appointment.note', name: 'Appointment note' },
  { code: AppointmentCustomFieldsCodes.AppointmentCancelUrl, translateCode: 'appointment.cancelUrl', name: 'Appointment cancel url' },
  { code: AppointmentCustomFieldsCodes.AppointmentUniqueCode, translateCode: 'appointment.uniqueCode', name: 'Appointment unique code' }
]

export const OfficeCustomFields: ICustomField[] = [
  { code: OfficeCustomFieldsCodes.OfficeName, translateCode: 'office.name', name: 'Office name' },
  { code: OfficeCustomFieldsCodes.OfficeAddress, translateCode: 'office.address', name: 'Office address' },
  { code: OfficeCustomFieldsCodes.OfficePhone, translateCode: 'office.phone', name: 'Office phone' },
  { code: OfficeCustomFieldsCodes.OfficeEmail, translateCode: 'office.email', name: 'Office email' },
  { code: OfficeCustomFieldsCodes.OfficeDescription, translateCode: 'office.description', name: 'Office description' }
]

export const ServiceCustomFields: ICustomField[] = [
  { code: ServiceCustomFieldsCodes.ServiceName, translateCode: 'service.name', name: 'Service name' },
  { code: ServiceCustomFieldsCodes.ServiceDuration, translateCode: 'service.duration', name: 'Service duration' },
  { code: ServiceCustomFieldsCodes.ServicePrice, translateCode: 'service.price', name: 'Service price' },
  { code: ServiceCustomFieldsCodes.ServiceDescription, translateCode: 'service.description', name: 'Service description' }
]

export const OperatorCustomFields: ICustomField[] = [
  { code: OperatorCustomFieldsCodes.OperatorFirstName, translateCode: 'operator.firstName', name: 'Operator first name' },
  { code: OperatorCustomFieldsCodes.OperatorLastName, translateCode: 'operator.lastName', name: 'Operator last name' },
  { code: OperatorCustomFieldsCodes.OperatorEmail, translateCode: 'operator.email', name: 'Operator email' },
  { code: OperatorCustomFieldsCodes.OperatorPhone, translateCode: 'operator.phone', name: 'Operator phone' }
]

export const CustomFields = [
  ClientCustomFields, AppointmentCustomFields, OfficeCustomFields, ServiceCustomFields, OperatorCustomFields
]

export const CustomFieldsWithKey: { [key: string]: ICustomField[] } = {
  [CustomFieldsType.Client]: ClientCustomFields,
  [CustomFieldsType.Appointment]: AppointmentCustomFields,
  [CustomFieldsType.Office]: OfficeCustomFields,
  [CustomFieldsType.Service]: ServiceCustomFields,
  [CustomFieldsType.Operator]: OperatorCustomFields
}


export const PhoneBookingCustomFieldsCodes = {
  ClientFirstName: '#[ClientFirstName]',
  ClientLastName: '#[ClientLastName]',
  ServiceName: '#[ServiceName]',
  OperatorFirstName: '#[OperatorFirstName]',
  OperatorLastName: '#[OperatorLastName]',
  OfficeAddress: '#[OfficeAddress]',
  OfficeName: '#[OfficeName]',
  AppointmentDate: '#[AppointmentDate]',
  AppointmentStartHour: '#[AppointmentStartHour]',
  AppointmentEndHour: '#[AppointmentEndHour]',
  AppointmentWeekDay: '#[AppointmentWeekDay]'
};

export const PhoneBookingCustomFields: ICustomField[] = [
  { code: PhoneBookingCustomFieldsCodes.ClientFirstName, translateCode: 'client.firstName', name: 'Client first name' },
  { code: PhoneBookingCustomFieldsCodes.ClientLastName, translateCode: 'client.lastName', name: 'Client last name' },
  { code: PhoneBookingCustomFieldsCodes.ServiceName, translateCode: 'service.name', name: 'Service name' },
  { code: PhoneBookingCustomFieldsCodes.OperatorFirstName, translateCode: 'operator.firstName', name: 'Operator first name' },
  { code: PhoneBookingCustomFieldsCodes.OperatorLastName, translateCode: 'operator.lastName', name: 'Operator last name' },
  { code: PhoneBookingCustomFieldsCodes.OfficeAddress, translateCode: 'office.address', name: 'Office address' },
  { code: PhoneBookingCustomFieldsCodes.OfficeName, translateCode: 'office.name', name: 'Office name' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentDate, translateCode: 'appointment.date', name: 'Appointment date' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentStartHour, translateCode: 'appointment.startHour', name: 'Appointment start hour' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentEndHour, translateCode: 'appointment.endHour', name: 'Appointment last hour' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentWeekDay, translateCode: 'appointment.weekDay', name: 'Appointment week day' }
]

export const PhoneBookingAgendaCustomFields: ICustomField[] = [
  { code: PhoneBookingCustomFieldsCodes.AppointmentDate, translateCode: 'appointment.date', name: 'Appointment date' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentStartHour, translateCode: 'appointment.startHour', name: 'Appointment start hour' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentEndHour, translateCode: 'appointment.endHour', name: 'Appointment last hour' },
  { code: PhoneBookingCustomFieldsCodes.AppointmentWeekDay, translateCode: 'appointment.weekDay', name: 'Appointment week day' }
]
