import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  /** selected date */
  @Input() selectedDate: Date;

  /** date to be returned */
  @Output() editedDate = new EventEmitter<Date>();

  public minDate: Date = new Date();

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDate != undefined) {
      this.selectedDate = changes.viewDate.currentValue;
    }
  }
}
