import { Component } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { AuthorizeService } from '../../../../../../api-authorization/authorize.service';
import { I18nComponent } from '../../../../../i18n/containers/i18n.component';
import * as fromI18n from '../../../../../i18n/reducers';
import { ApiBaseUrl } from '../../../../core/constants/shared.constants';
import { AppointmentDataSource } from '../../../../core/datasource/customer/appointment.datasource';
import { GetDayWithoutTime, GetMonthsInterval } from '../../../../core/functions/date-function';
import { IFilterGridData } from '../../../../core/interfaces/common/filter-grid-data';
import { ISelection } from '../../../../core/interfaces/common/selection';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';
import { IOffice } from '../../../../core/interfaces/customer/office';
import { IOfficeOperatorService } from '../../../../core/interfaces/customer/office-operator-service';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { ISettings } from '../../../../core/interfaces/customer/settings';
import { AppointmentService } from '../../../../core/services/customer/appointment.service';
import { ClientService } from '../../../../core/services/customer/client.service';
import { OfficeService } from '../../../../core/services/customer/office.service';
import { OperatorService } from '../../../../core/services/customer/operator.service';
import { ServiceService } from '../../../../core/services/customer/service.service';
import { SettingsService } from '../../../../core/services/customer/settings.service';
import { StatusService } from '../../../../core/services/customer/status.service';
import { ConfirmationComponent } from '../../dialogs/confirmation/confirmation.component';
import { FilterGridFormService } from '../../filter-grid/filter-grid-form.service';
import { AppointmentDetailsComponent } from '../appointment-details/appointment-details.component';
import { AppointmentFormService } from '../appointment-form.service';
import tr from 'date-fns/esm/locale/tr/index.js';

@Component({
  selector: 'app-appointment-list',
  templateUrl: './appointment-list.component.html',
  styleUrls: ['./appointment-list.component.scss'],
  providers: [
    AppointmentService,
    ServiceService,
    ClientService,
    StatusService,
    AppointmentFormService,
    FilterGridFormService,
    OfficeService,
    OperatorService,
    SettingsService
  ]
})
export class AppointmentListComponent extends I18nComponent {

  public dataSource: AppointmentDataSource | null;

  public appointments: IAppointment[] = [];

  public appointmentSbj: BehaviorSubject<IAppointment[]> = new BehaviorSubject<IAppointment[]>([]);

  public displayedColumns: string[] = ["CreatedOnUtc", "UniqueCode", "Date", "ServiceName", 'ServiceAppointmentDuration', "StartHour", /*"ClientFirstName",*/ "ClientLastName", "StatusName", "Actions"];

  public loading: boolean = false;

  public page: number = 1;
  public pageSize: number = 20;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public filterArray: IFilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;
  public totalItems: number;

  public monthsInterval: ISelection[] = [];
  public selectedMonthsInterval: ISelection[] = [];
  public monthsIntervalLoaded: boolean = false;

  public selectedStartDate: string;
  public selectedEndDate: string;
  public filterDateLoaded: boolean = false;
  public userRole: string = "customer";
  public today = new Date();
  public allOfficeOperatorService: IOfficeOperatorService[] = [];

  public allOffices: IOffice[] = [];
  public offices: ISelection[] = [];
  public selectedOffice: ISelection = null;

  public allOperators: IOperator[] = [];
  public operators: ISelection[] = [];
  public selectedOperator: ISelection = null;
  private all: ISelection;
  private token: string = null;

  public settings: ISettings;

  public filtersLoaded: boolean = false;

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private appointmentService: AppointmentService,
    private officeService: OfficeService,
    private operatorService: OperatorService,
    private serviceService: ServiceService,
    private clientService: ClientService,
    private statusService: StatusService,
    private appointmentFormService: AppointmentFormService,
    private router: Router, private authorizeService: AuthorizeService,
    private route: ActivatedRoute,
    private titleAppointment: Title,
    private dialog: MatDialog,
    private filterGridFormService: FilterGridFormService,
    private matSnackBack: MatSnackBar,
    private settingsService: SettingsService
  ) {
    super(store, translate);

    const state = sessionStorage.getItem("filters");

    if (state != null) {
      var filters = JSON.parse(state);

      if (filters != null) {
        this.selectedStartDate = filters.startDate;
        this.selectedEndDate = filters.endDate;
        this.selectedOffice = filters.office;
        this.selectedOperator = filters.operator;
        this.operators = filters.operators;
      }
    }

    this.router.events.subscribe({
      next: event => {
        if (event instanceof NavigationEnd) {
          if (!this.router.url.startsWith(`/management/${this.userRole}/appointments`)) {
            sessionStorage.setItem("filters", null);
          }
        }
      }
    });

    this.authorizeService.getAccessToken().subscribe(x => this.token = x);

  }

  ngOnInit() {
    super.ngOnInit();

    this.translate.get("list.title").subscribe(x => this.titleAppointment.setTitle(x));
    this.translate.get("edit.all").subscribe(x => {
      this.all = {
        value: null,
        text: x
      }

      this.offices.unshift(this.all);
      this.operators.unshift(this.all);
    });

    this.authorizeService.getUserRole().subscribe({
      next: data => {
        this.userRole = data.toLowerCase();

        const requestWithAvailable = this.filterGridFormService.createRequest(false, null, [], null, true);

        if (this.userRole == "customer") {
          combineLatest(
            this.officeService.getOfficesWithRequest(requestWithAvailable),
            this.operatorService.getOperatorsWithRequest(requestWithAvailable),
            this.officeService.getAllOfficeOperatorService(),
            (offices, operators, allOfficeOperatorService) =>
              ({ offices, operators, allOfficeOperatorService })
          ).subscribe(pair => {
            this.allOffices = pair.offices.data;
            this.offices.push(...pair.offices.data.map(x => ({ value: x.id, text: x.name })));

            //this.offices = pair.offices.data.map(x => ({ value: x.id, text: x.name }));
            //this.offices.unshift(this.all);

            this.allOperators = pair.operators.data;
            if (this.operators.length == 0) {
              this.operators.push(...pair.operators.data.map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` })));

              //this.operators = pair.operators.data.map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));
              //this.operators.unshift(this.all);
            }

            this.allOfficeOperatorService = pair.allOfficeOperatorService;

            this.filtersLoaded = true;
          });
        }
        else {
          combineLatest(
            this.officeService.getOfficesWithRequest(requestWithAvailable),
            this.officeService.getAllOfficeOperatorService(),
            (offices, allOfficeOperatorService) =>
              ({ offices, allOfficeOperatorService })
          ).subscribe(pair => {
            this.allOffices = pair.offices.data;
            this.offices.push(...pair.offices.data.map(x => ({ value: x.id, text: x.name })));
            //this.offices = pair.offices.data.map(x => ({ value: x.id, text: x.name }));
            //this.offices.unshift(this.all);

            this.allOfficeOperatorService = pair.allOfficeOperatorService;

            this.filtersLoaded = true;
          });
        }
      }
    });

    if (this.selectedEndDate == null || this.selectedStartDate == null) {
      const date = new Date();

      this.selectedStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

      var year = date.getFullYear();
      var month = date.getMonth();

      this.selectedEndDate = `${year}-${month + 1}-${date.getDate()}`;
    }

    this.selectedMonthsInterval = GetMonthsInterval(this.selectedStartDate, this.selectedEndDate);

    this.filterDateLoaded = true;

    this.dataSource = new AppointmentDataSource(this.appointmentSbj);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p === null) {
        return;
      }

      this.loadAppointments();
      //this.checkMonthsInterval();
    });

    this.loadAppointments();
    //this.checkMonthsInterval();
  }

  checkMonthsInterval() {
    this.appointmentService.getMonthsInterval().subscribe(x => {
      if (x === null || x === '') {
        return;
      }

      this.selectedMonthsInterval = JSON.parse(x);

      this.loadAppointments();
    });
  }

  verifyDateTime(): boolean {
    var diffTime = Math.abs(Date.parse(this.selectedEndDate) - Date.parse(this.selectedStartDate));

    if (Math.ceil(diffTime / (1000 * 60 * 60 * 24)) > 30) {
      this.displayMessage('edit.errorOnExceedingDatePeriod');
      return true;
    }

    if (Date.parse(this.selectedStartDate) > Date.parse(this.selectedEndDate)) {
      this.displayMessage('edit.errorStartGreaterThanEnd');
      return true;
    }

    return false;
  }

  loadAppointments() {

    const invalid = this.verifyDateTime();
    if (invalid == true) {
      return;
    }

    this.loading = true;
    this.page = this.page !== null ? this.page : 1;

    const filterArray: IFilterGridData[] = [];
    filterArray.push(...this.filterArray);

    const startDateFilter = this.filterGridFormService.createFilter(
      'Timestamp', 'DateTimestamp', 'Greater Than Or Equals', this.selectedStartDate);
    filterArray.push(startDateFilter);

    const endDateFilter = this.filterGridFormService.createFilter(
      'Timestamp', 'DateTimestamp', 'Less Than Or Equals', this.selectedEndDate);
    filterArray.push(endDateFilter);

    if (this.selectedOffice != null && this.selectedOffice.value != null) {
      const officeFilter = this.filterGridFormService.createFilter(
        'Guid', 'OfficeId', 'Equals', this.selectedOffice.value);
      filterArray.push(officeFilter);
    }

    if (this.selectedOperator != null && this.selectedOperator.value != null) {
      const operatorFilter = this.filterGridFormService.createFilter(
        'Guid', 'OperatorId', 'Equals', this.selectedOperator.value);
      filterArray.push(operatorFilter);
    }

    const request = this.filterGridFormService.createRequest(true, null, filterArray, null, false,
      this.page, this.pageSize, this.sortDirection, this.sortField, this.selectedMonthsInterval, true);

    this.appointmentService.getAppointmentsWithRequest(request).subscribe({
      next: data => {
        data.data.forEach(x => {
          x.startHour = x.startHour.toString().slice(0, 5);
          x.endHour = x.endHour.toString().slice(0, 5);
        });

        this.appointmentSbj.next(data.data);
        this.totalItems = data.total;
        this.loading = false;

        this.appointmentService.setEnableMonthsInterval(0).subscribe(x => { });
      }
    });
  }

  export() {

    const invalid = this.verifyDateTime();
    if (invalid == true) {
      return;
    }

    this.loading = true;

    let office = this.selectedOffice != null && this.selectedOffice.value != null ? this.selectedOffice.value : "";
    let operator = this.selectedOperator != null && this.selectedOperator.value != null ? this.selectedOperator.value : "";

    this.currentLanguage$.subscribe(x => {
      if (!this.loading) {
        return;
      }

      const url = `${ApiBaseUrl}appointment/export?userToken=${this.token}
                &startDate=${this.selectedStartDate}&endDate=${this.selectedEndDate}
                &office=${office}&operatorName=${operator}&language=${x}`;

      window.location.href = url;
      this.loading = false;
    });
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  addFilterComponent(event: IFilterGridData) {
    if (event.value === null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName !== event.propertyName);
    }
    else {
      const existingFilter = this.filterArray.filter(x => x.propertyName === event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName !== event.propertyName);
      }

      this.filterArray.push(event);
    }

    this.page = 1;
    this.loadAppointments();
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.loadAppointments();
  }

  onEditedMonthsInterval($event) {
    this.selectedMonthsInterval = $event;

    this.appointmentService.setMonthsInterval(JSON.stringify(this.selectedMonthsInterval)).subscribe(x => { });
  }

  onEditedStartDateFilter(date: Date) {
    var year = date.getFullYear();
    var month = date.getMonth();

    //client request 22.08.2023 to allow to select only one day
    this.selectedStartDate = `${year}-${month + 1}-${date.getDate()}`;

    this.selectedMonthsInterval = GetMonthsInterval(this.selectedStartDate, this.selectedEndDate);
  }

  onEditedEndDateFilter(date: Date) {
    var year = date.getFullYear();
    var month = date.getMonth();

    //client request 22.08.2023 to allow to select only one day
    this.selectedEndDate = `${year}-${month + 1}-${date.getDate()}`;

    this.selectedMonthsInterval = GetMonthsInterval(this.selectedStartDate, this.selectedEndDate);
  }

  onEditedOffice(selection: ISelection) {

    this.selectedOffice = this.offices.find(x => x.value == selection.value);

    if (selection.value == null) {
      //is all
      return;
    }

    const officeOperatorServices = this.allOfficeOperatorService.filter(x => x.officeId == selection.value);

    this.loadOperators(officeOperatorServices);
  }


  onEditedOperator(selection: ISelection) {

    this.selectedOperator = this.operators.find(x => x.value == selection.value);

  }

  loadOperators(officeOperatorServices: IOfficeOperatorService[]) {
    this.operators = [];
    this.selectedOperator = null;

    if (officeOperatorServices.length > 0) {
      const operatorIds = Array.prototype.map.call(officeOperatorServices, x => x.operatorId).toString();
      this.operators = this.allOperators.filter(x => operatorIds.includes(x.id)).map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));
      this.operators.unshift(this.all);
    }
  }

  edit(id: any) {
    var filters = {
      startDate: this.selectedStartDate, endDate: this.selectedEndDate, office: this.selectedOffice, operator: this.selectedOperator,
      operators: this.operators
    };
    sessionStorage.setItem("filters", JSON.stringify(filters));

    this.router.navigate([`./edit/${id}`], { relativeTo: this.route });
  }

  openDeleteDialog(element: IAppointment) {
    this.translate.get("confirmation.deleteQuestion").subscribe(x => {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        minWidth: "25rem",
        minHeight: "7rem",
        data: `${x} ${element.client.firstName} ${element.client.lastName} ${element.startHour}?`
      });

      dialogRef.afterClosed().subscribe(response => {
        if (!response) {
          return;
        }

        this.delete(element);
      });
    });
  }

  delete(element: IAppointment) {
    this.appointmentService.delete(element).subscribe(x => {
      if (x) {
        this.loadAppointments();
      }
    }, error => {
      console.log('delete error = ', error);
    });
  }

  create() {
    var filters = { startDate: this.selectedStartDate, endDate: this.selectedEndDate, office: this.selectedOffice, operator: this.selectedOperator };
    sessionStorage.setItem("filters", JSON.stringify(filters));

    this.router.navigate([`/management/${this.userRole}/appointments/create`]);
  }

  openDetailsDialog(appointment: IAppointment) {
    this.dialog.open(AppointmentDetailsComponent, {
      minWidth: "25rem",
      minHeight: "7rem",
      data: appointment
    });
  }

  openCancelDialog(appointment: IAppointment) {
    const dateMessage = `${GetDayWithoutTime(new Date(appointment.date))}, ${appointment.startHour}-${appointment.endHour}?`;

    this.translate.get("confirmation.cancelAppointment").subscribe(x => {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        minWidth: "25rem",
        minHeight: "7rem",
        data: `${x} ${dateMessage}`
      });

      dialogRef.afterClosed().subscribe(response => {
        if (!response) {
          return;
        }

        this.checkSettings(appointment);
      });
    });
  }

  checkSettings(appointment: IAppointment) {
    if (this.settings != null) {
      appointment.settings = this.settings;

      this.cancelAppointment(appointment);

      return;
    }

    this.settingsService.getSettings().subscribe({
      next: data => {
        this.settings = data;

        appointment.settings = this.settings;

        this.cancelAppointment(appointment);
      }
    });
  }

  cancelAppointment(appointment: IAppointment) {
    this.appointmentService.cancel(appointment).subscribe(x => {
      if (x.entity) {
        this.displayMessage('edit.cancelSuccess');

        this.loadAppointments();
      }
      else {
        this.displayMessage(`errors.appointment.cancel.customer.${x.errorCode}`);
      }
    }, error => {
      console.log('error = ', error);
    });
  }

  displayMessage(code: string) {
    this.translate.get(code).subscribe(x => {
      if (x != null) {
        this.openSnackBar(x);
      }
      else {
        this.openSnackBar("An error occurred. Please refresh and try again.");
      }
    });
  }

  openSnackBar(message) {
    this.matSnackBack.open(message, "Ok", {
      duration: 5000
    });
  }
}
