import { WeekDay } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IOfficeOperatorWorkSchedule } from '../../../../../core/interfaces/customer/office-operator-work-schedule';
import { IWorkingHours } from '../../../../../core/interfaces/customer/working-hours';

@Injectable()
export class WeekdayWorkingHoursFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      workingHours: this.fb.array([])
    });
  }

  get workingHoursForm() {
    return this.form.get('workingHours') as FormArray;
  }

  addWorkingHours(workSchedule: IOfficeOperatorWorkSchedule = null) {
    var workingHours: IWorkingHours = {
      id: null,
      startHour: '09:00',
      endHour: '18:00'
    };

    if (workSchedule != null) {
      workingHours.id = workSchedule.id;
      workingHours.startHour = workSchedule.startHour;
      workingHours.endHour = workSchedule.endHour;
    }

    const obj = this.fb.group({
      id: [workingHours.id, null],
      startHour: [workingHours.startHour, Validators.required],
      endHour: [workingHours.endHour, Validators.required]
    });

    this.workingHoursForm.push(obj);
    this.form.markAsDirty();
  }

  removeWorkingHours(index: number) {
    this.workingHoursForm.removeAt(index);
    this.form.markAsDirty();
  }

  changeTimeValue(index: number, name: string, value: string) {
    var control = this.workingHoursForm.at(index).get(name);
    control.setValue(value);
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createOfficeOperatorWorkScheduleDtos(weekday: WeekDay, officeId: string, operatorId: string, serviceId: string): IOfficeOperatorWorkSchedule[] {
    var data = this.form.value;
    var workingHours = data.workingHours;

    const officeOperatorsWorkSchedule: IOfficeOperatorWorkSchedule[] = [];
    const isOfficeWorkSchedule = operatorId != null && operatorId != "" ? false : true;

    Object(workingHours).forEach(workingHours => {
      const workSchedule: IOfficeOperatorWorkSchedule = {
        id: workingHours.id,
        officeId: officeId,
        operatorId: operatorId,
        serviceId: serviceId,
        startHour: workingHours.startHour,
        endHour: workingHours.endHour,
        dayOfTheWeek: weekday,
        isOfficeWorkSchedule: isOfficeWorkSchedule
      };

      officeOperatorsWorkSchedule.push(workSchedule);
    });


    return officeOperatorsWorkSchedule;
  }
}
