import { BehaviorSubject } from "rxjs";
import { IUser } from "../../interfaces/users/user";
import { MatTableDataSource } from "@angular/material";

export class UserDataSource extends MatTableDataSource<IUser> {
  constructor(private subject: BehaviorSubject<IUser[]>) {
    super();
  }

  connect(): BehaviorSubject<IUser[]> {
    return this.subject;
  }

  disconnect() { }
}
