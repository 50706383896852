import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { IRequest } from '../../interfaces/common/request';
import { IExceptionDay } from '../../interfaces/customer/exception-day';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';

@Injectable()
export class ExceptionDayService {
  private _baseUrl: string = `${ApiBaseUrl}exceptionday`;

  constructor(private httpClient: HttpClient) { }

  getExceptionDaysWithRequest(request: IRequest): Observable<DataSourceResponse<IExceptionDay>> {
    return this.httpClient.post<DataSourceResponse<IExceptionDay>>(`${this._baseUrl}`, request);
  }

  createlist(exceptionDays: IExceptionDay[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/createlist`, exceptionDays);
  }

  deletelist(exceptionDays: IExceptionDay[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/deletelist`, exceptionDays);
  }

  delete(exceptionDay: IExceptionDay): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, exceptionDay);
  }
}
