import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUser } from '../../interfaces/users/user';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { IResponseCall } from '../../interfaces/common/response-call.interface';

@Injectable()
export class UserService {
  private _baseUrl: string = `${ApiBaseUrl}customer`;

  constructor(private httpClient: HttpClient) { }

  getUsers(): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(`${this._baseUrl}`);
  }

  getUsersWithRequest(request: IRequest): Observable<DataSourceResponse<IUser>> {
    return this.httpClient.post<DataSourceResponse<IUser>>(`${this._baseUrl}`, request);
  }

  getUser(id: any): Observable<IUser> {
    return this.httpClient.get<IUser>(`${this._baseUrl}/${id}`);
  }

  getUserByCustomerCode(customerCode: string = null): Observable<IUser> {
    return this.httpClient.get<IUser>(`${this._baseUrl}/byCustomerCode/${customerCode}`);
  }

  create(user: IUser): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/create`, user);
  }

  delete(user: IUser): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, user);
  }

  update(user: IUser): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/update`, user);
  }
}
