import { AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { AuthorizeService } from '../../../../../api-authorization/authorize.service';
import { CRUD } from '../../../core/constants/crud.constants';
import { GetDayWithoutTime, GetNewDateFromDateAndTime, GetNewTimeBasedOnTheService } from '../../../core/functions/date-function';
import { ISelection } from '../../../core/interfaces/common/selection';
import { IAppointment } from '../../../core/interfaces/customer/appointment';
import { IClient } from '../../../core/interfaces/customer/client';
import { IOffice } from '../../../core/interfaces/customer/office';
import { IOfficeOperatorService } from '../../../core/interfaces/customer/office-operator-service';
import { IOfficeOperatorWorkSchedule } from '../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../core/interfaces/customer/operator';
import { IService, IServiceDuration } from '../../../core/interfaces/customer/service';
import { ISettings } from '../../../core/interfaces/customer/settings';
import { IStatus } from '../../../core/interfaces/customer/status';
import { AppointmentService } from '../../../core/services/customer/appointment.service';
import { ClientService } from '../../../core/services/customer/client.service';
import { OfficeService } from '../../../core/services/customer/office.service';
import { OperatorService } from '../../../core/services/customer/operator.service';
import { ServiceService } from '../../../core/services/customer/service.service';
import { SettingsService } from '../../../core/services/customer/settings.service';
import { StatusService } from '../../../core/services/customer/status.service';
import { CalendarWithTimeSlotsComponent } from '../dialogs/calendar-with-time-slots/calendar-with-time-slots.component';
import { ClientComponent } from '../dialogs/client/client.component';
import { FilterGridFormService } from '../filter-grid/filter-grid-form.service';
import { AppointmentFormService } from './appointment-form.service';
import { AppointmentLoaderService } from './appointment-loader.service';

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  providers: [
    AppointmentService,
    AppointmentFormService,
    AppointmentLoaderService,
    OfficeService,
    ServiceService,
    OperatorService,
    StatusService,
    ClientService,
    SettingsService,
    FilterGridFormService
  ]
})
export class AppointmentComponent implements OnInit {
  @Input() id: any = null;
  @Input() appointment: IAppointment = null;
  @Input() isDialog: boolean = false;
  @Input() filters: any = null;

  @Output() closeDialog = new EventEmitter<boolean>();

  public notFound: boolean = false;
  public loading: boolean = false;
  public success: boolean = false;
  public appointmentLoaded: boolean = false;
  public isCalendarDisabled: boolean = true;

  public allOffices: IOffice[] = [];
  public offices: ISelection[] = [];
  public selectedOffice: ISelection = null;

  public allServices: IService[] = [];
  public services: ISelection[] = [];
  public selectedService: ISelection = null;

  public allServiceDurations: IServiceDuration[] = [];
  public selectedServiceDuration: ISelection = null;
  public serviceDurations: ISelection[] = [];
  public serviceSelectedObj: IService = null;

  public allOperators: IOperator[] = [];
  public operators: ISelection[] = [];
  public selectedOperator: ISelection = null;

  public allStatuses: IStatus[] = [];
  public statuses: ISelection[] = [];
  public selectedStatus: ISelection = null;

  public allClients: IClient[] = [];
  public clients: ISelection[] = [];
  public selectedClient: ISelection = null;

  public allOfficeOperatorService: IOfficeOperatorService[] = [];
  public allOfficeOperatorWorkSchedule: IOfficeOperatorWorkSchedule[] = [];

  public checkAppointment: boolean = false;

  public minTime: string = '00:00';
  public maxTime: string = '00:00';

  public settings: ISettings = null;
  public userRole: string = "customer";
  public currency: string = "€";

  constructor(
    //readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private router: Router,
    private appointmentFormService: AppointmentFormService,
    private appointmentLoaderService: AppointmentLoaderService,
    private appointmentService: AppointmentService,
    private officeService: OfficeService,
    private serviceService: ServiceService,
    private operatorService: OperatorService,
    private statusService: StatusService,
    private clientService: ClientService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private titleService: Title,
    private dialog: MatDialog,
    private authorizeService: AuthorizeService,
    private filterGridFormService: FilterGridFormService,
    private matSnackBack: MatSnackBar
  ) {
    this.authorizeService.getUserRole().subscribe({ next: data => this.userRole = data.toLowerCase() });
  }

  ngOnInit() {
    const request = this.filterGridFormService.createRequest();
    const requestWithAvailable = this.filterGridFormService.createRequest(false, null, [], null, true);

    if (this.id === null) {
      if (!this.isDialog) {
        this.translate.get("edit.titleCreate").subscribe(x => this.titleService.setTitle(x));
      }

      combineLatest(
        this.officeService.getOfficesWithRequest(requestWithAvailable),
        this.serviceService.getServicesWithRequest(requestWithAvailable),
        this.operatorService.getOperatorsWithRequest(requestWithAvailable),
        this.statusService.getStatusesWithRequest(request),
        this.clientService.getClientsWithRequest(request),
        this.officeService.getAllOfficeOperatorService(),
        this.officeService.getAllOfficeOperatorWorkSchedule(),
        this.settingsService.getSettings(),
        (offices, services, operators, statuses, clients, allOfficeOperatorService, allOfficeOperatorWorkSchedule, settings) =>
          ({ offices, services, operators, statuses, clients, allOfficeOperatorService, allOfficeOperatorWorkSchedule, settings })
      ).subscribe(pair => {
        this.allOffices = pair.offices.data;

        this.offices = pair.offices.data.map(x => ({ value: x.id, text: x.name }));

        this.allServices = pair.services.data;

        this.allOperators = pair.operators.data;

        this.allStatuses = pair.statuses.data;
        this.statuses = pair.statuses.data.map(x => ({ value: x.id, text: x.name }));

        this.allClients = pair.clients.data;
        this.clients = pair.clients.data.map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));

        this.allOfficeOperatorService = pair.allOfficeOperatorService;

        this.allOfficeOperatorWorkSchedule = pair.allOfficeOperatorWorkSchedule;

        this.settings = pair.settings;
        if (this.settings.currencySymbol != null && this.settings.currencySymbol != "") {
          this.currency = this.settings.currencySymbol;
        }

        if (this.appointment != null) {
          this.appointmentLoaderService.loadAppointment(this.appointment);
        }

        this.appointmentLoaded = true;
      });

      return;
    }

    if (!this.isDialog) {
      this.translate.get("edit.titleUpdate").subscribe(x => this.titleService.setTitle(x));
    }

    combineLatest(
      this.appointmentService.getAppointment(this.id),
      this.officeService.getOfficesWithRequest(requestWithAvailable),
      this.serviceService.getServicesWithRequest(requestWithAvailable),
      this.operatorService.getOperatorsWithRequest(requestWithAvailable),
      this.statusService.getStatusesWithRequest(request),
      this.clientService.getClientsWithRequest(request),
      this.officeService.getAllOfficeOperatorService(),
      this.officeService.getAllOfficeOperatorWorkSchedule(),
      this.settingsService.getSettings(),
      (appointment, offices, services, operators, statuses, clients, allOfficeOperatorService, allOfficeOperatorWorkSchedule, settings) =>
        ({ appointment, offices, services, operators, statuses, clients, allOfficeOperatorService, allOfficeOperatorWorkSchedule, settings })
    ).subscribe(pair => {
      const appointment = pair.appointment;

      if (appointment == null || appointment.id == null) {
        this.notFound = true;
        return;
      }

      this.allOffices = pair.offices.data;
      this.offices = pair.offices.data.map(x => ({ value: x.id, text: x.name }));
      this.selectedOffice = this.offices.find(x => x.value == appointment.office.id);

      this.allServices = pair.services.data;
      this.services = pair.services.data.map(x => ({ value: x.id, text: x.name }));
      this.selectedService = this.services.find(x => x.value == appointment.service.id);

      this.allOperators = pair.operators.data;
      this.operators = pair.operators.data.map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));
      this.selectedOperator = this.operators.find(x => x.value == appointment.operator.id);

      if (this.operators.length == 1) {
        //set to form control
        this.selectedOperator = this.operators[0];
      }

      this.allStatuses = pair.statuses.data;
      this.statuses = pair.statuses.data.map(x => ({ value: x.id, text: x.name }));
      this.selectedStatus = this.statuses.find(x => x.value == appointment.status.id);

      this.allClients = pair.clients.data;
      this.clients = pair.clients.data.map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));
      this.selectedClient = this.clients.find(x => x.value == appointment.client.id);

      this.allOfficeOperatorService = pair.allOfficeOperatorService;

      //filter services and operators
      let officeOperatorServices = this.allOfficeOperatorService.filter(x => x.officeId == appointment.office.id);
      this.loadServices(officeOperatorServices);
      this.selectedService = this.services.find(x => x.value == appointment.service.id);

      //add filter by service
      officeOperatorServices = this.allOfficeOperatorService.filter(x => x.officeId == appointment.office.id && x.serviceId==appointment.service.id);
      this.loadOperators(officeOperatorServices);
      this.selectedOperator = this.operators.find(x => x.value == appointment.operator.id);

      this.allOfficeOperatorWorkSchedule = pair.allOfficeOperatorWorkSchedule;

      this.settings = pair.settings;

      this.isCalendarDisabled = false;

      this.appointmentLoaderService.loadAppointment(appointment);
      this.appointment = appointment;

      this.loadWorkSchedule();

      this.appointmentLoaded = true;
    });
  }

  get form() {
    return this.appointmentFormService.form;
  }

  get date() {
    return this.appointmentFormService.getFormControl('date') as FormControl;
  }

  get startHour() {
    return this.appointmentFormService.getFormControl('startHour') as FormControl;
  }

  get endHour() {
    return this.appointmentFormService.getFormControl('endHour') as FormControl;
  }

  get notes() {
    return this.appointmentFormService.getFormControl('notes') as FormControl;
  }

  get client() {
    return this.appointmentFormService.getFormControl('client') as FormControl;
  }

  get uniqueCode() {
    return this.appointmentFormService.getFormControl('uniqueCode') as FormControl;
  }

  onEditedOffice(selection: ISelection) {
    const office = this.allOffices.find(x => x.id == selection.value);
    this.form.controls['office'].setValue(office);

    this.selectedOffice = this.offices.find(x => x.value == selection.value);
    const officeOperatorServices = this.allOfficeOperatorService.filter(x => x.officeId == selection.value);

    this.checkCalendarDisable();

    this.loadServices(officeOperatorServices);
    this.loadOperators(officeOperatorServices);
    //this.loadWorkSchedule();
  }

  onEditedService(selection: ISelection) {
    const service = this.allServices.find(x => x.id == selection.value);
    this.serviceSelectedObj = service;
    this.form.controls['service'].setValue(service);

    this.serviceDurations = [];
    this.form.controls['serviceDuration'].setValue({duration: service.appointmentDuration, price: service.price});

    if (service.durations != null && service.durations.length > 0) {
      this.serviceDurations.push({ text: `${service.appointmentDuration} min (${service.price} ${this.currency})`, value: `${service.appointmentDuration}` });
      this.selectedServiceDuration = { text: `${service.appointmentDuration} min (${service.price} ${this.currency})`, value: `${service.appointmentDuration}` };
      service.durations.forEach(item => {
        this.serviceDurations.push({ text: `${item.duration} min (${item.price} ${this.currency})`, value: `${item.duration}` });
      })
    }

    console.log("onEditedService", this.serviceSelectedObj, this.serviceDurations);

    this.selectedService = this.services.find(x => x.value == selection.value);
    var officeOperatorServices = this.allOfficeOperatorService.filter(x => x.serviceId == selection.value);

    if (this.selectedOffice != null) {
      officeOperatorServices = officeOperatorServices.filter(x => x.officeId == this.selectedOffice.value);
    }

    this.checkCalendarDisable();

    this.loadOperators(officeOperatorServices);
    //this.loadWorkSchedule();

    //if (this.startHour.value != null) {
    //  const endHourTime = GetNewTimeBasedOnTheService(service, this.startHour.value, true);
    //  this.form.controls['endHour'].setValue(endHourTime);
    //}
    //else if (this.endHour.value != null) {
    //  const startHourTime = GetNewTimeBasedOnTheService(service, this.endHour.value, false);
    //  this.form.controls['startHour'].setValue(startHourTime);
    //}
  }

  onEditedServiceDuration(selection: ISelection) {
    let selectedDuration = + selection.value;
    let duration = this.serviceSelectedObj.durations.find(x => x.duration == selectedDuration);

    if (duration != null) {
      this.serviceSelectedObj.appointmentDuration = duration.duration;
      this.serviceSelectedObj.price = duration.price;

      this.form.controls['service'].setValue(this.serviceSelectedObj);
      this.form.controls['serviceDuration'].setValue(duration);

      //reset date and interval
      this.form.controls['date'].setValue(null);
      this.form.controls['startHour'].setValue(null);
      this.form.controls['endHour'].setValue(null);
    }
  }

  onEditedOperator(selection: ISelection) {
    const operator = this.allOperators.find(x => x.id == selection.value);
    this.form.controls['operator'].setValue(operator);

    this.selectedOperator = this.operators.find(x => x.value == selection.value);

    this.checkCalendarDisable();

    //this.loadWorkSchedule();

    this.checkAppointment = true;
  }

  onEditedStatus(selection: ISelection) {
    const status = this.allStatuses.find(x => x.id == selection.value);
    this.form.controls['status'].setValue(status);
  }

  onEditedClient(selection: ISelection) {
    const client = this.allClients.find(x => x.id == selection.value);
    this.form.controls['client'].setValue(client);
  }

  onEditedDate(date: Date) {
    const newDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.form.controls['date'].setValue(newDate);

    this.loadWorkSchedule();

    this.checkAppointment = true;
  }

  onEditedStartHour(time: string) {
    this.form.controls['startHour'].setValue(time);

    if (this.selectedService != null) {
      const service = this.allServices.find(x => x.id == this.selectedService.value);

      const endHour = GetNewTimeBasedOnTheService(service, time, true);
      this.loadTime(endHour, 'endHour');
    }
    
    this.checkAppointment = true;
  }

  onEditedEndHour(time: string) {
    this.form.controls['endHour'].setValue(time);

    if (this.selectedService != null) {
      const service = this.allServices.find(x => x.id == this.selectedService.value);

      const startHour = GetNewTimeBasedOnTheService(service, time, false);
      this.loadTime(startHour, 'startHour');
    }

    this.checkAppointment = true;
  }

  //loadServices(officeOperatorServices: IOfficeOperatorService[]) {
  //  let service: IService = null;

  //  if (officeOperatorServices.length > 0) {
  //    const serviceIds = Array.prototype.map.call(officeOperatorServices, x => x.serviceId).toString();
  //    this.services = this.allServices.filter(x => serviceIds.includes(x.id)).map(x => ({ id: x.id, name: x.name }));
  //    this.selectedService = this.selectedService != null ? this.services.find(x => x.id == this.selectedService.id) : null;
  //    service = this.selectedService != null ? this.allServices.find(x => x.id == this.selectedService.id) : null;
  //  }
  //  else {
  //    this.services = [];
  //    this.selectedService = null;
  //  }

  //  return service;
  //}

  //loadOperators(officeOperatorServices: IOfficeOperatorService[]) {
  //  let operator: IOperator = null;

  //  if (officeOperatorServices.length > 0) {
  //    const operatorIds = Array.prototype.map.call(officeOperatorServices, x => x.operatorId).toString();
  //    this.operators = this.allOperators.filter(x => operatorIds.includes(x.id)).map(x => ({ id: x.id, name: `${x.firstName} ${x.lastName}` }));
  //    this.selectedOperator = this.selectedOperator != null ? this.operators.find(x => x.id == this.selectedOperator.id) : null;
  //    operator = this.selectedOperator != null ? this.allOperators.find(x => x.id == this.selectedOperator.id) : null;
  //  }
  //  else {
  //    this.operators = [];
  //    this.selectedOperator = null;
  //  }

  //  return operator;
  //}

  loadServices(officeOperatorServices: IOfficeOperatorService[]) {
    this.services = [];
    this.selectedService = null;
    this.form.controls['service'].setValue(null);

    if (officeOperatorServices.length > 0) {
      const serviceIds = Array.prototype.map.call(officeOperatorServices, x => x.serviceId).toString();
      this.services = this.allServices.filter(x => serviceIds.includes(x.id)).map(x => ({ value: x.id, text: x.name }));
    }
  }

  loadOperators(officeOperatorServices: IOfficeOperatorService[]) {
    this.operators = [];
    this.selectedOperator = null;
    this.form.controls['operator'].setValue(null);

    if (officeOperatorServices.length > 0) {
      const operatorIds = Array.prototype.map.call(officeOperatorServices, x => x.operatorId).toString();
      this.operators = this.allOperators.filter(x => operatorIds.includes(x.id)).map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));
    }

    if (this.operators.length == 1) {
      //set to form control
      this.selectedOperator = this.operators[0];
      var operator = this.allOperators.find(x => x.id == this.selectedOperator.value);
      this.form.controls['operator'].setValue(operator);

      this.checkCalendarDisable();
    }
  }

  loadWorkSchedule() {
    let minTime = '00:00';
    let maxTime = '00:00';

    if (this.selectedOperator != null && this.date.value != null) {
      const day = new Date(this.date.value).getDay();
      const officeOperatorWorkSchedules = this.allOfficeOperatorWorkSchedule.filter(x => x.officeId == this.selectedOffice.value && x.dayOfTheWeek == day);

      if (officeOperatorWorkSchedules.length > 0) {
        let workSchedules = officeOperatorWorkSchedules.filter(x => !x.isOfficeWorkSchedule && x.serviceId == this.selectedService.value &&
          x.operatorId == this.selectedOperator.value);

        if (workSchedules.length == 0) {
          workSchedules = officeOperatorWorkSchedules.filter(x => x.isOfficeWorkSchedule);
        }

        if (workSchedules.length > 0) {
          const startHours = workSchedules.map(function (item) {
            return item['startHour'];
          });
          minTime = startHours.sort()[0];

          const endHours = workSchedules.map(function (item) {
            return item['endHour'];
          });
          maxTime = endHours.sort().reverse()[0];
        }
      }
    }

    this.minTime = minTime;
    this.maxTime = maxTime;

    if (this.startHour.value != null) {
      this.loadTime(this.startHour.value, 'startHour');
    }

    if (this.endHour.value != null) {
      this.loadTime(this.endHour.value, 'endHour');
    }
  }

  loadTime(time: string, type: string) {
    const dateTime = GetNewDateFromDateAndTime(new Date(), time);
    const minTime = GetNewDateFromDateAndTime(new Date(), this.minTime);
    const maxTime = GetNewDateFromDateAndTime(new Date(), this.maxTime);

    if (dateTime < minTime || dateTime > maxTime) {
      time = null;
    }

    this.form.controls[`${type}`].setValue(time);
  }

  checkCalendarDisable() {
    if (this.selectedOffice != null && this.selectedService != null && this.selectedOperator != null) {
      this.isCalendarDisabled = false;

      return;
    }

    this.isCalendarDisabled = true;
  }

  openCalendarDialog() {
    let appointment = this.appointmentFormService.createAppointmentDto(this.id);

    const allSelectedOperators: IOperator[] = [];
    //allSelectedOperators.push(this.appointment.operator);
    allSelectedOperators.push(this.form.controls['operator'].value);

    const data = {
      settings: this.settings,
      appointment: appointment,
      allSelectedOperators: allSelectedOperators,
      allOfficeOperatorWorkSchedule: this.allOfficeOperatorWorkSchedule
    };

    const dialogRef = this.dialog.open(CalendarWithTimeSlotsComponent, {
      //width: "50rem",
      //minWidth: "30rem",
      width: "60%",
      height: "80%",
      data: data
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response == null) {
        return;
      }

      appointment = response.appointment;

      this.date.setValue(appointment.date);
      this.startHour.setValue(appointment.startHour.toString().slice(0, 5));
      this.endHour.setValue(appointment.endHour.toString().slice(0, 5));
    });
  }

  openClientDialog() {
    const dialogRef = this.dialog.open(ClientComponent, {
      width: "50rem",
      minWidth: "30rem",
      data: null
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response == '') {
        return;
      }

      const request = this.filterGridFormService.createRequest();

      //this.clientService.getClients().subscribe(x => {
      this.clientService.getClientsWithRequest(request).subscribe(x => {
        this.allClients = x.data;
        const client = x.data.find(y => y.id == response);
        this.form.controls['client'].setValue(client);

        this.clients = x.data.map(x => ({ value: x.id, text: `${x.firstName} ${x.lastName}` }));
        this.selectedClient = this.clients.find(y => y.value == response);
      });
    });
  }

  submit() {
    this.loading = true;

    let appointment = this.appointmentFormService.createAppointmentDto(this.id, this.appointment, this.settings);

    if (this.id == null) {
      //create
      this.appointmentService.create(appointment).subscribe(x => {
        this.loading = false;
        this.success = x != null && x.errorCode == null ? true : false;

        if (x != null && x.errorCode != null) {
          this.displayErrorMessage(x.errorCode);
        }

        if (this.isDialog && this.success) {
          this.closeAppointmentDialog(true);
        }

      }, error => {
          console.log('error = ', error);
      });
    }
    else {
      //check if notification is required
      if (JSON.stringify(appointment.status) != JSON.stringify(this.appointment.status)) {
        appointment.sendNotificationRequest = true;
      }

      let crud: string = CRUD.Update;

      if (GetDayWithoutTime(appointment.date) != GetDayWithoutTime(this.appointment.date) || appointment.startHour != this.appointment.startHour ||
        appointment.endHour != this.appointment.endHour) {
        crud = CRUD.Read;
      }

      this.appointmentService.update(appointment, crud).subscribe(x => {
        this.loading = false;
        this.success = x != null ? x.entity : false;

        if (x != null && !x.entity) {
          this.displayErrorMessage(x.errorCode);
        }

        if (this.isDialog && this.success) {
          this.closeAppointmentDialog(true);
        }
      }, error => {
        console.log('error = ', error);
      });
    }
  }

  closeAppointmentDialog(isModified=false) {
    this.closeDialog.emit(isModified);
  }

  onBackToManagement() {
    this.appointmentService.setEnableMonthsInterval(1).subscribe(x => { });
    
    this.router.navigate([`management/${this.userRole}/appointments`], { state: { filters: this.filters } } );
  }

  displayErrorMessage(errorCode: string) {
    this.translate.get(`errors.appointment.create.${this.userRole}.${errorCode}`).subscribe(x => {
      if (x != null) {
        this.openSnackBar(x);
      }
      else {
        this.openSnackBar("An error occurred. Please refresh and try again.");
      }
    });
  }

  openSnackBar(message) {
    this.matSnackBack.open(message, "Ok", {
      duration: 5000
    });
  }
}
