import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { SidenavModulesComponent } from './components/sidenav-modules/sidenav-modules.component';
import { SidenavMenuComponent } from './components/sidenav-menu/sidenav-menu.component';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ListComponent } from './components/users/manage-user/list/list.component';
import { EditComponent } from './components/users/manage-user/edit/edit.component';

import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DateAdapter, MatListModule, MatSelectModule, MatSortModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatStepperModule } from '@angular/material';
import { MatDatepickerModule, } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { KeywordsComponent } from './components/keywords/keywords.component';
import { InfoComponent } from './components/dialogs/info/info.component';
import { ConfirmationComponent } from './components/dialogs/confirmation/confirmation.component';
import { Translate } from '../core/functions/translate-function';
import { SingleSelectionComponent } from './components/selections/single-selection/single-selection.component';
import { MultipleSelectionComponent } from './components/selections/multiple-selection/multiple-selection.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ColorPickerSketchComponent } from './components/color-pickers/color-picker-sketch/color-picker-sketch.component';
import { ColorSketchModule } from 'ngx-color/sketch';
import { DatePickerComponent } from './components/calendars/date-picker/date-picker.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CalendarModule, DateAdapter as CalendarDateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SchedulerComponent } from './components/calendars/scheduler/scheduler.component';
import { DayViewSchedulerComponent } from './components/calendars/scheduler/day-view-scheduler/day-view-scheduler.component';
import { CalendarHeaderComponent } from './components/calendars/scheduler/calendar-header/calendar-header.component';
import { DatePickerIconComponent } from './components/calendars/date-picker-icon/date-picker-icon.component';
import { CustomDateAdapter } from './components/custom-date-adapter';
import { select, Store } from '@ngrx/store';
import * as fromI18n from '../../i18n/reducers';
import localeRo from '@angular/common/locales/ro';
import localeIt from '@angular/common/locales/it';
import { AppointmentComponent as DialogAppointmentComponent} from './components/dialogs/appointment/appointment.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { MultipleSelectionAllCheckboxComponent } from './components/selections/multiple-selection-all-checkbox/multiple-selection-all-checkbox.component';
import { FilterGridComponent } from './components/filter-grid/filter-grid.component';
import { MatPaginatorI18n } from './components/paginator-intl';
import { ClientComponent as DialogClientComponent } from './components/dialogs/client/client.component';
import { ClientComponent } from './components/client/client.component';
import { SingleSelectionWithImageComponent } from './components/selections/single-selection-with-image/single-selection-with-image.component';
import { CalendarWithTimeSlotsComponent as DialogCalendarWithTimeSlotsComponent } from './components/dialogs/calendar-with-time-slots/calendar-with-time-slots.component';
import { CalendarWithTimeSlotsComponent } from './components/calendars/calendar-with-time-slots/calendar-with-time-slots.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SettingsComponent } from './components/users/settings/settings.component';
import { TransactionListComponent } from './components/credit/transaction-list/transaction-list.component';
import { AppointmentNotificationComponent } from './components/appointment-notification/appointment-notification.component';
import { HtmlInfoComponent } from './components/dialogs/html-info/html-info.component';
import { ClientListComponent } from './components/client/client-list/client-list.component';
import { ClientEditComponent } from './components/client/client-edit/client-edit.component';
import { AppointmentListComponent } from './components/appointment/appointment-list/appointment-list.component';
import { AppointmentEditComponent } from './components/appointment/appointment-edit/appointment-edit.component';
import { NotificationSettingsComponent } from './components/notification-settings/notification-settings.component';
import { ExceptionDaysComponent } from './components/exception-days/exception-days.component';
import { ExceptionDaysCalendarComponent } from './components/dialogs/exception-days-calendar/exception-days-calendar.component';
import { ExceptionsByDayComponent } from './components/dialogs/exception-days-calendar/exceptions-by-day/exceptions-by-day.component';
import { ExceptionDaysComponent as DialogExceptionDaysComponent } from './components/dialogs/exception-days/exception-days.component';
import { TimeSlotsComponent } from './components/time-slots/time-slots.component';
import { TimeSlotsCalendarComponent } from './components/calendars/time-slots-calendar/time-slots-calendar.component';
import { CalendarComponent } from './components/calendars/calendar/calendar.component';
import { CalendarComponent as DialogCalendarComponent } from './components/dialogs/calendar/calendar.component';
import { CustomFieldsComponent } from './components/dialogs/custom-fields/custom-fields.component';
import { AppointmentDetailsComponent } from './components/appointment/appointment-details/appointment-details.component';
import { TimePickerComponent as BootstrapTimePickerComponent } from './components/bootstrap/time-picker/time-picker.component';
import { TimepickerActions, TimepickerConfig, TimepickerModule } from 'ngx-bootstrap/timepicker';
import { WorkingHoursComponent } from './components/office/working-hours/working-hours.component';
import { OperatorsWorkingHoursComponent } from './components/office/operators-working-hours/operators-working-hours.component';
import { ServicesWorkingHoursComponent } from './components/office/operators-working-hours/services-working-hours/services-working-hours.component';
import { WeekdayWorkingHoursComponent } from './components/office/working-hours/weekday-working-hours/weekday-working-hours.component';
import { ListInfoComponent } from './components/dialogs/list-info/list-info.component';
import { NgxColorsModule } from 'ngx-colors';
import { ColorsPickerComponent } from './components/color-pickers/colors-picker/colors-picker.component';
import { SingleSelectionCountryComponent } from './components/selections/single-selection-country/single-selection-country.component';

//export function createTranslateLoader(http: HttpClient) {
//  return new TranslateHttpLoader(http, './assets/i18n/management/shared/', '.json');
//}

//export function createTranslateLoader(http: HttpClient) {
//  return new MultiTranslateHttpLoader(http, [
//    { prefix: "./assets/i18n/management/shared/", suffix: ".json" }
//  ]);
//}

export function createTranslateLoader(http: HttpClient) {
  return Translate(http, [
    "/assets/i18n/management/shared/"
  ]);
}

registerLocaleData(localeRo);
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    SidenavModulesComponent,
    SidenavMenuComponent,
    LayoutComponent,
    ListComponent,
    EditComponent,
    KeywordsComponent,
    InfoComponent,
    ConfirmationComponent,
    SingleSelectionComponent,
    MultipleSelectionComponent,
    ColorPickerSketchComponent,
    DatePickerComponent,
    TimePickerComponent,
    SchedulerComponent,
    DayViewSchedulerComponent,
    CalendarHeaderComponent,
    DatePickerIconComponent,
    DialogAppointmentComponent,
    AppointmentComponent,
    MultipleSelectionAllCheckboxComponent,
    FilterGridComponent,
    DialogClientComponent,
    ClientComponent,
    SingleSelectionWithImageComponent,
    DialogCalendarWithTimeSlotsComponent,
    CalendarWithTimeSlotsComponent,
    SettingsComponent,
    TransactionListComponent,
    AppointmentNotificationComponent,
    HtmlInfoComponent,
    ClientListComponent,
    ClientEditComponent,
    AppointmentListComponent,
    AppointmentEditComponent,
    NotificationSettingsComponent,
    ExceptionDaysComponent,
    ExceptionDaysCalendarComponent,
    ExceptionsByDayComponent,
    DialogExceptionDaysComponent,
    TimeSlotsComponent,
    TimeSlotsCalendarComponent,
    CalendarComponent,
    DialogCalendarComponent,
    CustomFieldsComponent,
    AppointmentDetailsComponent,
    BootstrapTimePickerComponent,
    WorkingHoursComponent,
    OperatorsWorkingHoursComponent,
    ServicesWorkingHoursComponent,
    WeekdayWorkingHoursComponent,
    ListInfoComponent,
    ColorsPickerComponent,
    SingleSelectionCountryComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatCardModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    DragDropModule,
    MatStepperModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSortModule,
    NgxMatSelectSearchModule,
    ColorSketchModule,
    NgxMaterialTimepickerModule,
    FlexLayoutModule,
    MatListModule,
    NgxColorsModule,
    TimepickerModule.forRoot(),
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      isolate: true
    }),
    CalendarModule.forRoot({
      provide: CalendarDateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  exports: [
    SidenavModulesComponent,
    SidenavMenuComponent,
    LayoutComponent,
    KeywordsComponent,
    SingleSelectionComponent,
    MultipleSelectionComponent,
    ColorPickerSketchComponent,
    DatePickerComponent,
    TimePickerComponent,
    SchedulerComponent,
    DayViewSchedulerComponent,
    CalendarHeaderComponent,
    DatePickerIconComponent,
    AppointmentComponent,
    MultipleSelectionAllCheckboxComponent,
    FilterGridComponent,
    ClientComponent,
    SingleSelectionWithImageComponent,
    CalendarWithTimeSlotsComponent,
    NotificationSettingsComponent,
    ExceptionDaysComponent,
    ExceptionsByDayComponent,
    TimeSlotsComponent,
    TimeSlotsCalendarComponent,
    CalendarComponent,
    BootstrapTimePickerComponent,
    WorkingHoursComponent,
    OperatorsWorkingHoursComponent,
    ServicesWorkingHoursComponent,
    ColorsPickerComponent,
    SingleSelectionCountryComponent
  ],
  entryComponents: [
    InfoComponent,
    ConfirmationComponent,
    DialogAppointmentComponent,
    DialogClientComponent,
    DialogCalendarWithTimeSlotsComponent,
    HtmlInfoComponent,
    ExceptionDaysCalendarComponent,
    DialogExceptionDaysComponent,
    DialogCalendarComponent,
    CustomFieldsComponent,
    AppointmentDetailsComponent,
    ListInfoComponent
  ],
  providers: [
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18n },
    TimepickerConfig,
    TimepickerActions
  ]
})

export class SharedModule {
  constructor(private readonly store: Store<fromI18n.State>, private dateAdapter: DateAdapter<Date>) {
    this.store.pipe(select(fromI18n.getCurrentLanguage)).subscribe(x => {
      this.dateAdapter.setLocale(x);
    });
  }
}
