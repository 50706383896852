import { Injectable } from '@angular/core';
import { IClient } from '../../../core/interfaces/customer/client';
import { ClientFormService } from './client-form.service';

@Injectable()
export class ClientLoaderService {

  constructor(private clientFormService: ClientFormService) { }

  loadClient(client: IClient) {
    this.clientFormService.form.patchValue({
      firstName: client.firstName,
      lastName: client.lastName,
      phone: client.phone,
      email: client.email,
      description: client.description
    });
  }
}
