import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { HttpClient } from '@angular/common/http';
import { IOperator } from '../../interfaces/customer/operator';
import { Observable } from 'rxjs';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { IResponseCall } from '../../interfaces/common/response-call.interface';

@Injectable()
export class OperatorService {
  private _baseUrl: string = `${ApiBaseUrl}operator`;

  constructor(private httpClient: HttpClient) { }

  getOperators(): Observable<IOperator[]> {
    return this.httpClient.get<IOperator[]>(`${this._baseUrl}`);
  }

  getOperatorsByCustomerCode(customerCode: string): Observable<IOperator[]> {
    return this.httpClient.get<IOperator[]>(`${this._baseUrl}/withCustomerCode/${customerCode}`);
  }

  getOperatorsWithRequest(request: IRequest): Observable<DataSourceResponse<IOperator>> {
    return this.httpClient.post<DataSourceResponse<IOperator>>(`${this._baseUrl}`, request);
  }

  getOperator(id: any): Observable<IOperator> {
    return this.httpClient.get<IOperator>(`${this._baseUrl}/${id}`);
  }

  getOperatorInfo(): Observable<IOperator> {
    return this.httpClient.get<IOperator>(`${this._baseUrl}/info`);
  }


  create(operator: IOperator): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/create`, operator);
  }

  update(operator: IOperator): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, operator);
  }

  delete(operator: IOperator): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, operator);
  }

  uploadFile(fd: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/upload`, fd);
  }
}
