import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { I18nComponent } from '../../../../i18n/containers/i18n.component';
import * as fromI18n from '../../../../i18n/reducers';
import { IKeyword } from '../../../core/interfaces/common/keyword';
import { KeywordService } from '../../../core/services/customer/keyword.service';
import { KeywordsFormService } from './keywords-form.service';
import { KeywordsLoaderService } from './keywords-loader.service';

@Component({
  selector: 'app-keywords',
  templateUrl: './keywords.component.html',
  styleUrls: ['./keywords.component.scss'],
  providers: [
    KeywordsFormService,
    KeywordsLoaderService,
    KeywordService
  ]
})
export class KeywordsComponent extends I18nComponent {
  @Input() parentDiscriminator: string;
  @Input() parentId: string;

  @Output() editedKeywords = new EventEmitter<IKeyword[]>();
  @Output() isKeywordsValid = new EventEmitter<boolean>();

  public allKeywords: IKeyword[] = [];
  public keywords: IKeyword[] = [];

  public isDuplicated: boolean = false;
  public componentLoaded: boolean = false;

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private keywordsFormService: KeywordsFormService,
    private keywordsLoaderService: KeywordsLoaderService,
    private keywordService: KeywordService
  ) {
    super(store, translate);
  }


  ngOnInit() {
    super.ngOnInit();

    this.keywordService.getKeywordsByDiscriminator(this.parentDiscriminator).subscribe(x => {
      if (x == null || x.length == 0) {
        this.componentLoaded = true;

        return;
      }

      this.allKeywords = x;
      this.keywords = x.filter(x => x.parentId == this.parentId);

      this.keywordsLoaderService.loadKeywords(this.keywords);

      this.componentLoaded = true;
    });
  }

  get form() {
    return this.keywordsFormService.form;
  }

  get keywordsForm(): FormArray {
    return this.keywordsFormService.form.get('keywords') as FormArray;
  }

  addKeyword() {
    this.keywordsFormService.addKeyword();

    this.checkDuplicates();

    this.checkFormStatus();
  }

  removeKeyword(index: number) {
    this.keywordsFormService.removeKeyword(index);

    this.checkDuplicates();

    this.checkFormStatus();
  }

  editedWord() {
    this.checkDuplicates();

    this.checkFormStatus();
  }

  checkDuplicates() {
    const keywords = this.allKeywords.filter(x => x.parentId != this.parentId);
    keywords.push(...this.keywordsForm.value);

    const array = keywords.map(function (item) {
      let result: string = '';

      if (item['word'] != null) {
        result = item['word'].toLowerCase();
      }

      const array: string[] = result.trim().split(" ");
      if (array.length == 1) {
        result = result.replace(/\s+/g, '');
      }

      return result;
    });

    this.isDuplicated = (new Set(array)).size !== array.length;
  }

  checkFormStatus() {
    const status = this.form.status;

    if (status == "VALID" && !this.isDuplicated) {
      const keywords = this.keywordsFormService.createKeywordDtos();

      this.editedKeywords.emit(keywords);
      this.isKeywordsValid.emit(true);

      return;
    }

    this.isKeywordsValid.emit(false);
  }
}
