export const ImageSrcType = {
  Default: 'default',
  Clients: 'clients',
  Countries: 'countries',
  Operators:"operators"
};

export const DefaultImageNames = {
  Users: 'users.png',
  Categories: 'categories.png',
  Services: 'services.png',
  ServiceDuration:"service-duration.png",
  Operators: 'operators.png',
  Offices: 'offices.png',
  Statuses: 'statuses.png',
  Clients: 'clients.png',
  Appointments: 'appointments.png',
  Agenda: 'agenda.png',
  Settings: 'settings.png',
  White: 'white.png',
  NoLogo: 'nologo.png',
  NoProfilePhoto: 'no-avatar.png',
  Success: 'success.png',
  PhoneBooking: 'phone-booking.png',
  Credit: "credit-icon.png",
  ReportNotifications: "report-notification-icon.png",
  ContactDetails: 'contact-details.png',
  FixApp: 'fixapp.png'
};
