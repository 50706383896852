import { Component, OnInit } from '@angular/core';
import { I18nComponent } from '../../../../../i18n/containers/i18n.component';
import { ClientService } from '../../../../core/services/customer/client.service';
import { FilterGridFormService } from '../../filter-grid/filter-grid-form.service';
import { ClientDataSource } from '../../../../core/datasource/customer/client.datasource';
import { IClient } from '../../../../core/interfaces/customer/client';
import { BehaviorSubject } from 'rxjs';
import { IFilterGridData } from '../../../../core/interfaces/common/filter-grid-data';
import * as fromI18n from '../../../../../i18n/reducers';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material';
import { ConfirmationComponent } from '../../dialogs/confirmation/confirmation.component';
import { AuthorizeService } from '../../../../../../api-authorization/authorize.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
   providers: [
    ClientService,
    FilterGridFormService
  ]
})
export class ClientListComponent extends I18nComponent {

  public dataSource: ClientDataSource | null;

  public clients: IClient[] = [];

  public clientSbj: BehaviorSubject < IClient[] > = new BehaviorSubject<IClient[]>([]);

  public displayedColumns: string[] = ["FirstName", "LastName", "Phone", "Email", "Actions"];

  public loading: boolean = false;

  public page: number = 1;
  public pageSize: number = 20;
  public pageSubject: BehaviorSubject < number > = new BehaviorSubject<number>(null);

  public filterArray: IFilterGridData[] = [];
  public sortField: string = null;
  public sortDirection: string = null;
  public totalItems: number;
  public userRole: string = "customer";

  constructor(
    readonly store: Store < fromI18n.State >,
    readonly translate: TranslateService,
    private clientService: ClientService,
    private router: Router,
    private route: ActivatedRoute,
    private titleClient: Title,
    private dialog: MatDialog,
    private authorizeService:AuthorizeService,
    private filterGridFormService: FilterGridFormService
  ) {
    super(store, translate);

    //this.clientService.getClients().subscribe(x => this.clientSbj.next((this.clients = x)));
  }

  ngOnInit() {
    super.ngOnInit();

    this.translate.get("clients.list.title").subscribe(x => this.titleClient.setTitle(x));

    this.dataSource = new ClientDataSource(this.clientSbj);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p == null) {
        return;
      }

      this.loadClients();
    });

    this.authorizeService.getUserRole().subscribe({ next: data => this.userRole = data.toLowerCase() });

    this.loadClients();
  }

  loadClients() {
    this.loading = true;
    this.page = this.page != null ? this.page : 1;

    const request = this.filterGridFormService.createRequest(true, null, this.filterArray, null, false,
      this.page, this.pageSize, this.sortDirection, this.sortField);

    this.clientService.getClientsWithRequest(request).subscribe({
      next: data => {
        this.clientSbj.next(data.data);
        this.totalItems = data.total;
        this.loading = false;
      }
    });
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  addFilterComponent(event: IFilterGridData) {
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      const existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.page = 1;
    this.loadClients();
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.loadClients();
  }

  edit(id) {
    this.router.navigate([`./edit/${id}`], { relativeTo: this.route })
  }

  openDeleteDialog(element: IClient) {
    this.translate.get("confirmation.deleteQuestion").subscribe(x => {
      const dialogRef = this.dialog.open(ConfirmationComponent, {
        minWidth: "25rem",
        minHeight: "7rem",
        data: `${x} ${element.firstName} ${element.lastName}?`
      });

      dialogRef.afterClosed().subscribe(response => {
        if (!response) {
          return;
        }

        this.delete(element);
      });
    });
  }

  delete (element: IClient) {
    this.clientService.delete(element).subscribe(x => {
      if (x) {
        this.loadClients();
      }
    }, error => {
      console.log('delete error = ', error);
    });
  }

  addClient() {
    this.router.navigate([`/management/${this.userRole}/clients/create`]);
  }

}
