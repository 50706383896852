import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IOrderStep } from '../../../../core/interfaces/customer/order-step';
import { ISettings } from '../../../../core/interfaces/customer/settings';

@Injectable()
export class SettingsFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      maxOperators: [0, [
        Validators.required,
        Validators.min(1)
      ]],
      maxOffices: [0, [
        Validators.required,
        Validators.min(1)
      ]]
    });
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createSettingsDto(settings: ISettings, customerCode: string): ISettings {
    const data = this.form.value;

    if (settings == null) {
      settings = {
        customerCode: customerCode,
        slotDuration: 0,
        timeSystem: 0,
        enableAllOperatorsAvailability: false,
        allOperatorsAvailabilityName: null,
        enableHelp: false,
        helpName: null,
        helpMessage: null,
        urlSuffix: null,
        logo: null,
        logoMobilePosition: null,
        logoDesktopPosition: null,
        isDefaultBackgroundColor: true,
        backgroundColor: null,
        isDefaultTextColor: true,
        textColor: null,
        languageCode: null
      };
    }

    settings.maxOperators = data.maxOperators;
    settings.maxOffices = data.maxOffices;

    return settings;
  }
}
