import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagementHeaderComponent } from './management/management-header/management-header.component';
import { ManagementLayoutComponent } from './management/management-layout/management-layout.component';
import { RouterModule } from '@angular/router';
import { ApiAuthorizationModule } from '../../api-authorization/api-authorization.module';
import { MatMenuModule } from '@angular/material/menu'
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { I18nModule } from '../i18n/i18n.module';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ROOT_REDUCERS, metaReducers } from '../reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { Translate } from '../management/core/functions/translate-function';
import { SharedModule } from '../management/shared/shared.module';
import { MatButtonModule, MatDialogModule } from '@angular/material';

//export function createTranslateLoader(http: HttpClient) {
//  return new TranslateHttpLoader(http, './assets/i18n/layout/', '.json');
//}

export function createTranslateLoader(http: HttpClient) {
  return Translate(http, [
    "/assets/i18n/layout/",
    "/assets/i18n/management/customer/appointments/",
    "/assets/i18n/appointments/booking/",
    "/assets/i18n/management/customer/clients/",
    "/assets/i18n/management/shared/selections/",
    "/assets/i18n/management/shared/confirmation/",
    "/assets/i18n/management/shared/time-picker/",
    "/assets/i18n/management/shared/calendars/",
    "/assets/i18n/management/shared/errors/"
  ]);
}

@NgModule({
  declarations: [
    ManagementLayoutComponent,
    ManagementHeaderComponent,],
  imports: [
    CommonModule,
    RouterModule,
    ApiAuthorizationModule,
    MatMenuModule,
    I18nModule,
    SharedModule,
    MatButtonModule,
    MatDialogModule,
    StoreModule.forRoot(ROOT_REDUCERS, { metaReducers }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal
    }),
    StoreDevtoolsModule.instrument({
      name: 'NgRx AppointmentApp'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: true
    })
  ],
  exports: [
    ManagementLayoutComponent,
    ManagementHeaderComponent
  ]
})
export class LayoutModule { }
