import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageSelectorComponent } from './containers/language-selector/language-selector.component';
import { StoreModule } from '@ngrx/store';
import * as fromI18n from './reducers';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    LanguageSelectorComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatButtonModule,
    StoreModule.forFeature(fromI18n.i18nFeatureKey, fromI18n.reducers)
  ],
  exports: [
    LanguageSelectorComponent
  ]
})
export class I18nModule { }
