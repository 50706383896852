import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GetNewDateFromDateAndTime, GetTimeFromDate } from '../../../../core/functions/date-function';

@Component({
  selector: 'app-bootstrap-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnChanges {
  /** selected time */
  @Input() selectedTime: string;

  /** the minimum time that can be selected */
  @Input() min: string = null;

  /** the maximum time that can be selected */
  @Input() max: string = null;

  @Input() title: string = '';

  /** time to be returned */
  @Output() editedTime = new EventEmitter<string>();

  public timeCtrl: FormControl = new FormControl();
  public minCtrl: Date = null;
  public maxCtrl: Date = null;

  constructor() { }

  ngOnInit() {
    this.timeCtrl.valueChanges.subscribe(x => {
      if (x != null) {
        var time = GetTimeFromDate(x);

        this.editedTime.emit(time);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTime != undefined && changes.selectedTime.currentValue != null) {
      var formatedTime = GetNewDateFromDateAndTime(new Date(), changes.selectedTime.currentValue);

      this.timeCtrl.setValue(formatedTime);
    }

    if (changes.min != undefined && changes.min.currentValue != null) {
      var formatedTime = GetNewDateFromDateAndTime(new Date(), changes.min.currentValue);

      this.minCtrl = formatedTime;
    }

    if (changes.max != undefined && changes.max.currentValue != null) {
      var formatedTime = GetNewDateFromDateAndTime(new Date(), changes.max.currentValue);

      this.maxCtrl = formatedTime;
    }
  }
}
