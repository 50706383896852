import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { IKeyword } from '../../interfaces/common/keyword';

@Injectable()
export class KeywordService {
  private _baseUrl: string = `${ApiBaseUrl}keyword`;

  constructor(private httpClient: HttpClient) { }

  getKeywordsByDiscriminator(parentDiscriminator: string): Observable<IKeyword[]> {
    return this.httpClient.get<IKeyword[]>(`${this._baseUrl}/${parentDiscriminator}`);
  }
}
