import { Injectable } from '@angular/core';
import { IOfficeOperatorService } from '../../../../core/interfaces/customer/office-operator-service';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { IService } from '../../../../core/interfaces/customer/service';
import { OperatorsWorkingHoursFormService } from './operators-working-hours-form.service';

@Injectable()
export class OperatorsWorkingHoursLoaderService {
  public selectedItem: any = {
    operator: null,
    operatorServices: [],
    officeOperatorServices: [],
    schedule: []
  };

  constructor(private operatorsWorkingHoursFormService: OperatorsWorkingHoursFormService) { }

  loadOperators(operators: IOperator[], services: IService[], allOfficeOperatorServices: IOfficeOperatorService[], workSchedule: IOfficeOperatorWorkSchedule[]) {
    return this.patchOperatorsArray(operators, services, allOfficeOperatorServices, workSchedule);
  }

  patchOperatorsArray(operators: IOperator[], services: IService[], allOfficeOperatorServices: IOfficeOperatorService[], workSchedule: IOfficeOperatorWorkSchedule[]) {
    try {
      if (operators != null && operators.length > 0) {
        Object(operators).forEach(operator => {
          let isChecked: boolean = false;
          let operatorServices: IService[] = [];
          let officeOperatorServices: IOfficeOperatorService[] = [];

          if (services != null && services.length > 0) {
            operatorServices = services.filter(o => operator.servicesIds.includes(o.id));
          }

          if (allOfficeOperatorServices != null && allOfficeOperatorServices.length > 0) {
            officeOperatorServices = allOfficeOperatorServices.filter(x => x.operatorId == operator.id);

            isChecked = officeOperatorServices.length > 0 ? true : false;
          }

          var schedule = workSchedule.filter(x => x.operatorId == operator.id);

          this.operatorsWorkingHoursFormService.addOperatorWithValue(operator, isChecked, operatorServices, officeOperatorServices, schedule);

          if (isChecked && this.selectedItem.operator == null) {
            this.selectedItem = {
              operator: operator,
              operatorServices: operatorServices,
              officeOperatorServices: officeOperatorServices,
              schedule: schedule
            };
          }
        });
      }
    } catch (err) {
      console.log('Operators services error = ', err);
    }

    return this.selectedItem;
  }
}
