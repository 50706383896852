import { Component, OnInit, Input } from '@angular/core';
import { IMenuItem } from '../../../core/interfaces/menu-item';
import { I18nComponent } from '../../../../i18n/containers/i18n.component';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as fromI18n from '../../../../i18n/reducers';

@Component({
  selector: 'app-sidenav-modules',
  templateUrl: './sidenav-modules.component.html',
  styleUrls: ['./sidenav-modules.component.scss']
})
export class SidenavModulesComponent extends I18nComponent {
  @Input('modules') modules: IMenuItem[];

  constructor(readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService) {
      super(store, translate);
  }

  ngOnInit() {
  }

}
