import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { GetDayWithoutTime } from '../../../../../core/functions/date-function';
import { IExceptionDay } from '../../../../../core/interfaces/customer/exception-day';
import { ExceptionsByDayFormService } from './exceptions-by-day-form.service';
import { ExceptionsByDayLoaderService } from './exceptions-by-day-loader.service';

@Component({
  selector: 'app-exceptions-by-day',
  templateUrl: './exceptions-by-day.component.html',
  styleUrls: ['./exceptions-by-day.component.scss'],
  providers: [
    ExceptionsByDayFormService,
    ExceptionsByDayLoaderService
  ]
})
export class ExceptionsByDayComponent implements OnInit, OnChanges {
  @Input() exceptionDays: IExceptionDay[];
  @Input() officeId: string;
  @Input() operatorId: string;
  @Input() date: Date;
  @Input() type: string;

  @Output() editedExceptionDays = new EventEmitter<IExceptionDay[]>();
  @Output() isFormValid = new EventEmitter<boolean>();

  public disabledAllDay: boolean;
  public disabled: boolean;

  public componentLoaded: boolean;

  constructor(
    private exceptionsByDayFormService: ExceptionsByDayFormService,
    private exceptionsByDayLoaderService: ExceptionsByDayLoaderService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.exceptionDays != undefined) {
      this.exceptionDays = changes.exceptionDays.currentValue;
    }

    if (changes.date != undefined) {
      this.date = changes.date.currentValue;
    }

    this.loadExceptionsByDay();
  }

  loadExceptionsByDay() {
    let date: Date = new Date(GetDayWithoutTime(this.date));
    const today: Date = new Date(GetDayWithoutTime(new Date()));

    if (date < today) {
      this.disabled = true;
      this.isAllDay.disable();
    }
    else {
      this.disabled = false;
      this.isAllDay.enable();

      if (date.getFullYear() == today.getFullYear() && date.getMonth() == today.getMonth() && date.getDate() == today.getDate()) {
        this.isAllDay.disable();

        date = new Date();
      }
    }

    this.removeAllTimes();

    this.exceptionsByDayLoaderService.loadExceptionsByDay(this.exceptionDays);

    if (!this.disabled && this.form.value.times.length == 0) {
      this.addTime();
    }

    this.checkFormStatus();

    this.componentLoaded = true;
  }

  get form() {
    return this.exceptionsByDayFormService.form;
  }

  get isAllDay() {
    return this.exceptionsByDayFormService.getFormControl('isAllDay') as FormControl;
  }

  get timesForm(): FormArray {
    return this.exceptionsByDayFormService.form.get('times') as FormArray;
  }

  addTime() {
    this.exceptionsByDayFormService.addTime();

    this.checkFormStatus();
  }

  removeTime(index: number) {
    this.exceptionsByDayFormService.removeTime(index);

    this.checkFormStatus();
  }

  removeAllTimes() {
    this.exceptionsByDayFormService.removeAllTimes();
  }

  onChangeTime(index: number, name: string, value: any) {
    this.exceptionsByDayFormService.changeTimeValue(index, name, value);

    this.checkFormStatus();
  }

  onChangeAllDay(isChecked: boolean) {
    this.removeAllTimes();

    if (!isChecked) {
      this.addTime();

      return;
    }

    this.checkFormStatus();
  }

  checkFormStatus() {
    const status = this.form.status;

    if (status == "VALID" && !this.disabled) {
      const exceptionDays = this.exceptionsByDayFormService.createExceptionDayDtos(this.officeId, this.operatorId, this.date, this.type);

      this.editedExceptionDays.emit(exceptionDays);
      this.isFormValid.emit(true);

      return;
    }

    this.isFormValid.emit(false);
  }
}
