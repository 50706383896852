import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { INotificationSettings } from '../../interfaces/common/notification-settings';

@Injectable()
export class NotificationSettingsService {
  private _baseUrl: string = `${ApiBaseUrl}notificationsettings`;

  constructor(private httpClient: HttpClient) { }

  getNotificationSettings(): Observable<INotificationSettings> {
    return this.httpClient.get<INotificationSettings>(`${this._baseUrl}`);
  }

  getNotificationSettingsByCustomerCode(customerCode: string): Observable<INotificationSettings> {
    return this.httpClient.get<INotificationSettings>(`${this._baseUrl}/${customerCode}`);
  }

  update(notificationSettings: INotificationSettings): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, notificationSettings);
  }
}
