import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { INotificationSettings } from '../../../core/interfaces/common/notification-settings';
import { IClient } from '../../../core/interfaces/customer/client';
import { NotificationSettingsService } from '../../../core/services/common/notification-settings.service';
import { NotificationSettingsFormService } from './notification-settings-form.service';
import { NotificationSettingsLoaderService } from './notification-settings-loader.service';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
  providers: [
    NotificationSettingsService,
    NotificationSettingsFormService,
    NotificationSettingsLoaderService
  ]
})
export class NotificationSettingsComponent implements OnInit {
  @Input() client: IClient = null;
  @Input() showInfo: boolean = false;

  @Output() editedNotificationSettings = new EventEmitter<INotificationSettings>();
  @Output() isFormValid = new EventEmitter<boolean>();

  public componentLoaded: boolean = false;

  constructor(
    readonly translate: TranslateService,
    private notificationSettingsService: NotificationSettingsService,
    private notificationSettingsFormService: NotificationSettingsFormService,
    private notificationSettingsLoaderService: NotificationSettingsLoaderService
  ) { }

  ngOnInit() {
    if (this.client == null) {
      this.notificationSettingsService.getNotificationSettings().subscribe(x => {
        if (x != null && x.customerCode != null) {
          this.loadNotificationSettings(x);

          return;
        }

        const notificationSettings = this.createNotificationSettings(null);
        this.loadNotificationSettings(notificationSettings);
      });
    }
    else {
      if (this.client.customerCode != null) {
        this.notificationSettingsService.getNotificationSettingsByCustomerCode(this.client.customerCode).subscribe(x => {
          if (x != null && x.customerCode != null) {
            this.loadNotificationSettings(x);

            return;
          }

          //create from client info
          const notificationSettings = this.createNotificationSettings(this.client);
          this.loadNotificationSettings(notificationSettings);
        });
      }

    }
  }

  loadNotificationSettings(notificationSettings: INotificationSettings) {
    this.notificationSettingsLoaderService.loadNotificationSettings(notificationSettings);

    this.enableEmail.valueChanges.pipe().subscribe(value => {
      this.notificationSettingsLoaderService.checkValidators(value, ['email']);
    });

    this.enableSms.valueChanges.pipe().subscribe(value => {
      this.notificationSettingsLoaderService.checkValidators(value, ['smsPhone']);
    });

    this.enableVoice.valueChanges.pipe().subscribe(value => {
      this.notificationSettingsLoaderService.checkValidators(value, ['voicePhone']);
    });

    this.componentLoaded = true;
    this.checkFormStatus();
  }

  createNotificationSettings(client: IClient) {
    const notificationSettings: INotificationSettings = {
      id: null,
      enableEmail: false,
      email: null,
      enableSms: false,
      smsPhone: null,
      enableVoice: false,
      voicePhone: null
    };

    if (client != null) {
      if (client.email) {
        notificationSettings.enableEmail = true;
        notificationSettings.email = client.email;
      }

      if (client.phone) {
        notificationSettings.enableSms = true;
        notificationSettings.smsPhone = client.phone;
      }

      //enableVoice remain on false, because this option is not available now
    }

    return notificationSettings;
  }

  get form() {
    return this.notificationSettingsFormService.form;
  }

  get enableEmail() {
    return this.notificationSettingsFormService.getFormControl('enableEmail') as FormControl;
  }

  get email() {
    return this.notificationSettingsFormService.getFormControl('email') as FormControl;
  }

  get enableSms() {
    return this.notificationSettingsFormService.getFormControl('enableSms') as FormControl;
  }

  get smsPhone() {
    return this.notificationSettingsFormService.getFormControl('smsPhone') as FormControl;
  }

  get enableVoice() {
    return this.notificationSettingsFormService.getFormControl('enableVoice') as FormControl;
  }

  get voicePhone() {
    return this.notificationSettingsFormService.getFormControl('voicePhone') as FormControl;
  }

  checkFormStatus() {
    if (this.enableEmail.value || this.enableSms.value || this.enableVoice.value) {
      const status = this.form.status;

      if (status === "VALID") {
        const notificationSettings = this.notificationSettingsFormService.createNotificationSettingsDto();
        this.editedNotificationSettings.emit(notificationSettings);
        this.isFormValid.emit(true);

        return;
      }
    }

    this.isFormValid.emit(false);
  }
}
