import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-exception-days',
  templateUrl: './exception-days.component.html',
  styleUrls: ['./exception-days.component.scss']
})
export class ExceptionDaysComponent implements OnInit {
  public officeId: string = null;
  public type: string = null;

  constructor(public dialogRef: MatDialogRef<ExceptionDaysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (data != null) {
      this.officeId = data.officeId;
      this.type = data.type;
    }
  }

  ngOnInit() {
  }

  closeDialog(event) {
    this.dialogRef.close(event);
  }
}
