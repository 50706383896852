import { Component, OnInit } from '@angular/core';
import { IMenuItem } from '../../../core/interfaces/menu-item';
import { ActivatedRoute } from '@angular/router';
import { UserRoles } from '../../../../../api-authorization/api-authorization.constants';
import { I18nComponent } from '../../../../i18n/containers/i18n.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as fromI18n from '../../../../i18n/reducers';
import { GetImageSrc } from '../../../core/functions/file-function';
import { DefaultImageNames, ImageSrcType } from '../../../core/constants/src.constants';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent extends I18nComponent {

  public modules: IMenuItem[];

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private route: ActivatedRoute) {
    super(store, translate);
    this.route.data.subscribe(data => this.loadModules(data));
  }

  ngOnInit() {
    super.ngOnInit();
  }

  loadModules(data) {
    if (typeof data.allowedRoles == "undefined" || data.allowedRoles == null || data.allowedRoles.length == 0) {
      return;
    }

    this.modules = this.getModules(data.allowedRoles[0]);
  }

  getModules(role): IMenuItem[] {
    switch (role) {
      case UserRoles.Admin: {
        return [
          {
            icon: 'icofont-users-alt-5',
            title: 'modules.users.title',
            url: '/management/admin/users',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Users)
          },
          {
            icon: 'icofont-money',
            title: 'modules.credit.title',
            url: '/management/admin/credit',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Credit)
          },
          {
            icon: 'icofont-settings',
            title: 'modules.settings.title',
            url: '/management/admin/settings',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Settings)
          },
        ];
      }
      case UserRoles.Customer: {
        return [
          {
            icon: 'icofont-calendar',
            title: 'modules.agenda.title',
            url: '/management/customer/agenda',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Agenda)
          },
          {
            icon: 'icofont-meeting-add',
            title: 'modules.appointments.title',
            url: '/management/customer/appointments',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Appointments)
          },
          {
            icon: 'icofont-files-stack',
            title: 'modules.categories.title',
            url: '/management/customer/categories',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Categories)
          },
          {
            icon: 'icofont-light-bulb',
            title: 'modules.services.title',
            url: '/management/customer/services',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Services)
          },
          {
            icon: 'icofont-users-alt-5',
            title: 'modules.operators.title',
            url: '/management/customer/operators',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Operators)
          },
          {
            icon: 'icofont-google-map',
            title: 'modules.offices.title',
            url: '/management/customer/offices',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Offices)
          },
          {
            icon: 'icofont-settings',
            title: 'modules.statuses.title',
            url: '/management/customer/statuses',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Statuses)
          },
          {
            icon: 'icofont-ui-settings',
            title: 'modules.settings.title',
            url: '/management/customer/settings',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Settings)
          },
          {
            icon: 'icofont-users-social',
            title: 'modules.clients.title',
            url: '/management/customer/clients',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Clients)
          },
          {
            icon: 'icofont-paper-plane',
            title: 'modules.notifications.title',
            url: '/management/customer/notifications',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.ReportNotifications)
          },
          {
            icon: '',
            title: 'modules.phone-booking.title',
            url: '/management/customer/phone-booking',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.PhoneBooking)
          }
        ];
      }
      case UserRoles.Operator: {
        return [
          {
            icon: 'icofont-calendar',
            title: 'modules.agenda.title',
            url: '/management/operator/agenda',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Agenda)
          },
          {
            icon: 'icofont-meeting-add',
            title: 'modules.appointments.title',
            url: '/management/operator/appointments',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Appointments)
          },
          {
            icon: 'icofont-users-social',
            title: 'modules.clients.title',
            url: '/management/operator/clients',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Clients)
          },
          {
            icon: 'icofont-ui-settings',
            title: 'modules.settings.title',
            url: '/management/operator/settings',
            src: GetImageSrc(ImageSrcType.Default, DefaultImageNames.Settings)
          }
          
        ];
      }
      default: { return []; }
    }
  }
}
