import { Injectable } from '@angular/core';
import { ISettings } from '../../../../core/interfaces/customer/settings';
import { SettingsFormService } from './settings-form.service';

@Injectable()
export class SettingsLoaderService {
  constructor(private settingsFormService: SettingsFormService) { }

  loadSettings(settings: ISettings) {
    if (settings != null) {
      this.settingsFormService.form.patchValue({
        maxOperators: settings.maxOperators,
        maxOffices: settings.maxOffices
      });
    }
  }
}
