import { Component, OnInit } from '@angular/core';
import { AppointmentNotificationDatasource } from '../../../core/datasource/customer/appointment-notification-datasource';
import { BehaviorSubject } from 'rxjs';
import { IAppointmentNotification } from '../../../core/interfaces/customer/appointment-notification.interface';
import { IFilterGridData } from '../../../core/interfaces/common/filter-grid-data';
import { I18nComponent } from '../../../../i18n/containers/i18n.component';
import { FilterGridFormService } from '../filter-grid/filter-grid-form.service';
import { Store } from '@ngrx/store';
import * as fromI18n from '../../../../i18n/reducers';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';
import { AppointmentNotificationService } from '../../../core/services/customer/appointment-notification.service';
import { HtmlInfoComponent } from '../dialogs/html-info/html-info.component';
import { ISelection } from '../../../core/interfaces/common/selection';
import { GetMonthsInterval } from '../../../core/functions/date-function';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-appointment-notification',
  templateUrl: './appointment-notification.component.html',
  styleUrls: ['./appointment-notification.component.scss'],
  providers: [AppointmentNotificationService, FilterGridFormService ]
})
export class AppointmentNotificationComponent extends I18nComponent {
  public dataSource: AppointmentNotificationDatasource | null;

  public listSbj: BehaviorSubject<IAppointmentNotification[]> = new BehaviorSubject<IAppointmentNotification[]>([]);

  public displayedColumns: string[] = ["Receiver", "Sender", "Timestamp", "Price", "status", "actions"];

  public loading: boolean = false;

  public page: number = 1;
  public pageSize: number = 20;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public filterArray: IFilterGridData[] = [];
  public sortField: string = "Timestamp";
  public sortDirection: string = "desc";
  public totalItems: number;
  
  public selectedStartDate: string;
  public selectedEndDate: string;
  public filterDateLoaded: boolean = false;
  public channelsLoaded: boolean = false;
  public channels: ISelection[]=[];
  public selectedChannel: string;
  public dialogTitle: string;
  public selectedMonthsInterval: ISelection[] = [];
  public channel = new FormControl;

  constructor(readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private titleClient: Title,
    private requestService: AppointmentNotificationService,
    private dialog: MatDialog,
    private filterGridFormService: FilterGridFormService) {

    super(store, translate);
  }

  ngOnInit() {
    super.ngOnInit();

    this.translate.get("list.title").subscribe(x => this.titleClient.setTitle(x));
    this.translate.get("list.dialogTitle").subscribe(x => this.dialogTitle=x);

    const date = new Date();

    this.selectedStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-01`;
    this.selectedEndDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.selectedMonthsInterval = GetMonthsInterval(this.selectedStartDate, this.selectedEndDate);
    this.filterDateLoaded = true;

    this.channels.push({
      text: "SMS",
      value: 'Sms'
    });
    this.channels.push({
      text: "Email",
      value: 'Email'
    });
    this.channelsLoaded = true;

    this.dataSource = new AppointmentNotificationDatasource(this.listSbj);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p == null) {
        return;
      }

      this.load();
    });

    this.channel.valueChanges.subscribe(value => {
      this.selectedChannel = value;
      this.totalItems = 0;
      this.listSbj.next([]);
    });
  }

  onEditedChannel(channel) {
    this.selectedChannel = channel.value;
  }

  search() {
    this.pageSubject.next(1);
  }

  load() {

    if (this.selectedChannel == null || this.selectedChannel == "") {
      return;
    }

    this.loading = true;
    this.page = this.page != null ? this.page : 1;

    
    const filterArray: IFilterGridData[] = [];
    filterArray.push(...this.filterArray);

    const startDateFilter = this.filterGridFormService.createFilter(
      'Timestamp', 'CreatedTimestamp', 'Greater Than Or Equals', this.selectedStartDate);
    filterArray.push(startDateFilter);

    const endDateFilter = this.filterGridFormService.createFilter(
      'Timestamp', 'CreatedTimestamp', 'Less Than Or Equals', this.selectedEndDate);
    filterArray.push(endDateFilter);

    let request = this.filterGridFormService.createRequest(true, null, filterArray, null, false,
      this.page, this.pageSize, this.sortDirection, this.sortField, this.selectedMonthsInterval);

    this.requestService.get(request, this.selectedChannel).subscribe({
      next: data => {
        if (data == null) {
          return
        }

        data.data.map(item => {
          this.translate.get(`list.${item.status}`).subscribe(x => item.statusLabel = x);

          item.errorMessageLabel = this.getErrorMessage(item.errorMessage);
        });

        this.listSbj.next(data.data);
        this.loading = false;

        if (request.page == 1) {
          this.totalItems = data.total;
        }

      }
    })
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  onEditedStartDateFilter(date: Date) {
    this.selectedStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.selectedMonthsInterval = GetMonthsInterval(this.selectedStartDate, this.selectedEndDate);
  }

  onEditedEndDateFilter(date: Date) {
    this.selectedEndDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    this.selectedMonthsInterval = GetMonthsInterval(this.selectedStartDate, this.selectedEndDate);
  }

  copyRequestId(element: IAppointmentNotification) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = element.requestId;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  viewContent(element:IAppointmentNotification) {
    const dialogRef = this.dialog.open(HtmlInfoComponent, {
      minWidth: "25rem",
      maxWidth: "35rem",
      minHeight: "10rem",
      data: {
        title: this.dialogTitle,
        content: element.content
      }
    });
  }

  addFilterComponent(event: IFilterGridData) {
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      const existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.page = 1;
    this.load();
  }

  getErrorMessage(errorMessage: string): string {

    if (errorMessage === null || errorMessage == "") {
      return "";
    }

    let messageLabel: string = "";

    switch (errorMessage) {
      case "CustomerNoAvailableCredits": {
        this.translate.get(`list.${errorMessage}`).subscribe(x => messageLabel = x);
        break;
      }
      case "CustomerInsufficientCredits":{
        this.translate.get(`list.${errorMessage}`).subscribe(x => messageLabel = x);
        break;
      }
      default: {
        this.translate.get(`list.contactAdmin`).subscribe(x => messageLabel = x);
        break;
      }
      
    }
    return messageLabel;
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.load();
  }

}
