import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ICustomField } from '../../../../core/interfaces/common/custom-field';

@Component({
  selector: 'app-custom-fields',
  templateUrl: './custom-fields.component.html',
  styleUrls: ['./custom-fields.component.scss']
})
export class CustomFieldsComponent implements OnInit {
  public customFields: ICustomField[] = [];

  constructor(public dialogRef: MatDialogRef<CustomFieldsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | []) {

    this.customFields = data;
  }

  ngOnInit() {
  }

  copyToClipboard(field: ICustomField) {
    this.dialogRef.close(field);
  }
}
