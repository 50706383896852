import { WeekDay } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray } from '@angular/forms';
import { IOfficeOperatorWorkSchedule } from '../../../../../core/interfaces/customer/office-operator-work-schedule';
import { WeekdayWorkingHoursFormService } from './weekday-working-hours-form.service';
import { WeekdayWorkingHoursLoaderService } from './weekday-working-hours-loader.service';

@Component({
  selector: 'app-weekday-working-hours',
  templateUrl: './weekday-working-hours.component.html',
  styleUrls: ['./weekday-working-hours.component.scss'],
  providers: [
    WeekdayWorkingHoursFormService,
    WeekdayWorkingHoursLoaderService
  ]
})
export class WeekdayWorkingHoursComponent implements OnInit {
  @Input() workSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() weekday: WeekDay;
  @Input() officeId: string = null;
  @Input() operatorId: string = null;
  @Input() serviceId: string = null;
  @Input() returnDtos: boolean = false;

  @Output() editedWorkSchedule = new EventEmitter<IOfficeOperatorWorkSchedule[]>();
  @Output() editedWeekDay = new EventEmitter<WeekDay>();
  @Output() isFormValid = new EventEmitter<boolean>();

  public componentLoaded: boolean = false;

  constructor(
    private weekdayWorkingHoursFormService: WeekdayWorkingHoursFormService,
    private weekdayWorkingHoursLoaderService: WeekdayWorkingHoursLoaderService
  ) { }

  ngOnInit() {
    var weekdayWorkSchedule: IOfficeOperatorWorkSchedule[] = this.workSchedule.filter(x => x.dayOfTheWeek == this.weekday);

    this.weekdayWorkingHoursLoaderService.loadWorkingHours(weekdayWorkSchedule);

    this.checkFormStatus();

    this.componentLoaded = true;
  }

  get form() {
    return this.weekdayWorkingHoursFormService.form;
  }

  get workingHoursForm(): FormArray {
    return this.weekdayWorkingHoursFormService.form.get('workingHours') as FormArray;
  }

  addWorkingHours() {
    this.weekdayWorkingHoursFormService.addWorkingHours(null);

    this.checkFormStatus();
  }

  removeWorkingHours(index: number) {
    this.weekdayWorkingHoursFormService.removeWorkingHours(index);

    this.checkFormStatus();
  }

  onChangeTime(index: number, name: string, value: any) {
    this.weekdayWorkingHoursFormService.changeTimeValue(index, name, value);

    this.checkFormStatus();
  }

  checkFormStatus() {
    const status = this.form.status;

    if (status == "VALID") {
      this.editedWeekDay.emit(this.weekday);

      const dtos = this.weekdayWorkingHoursFormService.createOfficeOperatorWorkScheduleDtos(this.weekday, this.officeId, this.operatorId, this.serviceId);
      this.editedWorkSchedule.emit(dtos);
      this.isFormValid.emit(true);

      return;
    }

    this.isFormValid.emit(false);
  }
}
