import { Injectable } from '@angular/core';
import { ISelection } from '../../../../../core/interfaces/common/selection';
import { IOfficeOperatorService } from '../../../../../core/interfaces/customer/office-operator-service';
import { ServicesWorkingHoursFormService } from './services-working-hours-form.service';

@Injectable()
export class ServicesWorkingHoursLoaderService {

  constructor(private servicesWorkingHoursFormService: ServicesWorkingHoursFormService) { }

  loadServices(operatorServices: ISelection[], officeOperatorServices: IOfficeOperatorService[]) {
    this.patchServicesArray(operatorServices, officeOperatorServices);
  }

  patchServicesArray(operatorServices: ISelection[], officeOperatorServices: IOfficeOperatorService[]) {
    try {
      Object(operatorServices).forEach(x => {
        var officeOperatorService = officeOperatorServices.find(s => s.serviceId == x.value);

        this.servicesWorkingHoursFormService.addService(x.value, x.text, officeOperatorService);
      });
    }
    catch (err) {
      console.log('Services split error = ', err);
    }
  }
}
