import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormArray } from '@angular/forms';
import { combineLatest } from 'rxjs';
import { IOfficeOperatorService } from '../../../../core/interfaces/customer/office-operator-service';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { OfficeService } from '../../../../core/services/customer/office.service';
import { OperatorService } from '../../../../core/services/customer/operator.service';
import { ServiceService } from '../../../../core/services/customer/service.service';
import { FilterGridFormService } from '../../filter-grid/filter-grid-form.service';
import { OperatorsWorkingHoursFormService } from './operators-working-hours-form.service';
import { OperatorsWorkingHoursLoaderService } from './operators-working-hours-loader.service';

@Component({
  selector: 'app-operators-working-hours',
  templateUrl: './operators-working-hours.component.html',
  styleUrls: ['./operators-working-hours.component.scss'],
  providers: [
    OperatorsWorkingHoursFormService,
    OperatorsWorkingHoursLoaderService,
    OperatorService,
    ServiceService,
    FilterGridFormService
  ],
  encapsulation: ViewEncapsulation.None
})
export class OperatorsWorkingHoursComponent implements OnInit {
  @Input() workSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() officeId: string = null;

  @Output() editedOfficeOperatorServices = new EventEmitter<IOfficeOperatorService[]>();
  @Output() editedWorkSchedule = new EventEmitter<IOfficeOperatorWorkSchedule[]>();
  @Output() isFormValid = new EventEmitter<boolean>();

  public selectedItem: any = {
    operator: null,
    operatorServices: [],
    officeOperatorServices: [],
    schedule: []
  };

  public officeOperatorServices: IOfficeOperatorService[] = [];
  public schedule: IOfficeOperatorWorkSchedule[] = [];

  public componentLoaded: boolean = false;

  constructor(
    private operatorsWorkingHoursFormService: OperatorsWorkingHoursFormService,
    private operatorsWorkingHoursLoaderService: OperatorsWorkingHoursLoaderService,
    private operatorService: OperatorService,
    private serviceService: ServiceService,
    private officeService: OfficeService,
    private filterGridFormService: FilterGridFormService,
  ) { }

  ngOnInit() {
    const request = this.filterGridFormService.createRequest(false, null, [], null, true);

    this.schedule = this.workSchedule;

    if (this.officeId == null) {
      combineLatest(
        this.operatorService.getOperatorsWithRequest(request),
        this.serviceService.getServicesWithRequest(request),
        (operators, services) => ({ operators, services })
      ).subscribe(pair => {
        this.operatorsWorkingHoursLoaderService.loadOperators(pair.operators.data, pair.services.data, [], []);

        this.componentLoaded = true;
      });

      return;
    }

    const officeIdFilter = this.filterGridFormService.createFilter('String', 'OfficeId', 'Equals', this.officeId);
    const oosRequest = this.filterGridFormService.createRequest(false, null, [officeIdFilter]);

    combineLatest(
      this.operatorService.getOperatorsWithRequest(request),
      this.serviceService.getServicesWithRequest(request),
      this.officeService.getAllOfficeOperatorServiceWithRequest(oosRequest),
      (operators, services, officeOperatorServices) => ({ operators, services, officeOperatorServices })
    ).subscribe(pair => {
      this.selectedItem = this.operatorsWorkingHoursLoaderService.loadOperators(pair.operators.data, pair.services.data,
        pair.officeOperatorServices.data, this.workSchedule);

      this.officeOperatorServices = pair.officeOperatorServices.data;

      this.componentLoaded = true;

      return;
    });
  }

  get form() {
    return this.operatorsWorkingHoursFormService.form;
  }

  get operatorsForm(): FormArray {
    return this.operatorsWorkingHoursFormService.form.get('operators') as FormArray;
  }

  onSelectOperator(item: any, index: number) {
    this.selectedItem = item;

    this.operatorsForm.at(index).get('isChecked').setValue(true);
  }

  onChangeOperator(checked: boolean, item: any, index: number) {
    if (checked) {
      this.onSelectOperator(item, index);

      return;
    }

    this.officeOperatorServices = this.officeOperatorServices.filter(x => x.operatorId != item.operator.id);
    this.schedule = this.schedule.filter(x => x.operatorId != item.operator.id);

    this.operatorsForm.at(index).get('isChecked').setValue(false);
    this.operatorsForm.at(index).get('officeOperatorServices').setValue([]);
    this.operatorsForm.at(index).get('schedule').setValue([]);

    this.selectedItem = {
      operator: null,
      operatorServices: [],
      officeOperatorServices: [],
      schedule: []
    };

    this.checkFormStatus();
  }

  onEditedOfficeOperatorServices(officeOperatorServices: IOfficeOperatorService[]) {
    this.officeOperatorServices = this.officeOperatorServices.filter(x => x.operatorId != this.selectedItem.operator.id);
    var operatorForm = this.operatorsForm.value.find(x => x.operator.id == this.selectedItem.operator.id);
    operatorForm.officeOperatorServices = [];

    if (officeOperatorServices.length > 0) {
      this.officeOperatorServices.push(...officeOperatorServices);
      operatorForm.officeOperatorServices.push(...officeOperatorServices);
    }

    this.checkFormStatus();
  }

  onEditedWorkSchedule(workSchedule: IOfficeOperatorWorkSchedule[]) {
    this.schedule = this.schedule.filter(x => x.operatorId != this.selectedItem.operator.id);
    var operatorForm = this.operatorsForm.value.find(x => x.operator.id == this.selectedItem.operator.id);
    operatorForm.schedule = [];

    if (workSchedule.length > 0) {
      this.schedule.push(...workSchedule);
      operatorForm.schedule.push(...workSchedule);
    }

    this.checkFormStatus();
  }

  onServicesFormValid(isValid: boolean) {
    if (!isValid) {
      this.isFormValid.emit(false);
    }
  }

  checkFormStatus() {
    const status = this.form.status;

    if (status == "VALID") {
      this.editedOfficeOperatorServices.emit(this.officeOperatorServices);
      this.editedWorkSchedule.emit(this.schedule);
      this.isFormValid.emit(true);

      return;
    }

    this.isFormValid.emit(false);
  }
}
