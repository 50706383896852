import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ITimeSlot } from '../../../../core/interfaces/common/time-slot';
import { ITimeSlotParameters } from '../../../../core/interfaces/common/time-slot-parameters';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';
import { IExceptionDay } from '../../../../core/interfaces/customer/exception-day';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { ISettings } from '../../../../core/interfaces/customer/settings';
import { AgendaService } from '../../../../core/services/common/agenda.service';
import { ExceptionDayService } from '../../../../core/services/customer/exception-day.service';
import { FilterGridFormService } from '../../filter-grid/filter-grid-form.service';

@Component({
  selector: 'app-calendar-with-time-slots',
  templateUrl: './calendar-with-time-slots.component.html',
  styleUrls: ['./calendar-with-time-slots.component.scss'],
  providers: [
    FilterGridFormService,
    ExceptionDayService,
    AgendaService
  ]
})
export class CalendarWithTimeSlotsComponent implements OnInit {
  @Input() settings: ISettings = null;
  @Input() appointment: IAppointment = null;
  @Input() allSelectedOperators: IOperator[] = [];
  @Input() allOfficeOperatorWorkSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() showActionButtons: boolean = false;

  @Output() editedData = new EventEmitter<any>();

  public exceptionDays: IExceptionDay[] = [];
  public timeSlots: ITimeSlot[] = [];

  public wasSelected: boolean = false;
  public selectedOperator: IOperator = null;
  public selectedDate: Date = new Date();
  public minDate: Date = new Date();

  public appointmentDuration: number = 0;

  public startHour: any;
  public endHour: any;

  public componentLoaded: boolean = false;
  public durationExceeded: boolean = false;

  constructor(
    private filterGridFormService: FilterGridFormService,
    private exceptionDayService: ExceptionDayService,
    private agendaService: AgendaService
  ) { }

  ngOnInit() {
    if (this.settings == null || this.appointment == null || this.appointment.service == null) {
      return;
    }

    this.appointmentDuration = this.appointment.service.appointmentDuration + this.appointment.service.intervalBeforeAppointment +
      this.appointment.service.intervalAfterAppointment;

    if (this.appointment.date != null) this.selectedDate = new Date(this.appointment.date);
    this.startHour = this.appointment.startHour;
    this.endHour = this.appointment.endHour;

    this.loadExceptionDays();
  }

  loadExceptionDays() {
    const request = this.filterGridFormService.createRequest(false, this.settings.customerCode);

    this.exceptionDayService.getExceptionDaysWithRequest(request).subscribe({
      next: data => {
        this.exceptionDays = data.data;

        this.createTimeSlots();
      }
    });
  }

  createTimeSlots() {
    const date: any = `${this.selectedDate.getFullYear()}-${this.selectedDate.getMonth() + 1}-${this.selectedDate.getDate()}`;

    const timeSlotParameters: ITimeSlotParameters = {
      customerCode: this.settings.customerCode,
      officeOperatorWorkSchedules: this.allOfficeOperatorWorkSchedule,
      exceptionDays: this.exceptionDays,
      settings: this.settings,
      office: this.appointment.office,
      service: this.appointment.service,
      operators: this.allSelectedOperators,
      date: date,
      startHour: this.startHour,
      endHour: this.endHour
    };

    this.agendaService.getTimeSlots(timeSlotParameters).subscribe(items => {
      items.forEach(x => {
        x.startHour = x.startHour.toString().slice(0, 5);
        x.endHour = x.endHour.toString().slice(0, 5);
      });

      this.timeSlots = items;
    });

    this.componentLoaded = true;
  }

  onSelectDate(date: Date, calendar: any) {
    this.wasSelected = false;
    this.durationExceeded = false;

    this.selectedDate = date;

    const appointmentDate: Date = new Date(this.appointment.date);

    if (appointmentDate.getFullYear() == date.getFullYear() && appointmentDate.getMonth() == date.getMonth() &&
      appointmentDate.getDate() == date.getDate()) {
      this.startHour = this.appointment.startHour;
      this.endHour = this.appointment.endHour;
    }
    else {
      this.startHour = null;
      this.endHour = null;
    }

    this.createTimeSlots();

    if (!this.showActionButtons) {
      var obj = {
        "wasSelected": false
      };

      this.editedData.emit(obj);
    }
  }

  onSelectSlot(slot: ITimeSlot) {
    this.durationExceeded = false;
    const timeSlotParameters: ITimeSlotParameters = {
      settings: this.settings,
      service: this.appointment.service,
      operators: this.allSelectedOperators,
      startHour: slot.startHour,
      timeSlots: this.timeSlots
    };

    this.agendaService.selectTimeSlots(timeSlotParameters).subscribe(item => {
      item.timeSlots.forEach(x => {
        x.startHour = x.startHour.toString().slice(0, 5);
        x.endHour = x.endHour.toString().slice(0, 5);
      });

      this.timeSlots = item.timeSlots;
      this.startHour = item.startHour;
      this.endHour = item.endHour;
      this.selectedOperator = item.operator;
      this.wasSelected = item.timeSlots.filter(x => x.isSelected).length > 0 ? true : false;

      if (this.wasSelected == false) this.durationExceeded = true;
     
      if (!this.showActionButtons) {
        this.continue();
      }
    });
  }

  cancel() {
    this.editedData.emit(null);
  }

  continue() {
    var selectedDate: any = `${this.selectedDate.getFullYear()}-${this.selectedDate.getMonth() + 1}-${this.selectedDate.getDate()}`;

    this.appointment.date = selectedDate;
    this.appointment.startHour = this.startHour;
    this.appointment.endHour = this.endHour;
    this.appointment.operator = this.selectedOperator;

    var obj = {
      "appointment": this.appointment,
      "wasSelected": this.wasSelected
    };

    this.editedData.emit(obj);
  }
}
