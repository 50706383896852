import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';

@Component({
  selector: 'app-calendar-header',
  templateUrl: './calendar-header.component.html',
  styleUrls: ['./calendar-header.component.scss']
})
export class CalendarHeaderComponent {
  @Input() view: CalendarView;

  @Input() viewDate: Date;

  @Input() locale: string = 'en';

  @Input() operatorName: string = '';

  @Input() weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  @Output() viewChange = new EventEmitter<CalendarView>();

  @Output() viewDateChange = new EventEmitter<Date>();

  CalendarView = CalendarView;
}
