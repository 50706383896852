import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { IStatus } from '../../interfaces/customer/status';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { IStatusTemplate } from '../../interfaces/customer/status-template';

@Injectable()
export class StatusService {
  private _baseUrl: string = `${ApiBaseUrl}status`;

  constructor(private httpClient: HttpClient) { }

  getStatuses(): Observable<IStatus[]> {
    return this.httpClient.get<IStatus[]>(`${this._baseUrl}`);
  }

  getStatusesWithRequest(request: IRequest): Observable<DataSourceResponse<IStatus>> {
    return this.httpClient.post<DataSourceResponse<IStatus>>(`${this._baseUrl}`, request);
  }

  getStatus(id: any): Observable<IStatus> {
    return this.httpClient.get<IStatus>(`${this._baseUrl}/${id}`);
  }

  create(status: IStatus): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/create`, status);
  }

  update(status: IStatus): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, status);
  }

  delete(status: IStatus): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, status);
  }

  getStatusTemplates(statusId: any): Observable<IStatusTemplate[]> {
    return this.httpClient.get<IStatusTemplate[]>(`${this._baseUrl}/templates/${statusId}`);
  }
}
