import { Time } from "@angular/common";
import { MonthNames } from "../constants/calendar.constants";
import { ISelection } from "../interfaces/common/selection";
import { IService } from "../interfaces/customer/service";

export function GetNewDateFromDateAndTime(date: Date, time: string): Date {
  const timeArr = time.split(':');
  const hours = parseInt(timeArr[0]);
  const minutes = parseInt(timeArr[1]);

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes);
}

export function GetNewTimeBasedOnTheService(service: IService, time: string, addMinutes: boolean): string {
  const arrTime = time.split(':');
  const timeHours = parseInt(arrTime[0]);
  const timeMinutes = parseInt(arrTime[1]);

  const appointmentDuration = service.appointmentDuration + service.intervalBeforeAppointment + service.intervalAfterAppointment;
  const allMinutes = addMinutes ? timeMinutes + appointmentDuration : timeMinutes - appointmentDuration;

  const newTimeHours = Math.floor(allMinutes / 60) + timeHours;
  const hours = newTimeHours.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  const newTimeMinutes = addMinutes ? allMinutes % 60 : -allMinutes % 60;
  const minutes = newTimeMinutes.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });

  return `${hours}:${minutes}`;
}

export function GetTimeFromDate(date: Date): string {
  return ("00" + (date.getHours())).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);
}

export function GetDisplayMonth(date: Date) {
  const month = date.getMonth() + 1;
  return month < 10 ? '0' + month : month;
}

export function GetMonthsInterval(startDate: string, endDate: string, translations: string = null): ISelection[] {
  const start = startDate.split('-');
  const end = endDate.split('-');
  const startYear = parseInt(start[0]);
  const endYear = parseInt(end[0]);
  const dates = [];
  const monthsInterval: ISelection[] = [];

  for (let i = startYear; i <= endYear; i++) {
    const endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    const startMonth = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (let j = startMonth; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      const month = j + 1;
      const displayMonth = month < 10 ? '0' + month : month;

      dates.push([i, displayMonth, '01'].join('-'));
    }
  }

  for (let i = dates.length - 1; i >= 0; i--) {
    const value = dates[i];
    let text = '';

    if (translations !== null) {
      const date = new Date(value);
      const monthName = translations[`months.${MonthNames[date.getMonth()]}`];

      text = `${monthName} ${date.getFullYear()}`;
    }

    monthsInterval.push({
      value: value,
      text: text
    });
  }

  return monthsInterval;
}

export function GetDayWithoutTime(d: Date) {
  const date: Date = new Date(d);

  const newDate = `${date.getFullYear()}-${("00" + (date.getMonth() + 1)).slice(-2)}-${("00" + date.getDate()).slice(-2)}`;

  return newDate;
}

export function GetDayWithTime(d: Date, time: string) {
  const timeArr = time.split(':');
  const hours = parseInt(timeArr[0]);
  const minutes = parseInt(timeArr[1]);

  const date: Date = new Date(d);

  const newDate = `${date.getFullYear()}-${("00" + (date.getMonth() + 1)).slice(-2)}-${("00" + date.getDate()).slice(-2)} ${hours}:${minutes}`;

  return newDate;
}

export function GetNextDay(date: Date, next: boolean = true) {
  if (next) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0);
  }

  return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0);
}
