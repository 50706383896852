import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {
  public id: any = null;

  constructor(public dialogRef: MatDialogRef<ClientComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    this.id = data;
  }

  ngOnInit() {
  }

  closeDialog($event) {
    this.dialogRef.close($event);
  }
}
