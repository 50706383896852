import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {
  /** selected date */
  @Input() selectedDate: Date;

  @Input() title: string = null;

  /** date to be returned */
  @Output() editedDate = new EventEmitter<Date>();

  public date: FormControl = new FormControl();

  constructor(
    readonly translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.selectedDate != null) {
      this.date.setValue(new Date(this.selectedDate));
    }

    if (this.title == null) {
      this.translate.get(`date-picker.placeholder`).subscribe(t => {
        this.title = t;
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate != undefined) {
      this.selectedDate = changes.selectedDate.currentValue;
      this.date.setValue(new Date(this.selectedDate));
    }
  }

  onChange($event) {
    this.editedDate.emit($event.value);
  }
}
