export const Modules = {
  Agenda: 'agenda',
  Appointments: 'appointments',
  Billing: 'billing',
  Categories: 'categories',
  Clients: 'clients',
  Offices: 'offices',
  Operators: 'operators',
  PhoneBooking: 'phone-booking',
  Services: 'services',
  Settings: 'settings',
  Statuses: 'statuses',
  Users: 'users',
  Credit: "credit",
  Notifications:"notifications"
};

//export const ApiBaseUrl = "/AppointmentApp/api/"; //UNCOMMENT ON PUBLISH
export const ApiBaseUrl = "/api/"; //COMMENT ON PUBLISH

export const MaxListLength = 20;
