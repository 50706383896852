import { Component, OnInit } from '@angular/core';
import { AuthorizeService } from '../../api-authorization/authorize.service';
import { ClientService } from '../management/core/services/customer/client.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { UserRoles, ApplicationPaths, QueryParameterNames } from '../../api-authorization/api-authorization.constants';

@Component({
  selector: 'app-initial-routing',
  templateUrl: './initial-routing.component.html',
  providers: [ClientService]
})
export class InitialRoutingComponent implements OnInit {

  constructor(private authorizeService: AuthorizeService,
    private router: Router,
    clientService: ClientService) {
    
    authorizeService.getUserRole().subscribe({
      next: data => {
        //console.log("user role initial routing", data);
        if (data != null && data == UserRoles.Client) {
          //console.log("is client");
          clientService.getClientUrlSufix().subscribe({
            next: suffix => {
             // console.log("suffix=", suffix);
              router.navigate([`appointments/${suffix}`]);
            }
          })
          
        }
        else {
          if (data != null) {
            //console.log("return to management", data.toLowerCase());
            //redirect to management
            router.navigate([`/management/${data.toLowerCase()}`]);
          }
          else {
            //redirect to login
            localStorage.clear();
            //console.log("return to login", ApplicationPaths.LoginPathComponents);
            setTimeout(async () => await this.navigateToReturnUrl('/authentication/login'), 300);
            
          }
         
        }
      }
    })
  }

  ngOnInit() {
  }

  private async navigateToReturnUrl(returnUrl: string) {
    await this.router.navigateByUrl(returnUrl, {
      replaceUrl: true
    });
  }
}
