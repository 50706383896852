import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { INotificationSettings } from '../../../core/interfaces/common/notification-settings';

@Injectable()
export class NotificationSettingsFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      id: null,
      enableEmail: false,
      email: null,
      enableSms: false,
      smsPhone: null,
      enableVoice: false,
      voicePhone: null,
    });
  }

  setValidators(controlNames: string[]) {
    controlNames.map(value => {
      const control = this.form.get(value);

      if (value === 'email') {
        control.setValidators([
          Validators.required,
          Validators.pattern("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$")
        ]);
      }
      else {
        control.setValidators([
          Validators.required,
          //Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
        ]);
      }

      control.updateValueAndValidity();
    });
  }

  clearValidators(controlNames: string[]) {
    controlNames.map(value => {
      const control = this.form.get(value);
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createNotificationSettingsDto(): INotificationSettings {
    const data = this.form.value;

    const client: INotificationSettings = {
      id: data.id,
      enableEmail: data.enableEmail,
      email: data.email,
      enableSms: data.enableSms,
      smsPhone: data.smsPhone,
      enableVoice: data.enableVoice,
      voicePhone: data.voicePhone
    };

    return client;
  }
}
