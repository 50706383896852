import { Component, OnInit } from '@angular/core';
import * as fromI18n from '../../../../../i18n/reducers';
import { I18nComponent } from '../../../../../i18n/containers/i18n.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-appointment-edit',
  templateUrl: './appointment-edit.component.html',
  styleUrls: ['./appointment-edit.component.scss']
})
export class AppointmentEditComponent extends I18nComponent {
  public id: any = null;

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private route: ActivatedRoute
  ) {
    super(store, translate);
  }

  ngOnInit() {
    super.ngOnInit();

    this.id = this.route.snapshot.paramMap.get("id");
  }
}

