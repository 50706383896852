import { Injectable } from '@angular/core';
import { IKeyword } from '../../../core/interfaces/common/keyword';
import { KeywordsFormService } from './keywords-form.service';

@Injectable()
export class KeywordsLoaderService {

  constructor(private keywordsFormService: KeywordsFormService) { }

  loadKeywords(keywords: IKeyword[]) {
    this.patchKeywordsArray(keywords);
  }

  patchKeywordsArray(keywords: IKeyword[]) {
    if (keywords != null && keywords.length > 0) {
      try {
        Object(keywords).forEach(x => {
          this.keywordsFormService.addKeywordWithValue(x);
        });
      }
      catch (err) {
        console.log('Keywords split error = ', err);
      }
    }
  }
}
