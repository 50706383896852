import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { HttpClient } from '@angular/common/http';
import { IService } from '../../interfaces/customer/service';
import { Observable } from 'rxjs';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';

@Injectable()
export class ServiceService {
  private _baseUrl: string = `${ApiBaseUrl}service`;

  constructor(private httpClient: HttpClient) { }

  getServices(): Observable<IService[]> {
    return this.httpClient.get<IService[]>(`${this._baseUrl}`);
  }

  getServicesByCustomerCode(customerCode: string): Observable<IService[]> {
    return this.httpClient.get<IService[]>(`${this._baseUrl}/withCustomerCode/${customerCode}`);
  }

  getServicesWithRequest(request: IRequest): Observable<DataSourceResponse<IService>> {
    return this.httpClient.post<DataSourceResponse<IService>>(`${this._baseUrl}`, request);
  }

  getService(id: any): Observable<IService> {
    return this.httpClient.get<IService>(`${this._baseUrl}/${id}`);
  }

  create(service: IService): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/create`, service);
  }

  update(service: IService): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, service);
  }

  delete(service: IService): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, service);
  }
}
