import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { HttpClient } from '@angular/common/http';
import { IRequest } from '../../interfaces/common/request';
import { Observable } from 'rxjs';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { ICustomerCredit } from '../../interfaces/customer/customer-credit.interface';
import { ICustomerCreditTransaction } from '../../interfaces/customer/customer-credit-transaction.interface';

@Injectable()
export class CreditService {

  private _baseUrl: string = `${ApiBaseUrl}customercredit`;

  constructor(private httpClient: HttpClient) { }

  getCustomersCredit(request: IRequest): Observable<DataSourceResponse<ICustomerCredit>> {
    return this.httpClient.post<DataSourceResponse<ICustomerCredit>>(`${this._baseUrl}/credit`, request);
  }

  getCustomerCreditTransactions(request: IRequest): Observable<DataSourceResponse<ICustomerCreditTransaction>> {
    return this.httpClient.post<DataSourceResponse<ICustomerCreditTransaction>>(`${this._baseUrl}/transaction`, request);
  }

  addTransaction(transaction: ICustomerCreditTransaction): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/add-credit`, transaction);
  }

  getCurrentCredit(): Observable<ICustomerCredit> {
    return this.httpClient.get<any>(`${this._baseUrl}/customer-credit`);
  }
}
