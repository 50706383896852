import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-dialog-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  public selectedDate: Date;

  constructor(public dialogRef: MatDialogRef<CalendarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (data == null) {
      return;
    }

    this.selectedDate = data;
  }

  ngOnInit() {
  }

  onEditedData($event) {
    this.dialogRef.close($event);
  }
}
