import { Component, OnInit, ViewChild } from '@angular/core';
import { I18nComponent } from '../../../../../i18n/containers/i18n.component';
import { FilterGridFormService } from '../../filter-grid/filter-grid-form.service';
import { Store } from '@ngrx/store';
import * as fromI18n from '../../../../../i18n/reducers';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from '../../../../core/services/customer/credit.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatPaginator } from '@angular/material';
import { CustomerCreditTransactionDatasource } from '../../../../core/datasource/customer/customer-credit-transaction-datasource';
import { ICustomerCreditTransaction } from '../../../../core/interfaces/customer/customer-credit-transaction.interface';
import { BehaviorSubject } from 'rxjs';
import { IFilterGridData } from '../../../../core/interfaces/common/filter-grid-data';
import { AuthorizeService } from '../../../../../../api-authorization/authorize.service';
import { UserRoles } from '../../../../../../api-authorization/api-authorization.constants';
import { AddCreditComponent } from '../../../../admin/credit/add-credit/add-credit.component';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss'],
  providers: [CreditService, FilterGridFormService]
})
export class TransactionListComponent extends I18nComponent {
  public dataSource: CustomerCreditTransactionDatasource | null;

  public transactionListSbj: BehaviorSubject<ICustomerCreditTransaction[]> = new BehaviorSubject<ICustomerCreditTransaction[]>([]);

  public displayedColumns: string[] = ["Amount", "CreatedTimestamp", "details"];

  public loading: boolean = false;

  public page: number = 1;
  public pageSize: number = 20;
  public pageSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  public filterArray: IFilterGridData[] = [];
  public sortField: string = "CreatedTimestamp";
  public sortDirection: string = "desc";
  public totalItems: number;
  public isAdmin: boolean = null;
  public customerCode: string = null;
  public customerCodeSbj: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public email: string = "";
  public currentCredit: number = 0;

  public selectedStartDate: string;
  public selectedEndDate: string;
  public filterDateLoaded: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private titleClient: Title,
    private creditService: CreditService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private filterGridFormService: FilterGridFormService,
    private authorizeService:AuthorizeService) {

    super(store, translate);

    authorizeService.getUserRole().subscribe({
      next: data => {
        if (data == UserRoles.Admin) {
          this.isAdmin = true;
          this.displayedColumns = ["Amount", "CreatedTimestamp", "details"];

          var code = this.route.snapshot.paramMap.get("code")
          this.customerCodeSbj.next(code);
          this.customerCode = code;
        }
        else {
          this.isAdmin = false;
          this.displayedColumns = ["Amount", "CreatedTimestamp", "method", "status"];
        }
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();

    this.translate.get("credit.list.title").subscribe(x => this.titleClient.setTitle(x));

    const date = new Date();

    this.selectedStartDate = `${date.getFullYear()}-${date.getMonth()+1}-01`;
    this.selectedEndDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;

    this.filterDateLoaded = true;

    this.dataSource = new CustomerCreditTransactionDatasource(this.transactionListSbj);

    this.pageSubject.subscribe(p => {
      this.page = p;
      if (p == null) {
        return;
      }

      this.load();
    });

    this.customerCodeSbj.subscribe(code => {
      this.customerCode = code;

      this.load();
    })
  }

  search() {
    this.pageSubject.next(1);
  }

  load() {

    if (this.isAdmin == true && this.customerCode == null) {
      return;
    }

    this.loading = true;
    this.page = this.page != null ? this.page : 1;

    let request = this.filterGridFormService.createRequest(true, this.customerCode, this.filterArray, null, false,
      this.page, this.pageSize, this.sortDirection, this.sortField);

    if (this.isAdmin == false) {
      const filterArray: IFilterGridData[] = [];
      filterArray.push(...this.filterArray);

      const startDateFilter = this.filterGridFormService.createFilter(
        'Timestamp', 'CreatedTimestamp', 'Greater Than Or Equals', this.selectedStartDate);
      filterArray.push(startDateFilter);

      const endDateFilter = this.filterGridFormService.createFilter(
        'Timestamp', 'CreatedTimestamp', 'Less Than Or Equals', this.selectedEndDate);
      filterArray.push(endDateFilter);

       request = this.filterGridFormService.createRequest(true, null, filterArray, null, false,
        this.page, this.pageSize, this.sortDirection, this.sortField);
    }

    this.creditService.getCustomerCreditTransactions(request).subscribe({
      next: data => {
        if (data == null) {
          return
        }

        data.data.map(item => {
          this.translate.get(`credit.transactions.${item.status}`).subscribe(x => item.statusLabel = x);
          this.translate.get(`credit.transactions.${item.paymentMethod}`).subscribe(x => item.paymentMethodLabel = x);
        });

        this.transactionListSbj.next(data.data);
        this.loading = false;

        if (request.page == 1) {
          this.totalItems = data.total;
          this.currentCredit =+ data.aggregates["CustomerCredit"];
          this.email = data.aggregates["CustomerEmail"];
        }

      }
    })
  }

  handlePage(e) {
    this.pageSubject.next(e.pageIndex + 1);
  }

  onEditedStartDateFilter(date: Date) {
    this.selectedStartDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
   // this.selectedEndDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()+1}`;
  }

  onEditedEndDateFilter(date: Date) {
    //this.selectedStartDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
    this.selectedEndDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }

  addFilterComponent(event: IFilterGridData) {
    if (event.value == null) {
      this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
    }
    else {
      const existingFilter = this.filterArray.filter(x => x.propertyName == event.propertyName);

      if (existingFilter.length > 0) {
        this.filterArray = this.filterArray.filter(x => x.propertyName != event.propertyName);
      }

      this.filterArray.push(event);

    }

    this.page = 1;
    this.load();
  }

  sortData(event) {
    this.sortDirection = event.direction;
    this.sortField = event.active;

    this.page = 1;

    //call again the load method;
    this.load();
  }

  addCredit() {
    const dialogRef = this.dialog.open(AddCreditComponent, {
      minWidth: "20rem",
      maxWidth: "25rem",
      minHeight: "25rem",
      data: {
        email: this.email,
        customerCode: this.customerCode
      }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (!response || (response != null && (response.saved == false || response.transaction == null))) {
        return;
      }

      this.handleResponse(response.transaction);
    });
  }

  handleResponse(transaction:ICustomerCreditTransaction) {
    var arr = this.transactionListSbj.value;

    arr.unshift(transaction);

    this.transactionListSbj.next(arr);
    this.totalItems += 1;
    this.currentCredit += transaction.amount;
  }

  cancel() {
    this.router.navigate([`management/admin/credit`])
  }
}
