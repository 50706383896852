import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";
import { CustomFieldsTypeList, CustomFieldsWithKey, PhoneBookingAgendaCustomFields, PhoneBookingCustomFields } from "../constants/custom-fields.constants";

export function Translate(http: HttpClient, paths: string[]) {
  const translatedResource = paths.map(x => ({
    prefix: `.${x}`, suffix: ".json"
  }));

  return new MultiTranslateHttpLoader(http, translatedResource);
}

export function GetTranslatedName(item: string, translate: TranslateService) {
  let translatedItem: string;

  translate.get(item).subscribe(x => translatedItem = x);

  return translatedItem;
}

export function TranslateCustomFields(translate: TranslateService) {
  translate
    .stream([
      "custom-fields.client.firstName",
      "custom-fields.client.lastName",
      "custom-fields.client.phone",
      "custom-fields.client.email",

      "custom-fields.appointment.date",
      "custom-fields.appointment.startHour",
      "custom-fields.appointment.endHour",
      "custom-fields.appointment.note",
      "custom-fields.appointment.cancelUrl",
      "custom-fields.appointment.uniqueCode",

      "custom-fields.office.name",
      "custom-fields.office.address",
      "custom-fields.office.phone",
      "custom-fields.office.email",
      "custom-fields.office.description",

      "custom-fields.service.name",
      "custom-fields.service.duration",
      "custom-fields.service.price",
      "custom-fields.service.description",

      "custom-fields.operator.firstName",
      "custom-fields.operator.lastName",
      "custom-fields.operator.email",
      "custom-fields.operator.phone"
    ])
    .subscribe(translations => {

      Object(CustomFieldsTypeList).forEach(type => {
        Object(CustomFieldsWithKey[type]).forEach(customField => {
          customField.name = translations[`custom-fields.${customField.translateCode}`];
        });
      });
    });
}

export function TranslatePhoneBookingCustomFields(translate: TranslateService) {
  translate
    .stream([
      "custom-fields.client.firstName",
      "custom-fields.client.lastName",
      "custom-fields.service.name",
      "custom-fields.operator.firstName",
      "custom-fields.operator.lastName",
      "custom-fields.office.address",
      "custom-fields.office.name",
      "custom-fields.appointment.date",
      "custom-fields.appointment.startHour",
      "custom-fields.appointment.endHour",
      "custom-fields.appointment.weekDay"
    ])
    .subscribe(translations => {

      Object(PhoneBookingCustomFields).forEach(customField => {
        customField.name = translations[`custom-fields.${customField.translateCode}`];
      });

      Object(PhoneBookingAgendaCustomFields).forEach(customField => {
        customField.name = translations[`custom-fields.${customField.translateCode}`];
      });
    });
}
