import { Injectable } from '@angular/core';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IOffice } from '../../interfaces/customer/office';
import { IOfficeOperatorService } from '../../interfaces/customer/office-operator-service';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { IOfficeOperatorWorkSchedule } from '../../interfaces/customer/office-operator-work-schedule';

@Injectable()
export class OfficeService {
  private _baseUrl: string = `${ApiBaseUrl}office`;

  constructor(private httpClient: HttpClient) { }

  getOffices(): Observable<IOffice[]> {
    return this.httpClient.get<IOffice[]>(`${this._baseUrl}`);
  }

  getOfficesByCustomerCode(customerCode: string): Observable<IOffice[]> {
    return this.httpClient.get<IOffice[]>(`${this._baseUrl}/withCustomerCode/${customerCode}`);
  }

  getOfficesWithRequest(request: IRequest): Observable<DataSourceResponse<IOffice>> {
    return this.httpClient.post<DataSourceResponse<IOffice>>(`${this._baseUrl}`, request);
  }

  getOffice(id: any): Observable<IOffice> {
    return this.httpClient.get<IOffice>(`${this._baseUrl}/${id}`);
  }

  create(office: IOffice): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/create`, office);
  }

  update(office: IOffice): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, office);
  }

  delete(office: IOffice): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, office);
  }

  getAllOfficeOperatorService(): Observable<IOfficeOperatorService[]> {
    return this.httpClient.get<IOfficeOperatorService[]>(`${this._baseUrl}/listOfficeOperatorService`);
  }

  getAllOfficeOperatorServiceByCustomerCode(customerCode: string): Observable<IOfficeOperatorService[]> {
    return this.httpClient.get<IOfficeOperatorService[]>(`${this._baseUrl}/listOfficeOperatorService/withCustomerCode/${customerCode}`);
  }

  getAllOfficeOperatorServiceWithRequest(request: IRequest): Observable<DataSourceResponse<IOfficeOperatorService>> {
    return this.httpClient.post<DataSourceResponse<IOfficeOperatorService>>(`${this._baseUrl}/listOfficeOperatorServiceWithRequest`, request);
  }

  getAllOfficeOperatorWorkSchedule(): Observable<IOfficeOperatorWorkSchedule[]> {
    return this.httpClient.get<IOfficeOperatorWorkSchedule[]>(`${this._baseUrl}/listOfficeOperatorWorkSchedule`);
  }

  getAllOfficeOperatorWorkScheduleWithRequest(request: IRequest): Observable<DataSourceResponse<IOfficeOperatorWorkSchedule>> {
    return this.httpClient.post<DataSourceResponse<IOfficeOperatorWorkSchedule>>(`${this._baseUrl}/listOfficeOperatorWorkScheduleWithRequest`, request);
  }
}
