import { ApplicationRoot } from "../../../../api-authorization/api-authorization.constants";
import { ImageSrcType } from "../constants/src.constants";

export function GetImageSrc(type: string, fileName: string, customerCode?: string) {
  let src: string;

  //for publish on an subdomain
  //src = `/${ApplicationRoot}/assets/img/`; //UNCOMMENT ON PUBLISH

  //for localhost and publish without subdomain
  src = '../../../../../../assets/img/'; //COMMENT ON PUBLISH

  switch (type) {
    case ImageSrcType.Default:
    case ImageSrcType.Countries:
      src = src.concat(`${type}/${fileName}`);
      break;
    case ImageSrcType.Clients:
      src = src.concat(`${type}/${customerCode}/logo/${fileName}`);
      break;
    case ImageSrcType.Operators:
      src = src.concat(`${type}/${customerCode}/photo/${fileName}`);
      break;
  }

  return src;
}
