import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { IAppointment } from '../../interfaces/customer/appointment';
import { IRequest } from '../../interfaces/common/request';
import { DataSourceResponse } from '../../interfaces/data/datasource-response';
import { IResponseCall, IResponseCallWithId } from '../../interfaces/common/response-call.interface';

@Injectable()

export class AppointmentService {
  private _baseUrl: string = `${ApiBaseUrl}appointment`;

  constructor(private httpClient: HttpClient) { }

  getAppointments(): Observable<IAppointment[]> {
    return this.httpClient.get<IAppointment[]>(`${this._baseUrl}`);
  }

  getAppointmentsWithRequest(request: IRequest): Observable<DataSourceResponse<IAppointment>> {
    return this.httpClient.post<DataSourceResponse<IAppointment>>(`${this._baseUrl}`, request);
  }

  getAppointment(id: any): Observable<IAppointment> {
    return this.httpClient.get<IAppointment>(`${this._baseUrl}/${id}`);
  }

  create(appointment: IAppointment): Observable<IResponseCallWithId> {
    return this.httpClient.post<IResponseCallWithId>(`${this._baseUrl}/create`, appointment);
  }

  update(appointment: IAppointment, crud: string): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/update/${crud}`, appointment);
  }

  delete(appointment: IAppointment): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/delete`, appointment);
  }

  cancel(appointment: IAppointment): Observable<IResponseCall> {
    return this.httpClient.post<IResponseCall>(`${this._baseUrl}/cancel`, appointment);
  }

  getMonthsInterval(): Observable<string> {
    return this.httpClient.get<string>(`${this._baseUrl}/getMonthsInterval`);
  }

  setMonthsInterval(monthsInterval: string): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/setMonthsInterval/${monthsInterval}`);
  }

  setEnableMonthsInterval(enableMonthsInterval: number): Observable<boolean> {
    return this.httpClient.get<boolean>(`${this._baseUrl}/setEnableMonthsInterval/${enableMonthsInterval}`);
  }
}
