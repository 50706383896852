import { Component, OnInit, Input } from '@angular/core';
import { IMenuItem } from '../../../core/interfaces/menu-item';

@Component({
  selector: 'app-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent implements OnInit {
  @Input('items') items: IMenuItem[];
  @Input('title') title: string;

  constructor() { }

  ngOnInit() {
  }

}
