import { MatTableDataSource } from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { IExceptionDay } from "../../interfaces/customer/exception-day";

export class ExceptionDayDataSource extends MatTableDataSource<IExceptionDay> {
  constructor(private subject: BehaviorSubject<IExceptionDay[]>) {
    super();
  }

  connect(): BehaviorSubject<IExceptionDay[]> {
    return this.subject;
  }

  disconnect() { }
}
