import { Injectable } from '@angular/core';
import { IOfficeOperatorWorkSchedule } from '../../../../../core/interfaces/customer/office-operator-work-schedule';
import { WeekdayWorkingHoursFormService } from './weekday-working-hours-form.service';

@Injectable()
export class WeekdayWorkingHoursLoaderService {

  constructor(private weekdayWorkingHoursFormService: WeekdayWorkingHoursFormService) { }

  loadWorkingHours(workSchedule: IOfficeOperatorWorkSchedule[]) {
    this.patchWorkingHoursArray(workSchedule);
  }

  patchWorkingHoursArray(workSchedule: IOfficeOperatorWorkSchedule[]) {
    if (workSchedule != null && workSchedule.length > 0) {
      try {
        Object(workSchedule).forEach(x => {
          this.weekdayWorkingHoursFormService.addWorkingHours(x);
        });
      }
      catch (err) {
        console.log('Keywords split error = ', err);
      }
    }
    else {
      this.weekdayWorkingHoursFormService.addWorkingHours();
    }
  }
}
