import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ITimeSlot } from '../../../core/interfaces/common/time-slot';
import { ITimeSlotParameters } from '../../../core/interfaces/common/time-slot-parameters';
import { IAppointment } from '../../../core/interfaces/customer/appointment';
import { IExceptionDay } from '../../../core/interfaces/customer/exception-day';
import { IOfficeOperatorWorkSchedule } from '../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../core/interfaces/customer/operator';
import { ISettings } from '../../../core/interfaces/customer/settings';
import { AgendaService } from '../../../core/services/common/agenda.service';
import { ExceptionDayService } from '../../../core/services/customer/exception-day.service';
import { FilterGridFormService } from '../filter-grid/filter-grid-form.service';

@Component({
  selector: 'app-time-slots',
  templateUrl: './time-slots.component.html',
  styleUrls: ['./time-slots.component.scss'],
  providers: [
    FilterGridFormService,
    ExceptionDayService,
    AgendaService
  ]
})
export class TimeSlotsComponent implements OnInit, OnChanges {
  @Input() settings: ISettings = null;
  @Input() appointment: IAppointment = null;
  @Input() allSelectedOperators: IOperator[] = [];
  @Input() allOfficeOperatorWorkSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() selectedDate: Date = new Date();

  @Output() editedData = new EventEmitter<any>();

  public exceptionDays: IExceptionDay[] = null;
  public timeSlots: ITimeSlot[] = [];

  public selectedOperator: IOperator = null;
  public startHour: string = '';
  public endHour: string = '';

  public componentLoaded: boolean = false;
  public durationExceeded: boolean = false;

  constructor(
    private filterGridFormService: FilterGridFormService,
    private exceptionDayService: ExceptionDayService,
    private agendaService: AgendaService
  ) { }

  ngOnInit() {
    this.loadExceptionDays();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedDate != undefined) {
      //this.selectedDate = new Date(changes.selectedDate.currentValue);
      this.selectedDate = changes.selectedDate.currentValue;
      this.startHour = "";
      this.endHour = "";
      const appointmentDate: Date = new Date(this.appointment.date);

      if (appointmentDate.getFullYear() == this.selectedDate.getFullYear() && appointmentDate.getMonth() == this.selectedDate.getMonth() &&
        appointmentDate.getDate() == this.selectedDate.getDate()) {
        this.startHour = this.appointment.startHour;
        this.endHour = this.appointment.endHour;
      }

      this.createTimeSlots();
    }
  }

  loadExceptionDays() {
    const request = this.filterGridFormService.createRequest(false, this.settings.customerCode);

    this.exceptionDayService.getExceptionDaysWithRequest(request).subscribe({
      next: data => {
        this.exceptionDays = data.data;

        this.createTimeSlots();
      }
    });
  }

  createTimeSlots() {
    if (this.exceptionDays == null) {
      return;
    }

    const date: any = `${this.selectedDate.getFullYear()}-${this.selectedDate.getMonth() + 1}-${this.selectedDate.getDate()}`;

    const timeSlotParameters: ITimeSlotParameters = {
      customerCode: this.settings.customerCode,
      officeOperatorWorkSchedules: this.allOfficeOperatorWorkSchedule,
      exceptionDays: this.exceptionDays,
      settings: this.settings,
      office: this.appointment.office,
      service: this.appointment.service,
      operators: this.allSelectedOperators,
      date: date,
      startHour: this.startHour,
      endHour: this.endHour
    };

    this.agendaService.getTimeSlots(timeSlotParameters).subscribe(items => {
      items.forEach(x => {
        x.startHour = x.startHour.toString().slice(0, 5);
        x.endHour = x.endHour.toString().slice(0, 5);
      });

      this.timeSlots = items;

      this.componentLoaded = true;
    });
  }

  onSelectSlot(slot: ITimeSlot) {
    this.durationExceeded = false;
    const timeSlotParameters: ITimeSlotParameters = {
      settings: this.settings,
      service: this.appointment.service,
      operators: this.allSelectedOperators,
      startHour: slot.startHour,
      timeSlots: this.timeSlots
    };

    this.agendaService.selectTimeSlots(timeSlotParameters).subscribe(item => {
      item.timeSlots.forEach(x => {
        x.startHour = x.startHour.toString().slice(0, 5);
        x.endHour = x.endHour.toString().slice(0, 5);
      });

      this.timeSlots = item.timeSlots;
      this.startHour = item.startHour;
      this.endHour = item.endHour;
      this.selectedOperator = item.operator;

      if (item.timeSlots.filter(x => x.isSelected).length > 0) {
        this.continue();
      }
      else {
        this.durationExceeded = true;
        this.editedData.emit(null);
      }
    });
  }

  continue() {
    var selectedDate: any = `${this.selectedDate.getFullYear()}-${this.selectedDate.getMonth() + 1}-${this.selectedDate.getDate()}`;

    this.appointment.date = selectedDate;
    this.appointment.startHour = this.startHour;
    this.appointment.endHour = this.endHour;
    this.appointment.operator = this.selectedOperator;

    var obj = {
      "appointment": this.appointment
    };

    this.editedData.emit(obj);
  }
}
