import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { select, Store } from '@ngrx/store';
import { CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import { BehaviorSubject, Subject } from 'rxjs';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { ISettings } from '../../../../core/interfaces/customer/settings';
import { CalendarComponent } from '../../dialogs/calendar/calendar.component';
import * as fromI18n from '../../../../../i18n/reducers';
import { GetNextDay } from '../../../../core/functions/date-function';
import { PeriodType } from '../../../../core/constants/period-type.constants';

@Component({
  selector: 'app-time-slots-calendar',
  templateUrl: './time-slots-calendar.component.html',
  styleUrls: ['./time-slots-calendar.component.scss']
})
export class TimeSlotsCalendarComponent implements OnInit {
  @Input() settings: ISettings = null;
  @Input() appointment: IAppointment = null;
  @Input() allSelectedOperators: IOperator[] = [];
  @Input() allOfficeOperatorWorkSchedule: IOfficeOperatorWorkSchedule[] = [];

  @Output() editedData = new EventEmitter<any>();

  public locale: string = 'en';
  public localeSbj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public refresh: Subject<any> = new Subject();

  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  public view: CalendarView = CalendarView.Day;
  CalendarView = CalendarView;

  public selectedDate: Date = new Date();
  public minDate: Date = new Date();

  public appointmentDuration: number = 0;

  public componentLoaded: boolean = false;

  constructor(
    private readonly store: Store<fromI18n.State>,
    private dialog: MatDialog
  ) {
    this.store.pipe(select(fromI18n.getCurrentLanguage)).subscribe(x => {
      this.localeSbj.next(x);
    });

    this.localeSbj.subscribe(x => {
      this.locale = x;
      this.refresh.next();
    });
  }

  ngOnInit() {
    if (this.settings == null || this.appointment == null || this.appointment.service == null) {
      return;
    }

    if (this.settings.minPeriodBeforeBooking > 0 && this.settings.minPeriodBeforeBookingType == PeriodType.Days) {
      var ms = new Date().getTime() + (86400000 * this.settings.minPeriodBeforeBooking);
      let minDate = new Date(ms);

      this.selectedDate = this.getNextAvailableDate(minDate.getDay(), minDate);
    }

    //console.log("selected date=",this.selectedDate, this.appointment.date)

    if (this.appointment.date != null) this.selectedDate = new Date(this.appointment.date);

    this.componentLoaded = true;
  }

  openCalendarDialog() {
    const dialogRef = this.dialog.open(CalendarComponent, {
      minWidth: "30%",
      data: this.selectedDate
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response == null) {
        return;
      }

      this.selectedDate = response;
    });
  }

  onEditedDate($event) {
    this.selectedDate = $event;
  }

  next() {
    this.selectedDate = this.getNextAvailableDate(this.selectedDate.getDay() + 1);
    
    //this.selectedDate = GetNextDay(this.selectedDate);
  }

  previous() {
    this.selectedDate = this.getPreviousAvailableDate(this.selectedDate.getDay() - 1);

    //this.selectedDate = GetNextDay(this.selectedDate, false);
  }

  onEditedData($event) {
    this.editedData.emit($event);
  }

  getNextAvailableDate(weekDay: number, date: Date = null): Date {
    //console.log("getNextAvailableDate", weekDay, date);
    let schedules = this.allOfficeOperatorWorkSchedule.filter(x => x.officeId == this.appointment.office.id);
    schedules = schedules.sort((a, b) => (a.dayOfTheWeek < b.dayOfTheWeek ? -1 : 1));

    let selectedDate = GetNextDay(this.selectedDate);
    let day = selectedDate.getDate();

    if (date != null) {
      day = date.getDate();
      selectedDate = date;
    }

    //console.log("selectedDate", selectedDate);

    if (weekDay == 7) {
      weekDay = 0;
    }

    if (schedules.findIndex(x => x.dayOfTheWeek == weekDay) < 0) {

      weekDay = weekDay + 1;
      let nexDate = GetNextDay(selectedDate);
      //console.log("no date found", weekDay, nexDate);

      return this.getNextAvailableDate(weekDay, nexDate);
    }
    else {
      //console.log("date found", new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day), day);
      return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
    }
  }

  getPreviousAvailableDate(weekDay: number, date: Date = null): Date {
    let schedules = this.allOfficeOperatorWorkSchedule.filter(x => x.officeId == this.appointment.office.id);
    schedules = schedules.sort((a, b) => (a.dayOfTheWeek < b.dayOfTheWeek ? -1 : 1));

    let selectedDate = GetNextDay(this.selectedDate, false);
    let day = selectedDate.getDate();

    if (date != null) {
      day = date.getDate();
      selectedDate = date;
    }

    if (weekDay == -1) {
      weekDay = 6;
    }

    if (schedules.findIndex(x => x.dayOfTheWeek == weekDay) < 0) {
      weekDay = weekDay - 1;
      let nexDate = GetNextDay(selectedDate, false);

      return this.getPreviousAvailableDate(weekDay, nexDate);
    }
    else {
      return new Date(selectedDate.getFullYear(), selectedDate.getMonth(), day);
    }
  }


}
