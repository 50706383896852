import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray } from '@angular/forms';
import { ISelection } from '../../../../../core/interfaces/common/selection';
import { IOfficeOperatorService } from '../../../../../core/interfaces/customer/office-operator-service';
import { IOfficeOperatorWorkSchedule } from '../../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../../core/interfaces/customer/operator';
import { IService } from '../../../../../core/interfaces/customer/service';
import { ServicesWorkingHoursFormService } from './services-working-hours-form.service';
import { ServicesWorkingHoursLoaderService } from './services-working-hours-loader.service';

@Component({
  selector: 'app-services-working-hours',
  templateUrl: './services-working-hours.component.html',
  styleUrls: ['./services-working-hours.component.scss'],
  providers: [
    ServicesWorkingHoursFormService,
    ServicesWorkingHoursLoaderService
  ]
})
export class ServicesWorkingHoursComponent implements OnInit, OnChanges {
  @Input() workSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() officeId: string = null;
  @Input() operator: IOperator = null;
  @Input() operatorServices: IService[] = [];
  @Input() officeOperatorServices: IOfficeOperatorService[] = [];

  @Output() editedOfficeOperatorServices = new EventEmitter<IOfficeOperatorService[]>();
  @Output() editedWorkSchedule = new EventEmitter<IOfficeOperatorWorkSchedule[]>();
  @Output() isFormValid = new EventEmitter<boolean>();

  public items: ISelection[] = [];
  public selectedItems: ISelection[] = [];

  public schedule: IOfficeOperatorWorkSchedule[] = [];
  public editedSchedule: IOfficeOperatorWorkSchedule[] = [];

  public editedOOS: IOfficeOperatorService[] = [];

  public selectedItem: any = {
    serviceId: null,
    serviceName: null,
    isOfficeWorkSchedule: true
  };

  public componentLoaded: boolean = false;

  constructor(
    private servicesWorkingHoursFormService: ServicesWorkingHoursFormService,
    private servicesWorkingHoursLoaderService: ServicesWorkingHoursLoaderService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.servicesWorkingHoursFormService.resetServices();
    this.selectService(null, null, true);

    if (changes.workSchedule != undefined) {
      this.workSchedule = changes.workSchedule.currentValue;
      this.editedSchedule = changes.workSchedule.currentValue;
    }

    if (changes.operator != undefined) {
      this.operator = changes.operator.currentValue;
    }

    if (changes.operatorServices != undefined) {
      this.operatorServices = changes.operatorServices.currentValue;

      this.items = this.operatorServices.map(x => ({ value: x.id, text: x.name }));
    }

    if (changes.officeOperatorServices != undefined) {
      this.officeOperatorServices = changes.officeOperatorServices.currentValue;

      var servicesIds = this.officeOperatorServices.map(function (item) {
        return item['serviceId'];
      });

      var services = this.operatorServices.filter(x => servicesIds.includes(x.id));

      this.selectedItems = services.map(x => ({ value: x.id, text: x.name }));
    }

    this.servicesWorkingHoursLoaderService.loadServices(this.selectedItems, this.officeOperatorServices);

    if (this.servicesForm.value.length > 0) {
      var firstItem = this.servicesForm.at(0).value;
      this.selectService(firstItem.id, firstItem.name, firstItem.isOfficeWorkSchedule);
    }

    this.checkFormStatus();

    this.componentLoaded = true;
  }

  get form() {
    return this.servicesWorkingHoursFormService.form;
  }

  get servicesForm(): FormArray {
    return this.servicesWorkingHoursFormService.form.get('services') as FormArray;
  }

  onChangeWorkScheduleType(checked: boolean): void {
    this.selectedItem.isOfficeWorkSchedule = checked;

    if (checked) {
      this.editedSchedule = this.editedSchedule.filter(x => x.serviceId != this.selectedItem.serviceId);

      this.checkFormStatus();
    }
  }

  selectService(id: string, name: string, isOfficeWorkSchedule: boolean) {
    this.schedule = this.editedSchedule.filter(x => x.serviceId == id);

    this.selectedItem = {
      serviceId: id,
      serviceName: name,
      isOfficeWorkSchedule: isOfficeWorkSchedule
    };
  }

  removeService(index: number, id: string) {
    this.servicesWorkingHoursFormService.removeService(index);

    this.selectedItems = this.selectedItems.filter(item => item.value !== id);

    this.selectService(null, null, true);

    this.checkFormStatus();
  }

  onEditedServices(services: ISelection[]) {
    this.servicesWorkingHoursFormService.resetServices();

    this.servicesWorkingHoursLoaderService.loadServices(services, this.officeOperatorServices);

    this.checkFormStatus();
  }

  onEditedWorkSchedule(workSchedule: IOfficeOperatorWorkSchedule[]) {
    this.editedSchedule = this.editedSchedule.filter(x => x.serviceId != this.selectedItem.serviceId);

    if (workSchedule.length > 0) {
      this.editedSchedule.push(...workSchedule);
    }

    this.checkFormStatus();
  }

  onWorkScheduleFormValid(isValid: boolean) {
    if (!isValid) {
      this.isFormValid.emit(false);
    }
  }

  checkFormStatus() {
    const status = this.form.status;

    if (status == "VALID") {
      const dtos = this.servicesWorkingHoursFormService.createOfficeOperatorServiceDtos(this.officeId, this.operator.id);
      this.editedOfficeOperatorServices.emit(dtos);
      this.editedWorkSchedule.emit(this.editedSchedule);
      this.isFormValid.emit(true);

      return;
    }

    this.isFormValid.emit(false);
  }
}
