import { MatTableDataSource } from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { ICustomerCreditTransaction } from "../../interfaces/customer/customer-credit-transaction.interface";

export class CustomerCreditTransactionDatasource extends MatTableDataSource<ICustomerCreditTransaction> {
  constructor(private subject: BehaviorSubject<ICustomerCreditTransaction[]>) {
    super();
  }

  connect(): BehaviorSubject<ICustomerCreditTransaction[]> {
    return this.subject;
  }

  disconnect() { }
}
