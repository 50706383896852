import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { ICountry } from '../../interfaces/common/country';
import { IPhoneBookingMessage } from '../../interfaces/common/phone-booking-message';
import { IOrderStep } from '../../interfaces/customer/order-step';
import { ISettings } from '../../interfaces/customer/settings';

@Injectable()
export class SettingsService {
  private _baseUrl: string = `${ApiBaseUrl}settings`;
  private _localizationUrl: string = `${ApiBaseUrl}localization`;

  constructor(private httpClient: HttpClient) { }

  getSettings(): Observable<ISettings> {
    return this.httpClient.get<ISettings>(`${this._baseUrl}`);
  }

  getSettingsByCustomerCode(customerCode: string = null): Observable<ISettings> {
    return this.httpClient.get<ISettings>(`${this._baseUrl}/${customerCode}`);
  }

  getSettingsByURLSuffix(urlSuffix: string): Observable<ISettings> {
    return this.httpClient.get<ISettings>(`${this._baseUrl}/byURLSuffix/${urlSuffix}`);
  }

  getOrderSteps(customerCode: string, channel: string, withCascade:boolean=false): Observable<IOrderStep[]> {
    return this.httpClient.get<IOrderStep[]>(`${this._baseUrl}/steps/${customerCode}/${channel}/${withCascade}`);
  }

  getPhoneBookingMessages(customerCode: string): Observable<IPhoneBookingMessage[]> {
    return this.httpClient.get<IPhoneBookingMessage[]>(`${this._baseUrl}/messages/${customerCode}`);
  }

  updateSettings(settings: ISettings): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update`, settings);
  }

  updateOrderSteps(orderStep: IOrderStep[], channel: string): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update-steps/${channel}`, orderStep);
  }

  updatePhoneBookingMessages(messages: IPhoneBookingMessage[]): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this._baseUrl}/update-messages`, messages);
  }

  uploadFile(fd: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this._baseUrl}/upload`, fd);
  }

  getCountries(): Observable<ICountry[]> {
    return this.httpClient.get<ICountry[]>(`${this._localizationUrl}`);
  }
}
