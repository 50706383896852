import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRoles } from '../api-authorization.constants';
import { AuthorizeService } from '../authorize.service';
import { ILoginMenuObject } from '../../app/management/core/interfaces/customer/login-menu-object';

@Component({
  selector: 'app-login-menu',
  templateUrl: './login-menu.component.html',
  styleUrls: ['./login-menu.component.scss']

})
export class LoginMenuComponent implements OnInit {
  public isAuthenticated: Observable<boolean>;
  public userName: Observable<string>;
  public userRole: string;
  public creditInfoLabel: string = "Credit";
  public billingLabel: string="Billing";
  public transactionLabel: string = "Credit transactions";
  public logoutLabel: string = "Logout";
  public isCustomer: boolean;
  public currentCredits: number = null;
  public loginMenuSbj: BehaviorSubject<ILoginMenuObject> = new BehaviorSubject < ILoginMenuObject>(null);
  public loginMenu: ILoginMenuObject;

  @Input() info?: ILoginMenuObject;

  constructor(private authorizeService: AuthorizeService, private router: Router) { }

  ngOnInit() {
    //this.isAuthenticated = this.authorizeService.isAuthenticated();
    //this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));

    this.loginMenuSbj.subscribe(value => {
      if (value != null) {
        this.loginMenu = value;
        if (value.creditInfoLabel != null && value.creditInfoLabel != "") {
          this.creditInfoLabel = value.creditInfoLabel;
        }

        if (value.billingLabel != null && value.billingLabel != "") {
          this.billingLabel = value.billingLabel;
        }

        if (value.transactionsLabel != null && value.transactionsLabel != "") {
          this.transactionLabel = value.transactionsLabel;
        }

        if (value.logoutLabel != null && value.logoutLabel != "") {
          this.logoutLabel = value.logoutLabel;
        }
      }
      else {
        this.loginMenu = {
          currentCredit: null,
          billingLabel:this.billingLabel,
          creditInfoLabel: this.creditInfoLabel,
          transactionsLabel: this.transactionLabel,
          logoutLabel: this.logoutLabel
        };
      }
    })

    if (this.info != null) {
      this.loginMenuSbj.next(this.info);
      
    }

    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
    this.authorizeService.getUserRole().subscribe(y => {
      this.userRole = y.toLowerCase();
      if (y == UserRoles.Customer) {
        this.userRole = 'customer'
        this.isCustomer = true;
      }
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("ngOnChanges", this.info, "changes=", changes.info);
    if (changes.info !== undefined) {
      this.loginMenuSbj.next(changes.info.currentValue);
    }

  }


  openBilling() {
    this.router.navigate([`management/customer/billing/`]);
  }

  openCreditTransactions() {
    this.router.navigate([`management/customer/credit/`]);
  }
}
