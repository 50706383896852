import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ISelection } from '../../../../core/interfaces/common/selection';

@Component({
  selector: 'app-single-selection-country',
  templateUrl: './single-selection-country.component.html',
  styleUrls: ['./single-selection-country.component.scss']
})
export class SingleSelectionCountryComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
  /** list of items */
  @Input() items: ISelection[];

  /** selected item */
  @Input() selectedItem: ISelection;

  /** name of selection component */
  @Input() title: string;

  /** list of items to be returned */
  @Output() editedItem = new EventEmitter<ISelection[]>();

  /** control for the selected item */
  public itemCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public itemFilterCtrl: FormControl = new FormControl();

  /** list of items filtered by search keyword */
  public filteredItems: ReplaySubject<ISelection[]> = new ReplaySubject<ISelection[]>(1);

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();


  constructor() { }

  ngOnInit() {
    this.loadSelection();
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.items != undefined) {
      this.items = changes.items.currentValue;
    }

    if (changes.selectedItem != undefined) {
      this.selectedItem = changes.selectedItem.currentValue;
    }

    this.loadSelection();
  }

  loadSelection() {
    // set initial selection
    this.itemCtrl.setValue(this.selectedItem);

    // load the initial item list
    this.filteredItems.next(this.items.slice());

    // listen for search field value changes
    this.itemFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterItems();
      });
  }

  /**
   * Sets the initial value after the filteredItems are loaded initially
   */
  protected setInitialValue() {
    this.filteredItems
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredItems are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: ISelection, b: ISelection) => a && b && a.value === b.value;
      });
  }

  protected filterItems() {
    if (!this.items) {
      return;
    }
    // get the search keyword
    let search = this.itemFilterCtrl.value;
    if (!search) {
      this.filteredItems.next(this.items.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the items
    this.filteredItems.next(
      this.items.filter(item => item.text.toLowerCase().indexOf(search) > -1)
    );
  }

  onChange($event) {
    this.editedItem.emit($event.value);
  }
}
