import { MatTableDataSource } from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { IAppointmentNotification } from "../../interfaces/customer/appointment-notification.interface";

export class AppointmentNotificationDatasource extends MatTableDataSource<IAppointmentNotification> {
  constructor(private subject: BehaviorSubject<IAppointmentNotification[]>) {
    super();
  }

  connect(): BehaviorSubject<IAppointmentNotification[]> {
    return this.subject;
  }

  disconnect() { }
}

