import { WeekDay } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { WeekDaysList } from '../../../../core/constants/calendar.constants';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { WorkingHoursFormService } from './working-hours-form.service';
import { WorkingHoursLoaderService } from './working-hours-loader.service';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
  providers: [
    WorkingHoursFormService,
    WorkingHoursLoaderService
  ]
})
export class WorkingHoursComponent implements OnInit, OnChanges {
  @Input() workSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() officeId: string = null;
  @Input() operatorId: string = null;
  @Input() serviceId: string = null;

  @Output() editedWorkSchedule = new EventEmitter<IOfficeOperatorWorkSchedule[]>();
  @Output() isFormValid = new EventEmitter<boolean>();

  public WeekDay = WeekDay;
  public editedWeekDay: WeekDay;

  public schedule: IOfficeOperatorWorkSchedule[] = [];

  public componentLoaded: boolean = false;

  constructor(
    private workingHoursFormService: WorkingHoursFormService,
    private workingHoursLoaderService: WorkingHoursLoaderService
  ) { }

  ngOnInit() {
    if (this.workSchedule.length == 0) {
      this.workingHoursLoaderService.loadFirstWorkingHours();

      this.componentLoaded = true;

      return;
    }

    this.workingHoursLoaderService.loadWorkingHours(this.workSchedule);

    this.schedule = this.workSchedule;

    this.componentLoaded = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.workSchedule != undefined) {
      this.workSchedule = changes.workSchedule.currentValue;
    }

    if (changes.officeId != undefined) {
      this.officeId = changes.officeId.currentValue;
    }

    if (changes.operatorId != undefined) {
      this.operatorId = changes.operatorId.currentValue;
    }

    if (changes.serviceId != undefined) {
      this.serviceId = changes.serviceId.currentValue;
    }

    this.workingHoursLoaderService.loadWorkingHours(this.workSchedule);

    this.schedule = this.workSchedule;

    this.componentLoaded = true;
  }

  get form() {
    return this.workingHoursFormService.form;
  }

  get monday() {
    return this.workingHoursFormService.getFormControl('monday') as FormControl;
  }

  get tuesday() {
    return this.workingHoursFormService.getFormControl('tuesday') as FormControl;
  }

  get wednesday() {
    return this.workingHoursFormService.getFormControl('wednesday') as FormControl;
  }

  get thursday() {
    return this.workingHoursFormService.getFormControl('thursday') as FormControl;
  }

  get friday() {
    return this.workingHoursFormService.getFormControl('friday') as FormControl;
  }

  get saturday() {
    return this.workingHoursFormService.getFormControl('saturday') as FormControl;
  }

  get sunday() {
    return this.workingHoursFormService.getFormControl('sunday') as FormControl;
  }

  onChangeWeekDay(checked: boolean, weekday: WeekDay) {
    if (!checked) {
      this.schedule = this.schedule.filter(x => x.dayOfTheWeek != weekday);

      this.checkFormStatus();
    }
  }

  onEditedWeekDay(weekday: WeekDay) {
    this.editedWeekDay = weekday;
  }

  onEditedWorkSchedule(workSchedule: IOfficeOperatorWorkSchedule[]) {
    this.schedule = this.schedule.filter(x => x.dayOfTheWeek != this.editedWeekDay);

    if (workSchedule.length == 0) {
      var weekdayName = WeekDaysList[this.editedWeekDay];
      this.form.controls[weekdayName].setValue(false);

      this.checkFormStatus();

      return;
    }

    this.schedule.push(...workSchedule);

    this.checkFormStatus();
  }

  onWorkScheduleFormValid(isValid: boolean) {
    if (!isValid) {
      this.isFormValid.emit(false);
    }
  }

  checkFormStatus() {
    const status = this.form.status;

    if (status == "VALID") {
      this.editedWorkSchedule.emit(this.schedule);
      this.isFormValid.emit(true);

      return;
    }

    this.isFormValid.emit(false);
  }
}
