import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-html-info',
  templateUrl: './html-info.component.html',
  styleUrls: ['./html-info.component.scss']
})
export class HtmlInfoComponent implements OnInit {

  public title: string = null;
  public content: string = null;

  constructor(private dialogRef: MatDialogRef<HtmlInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null
  ) {
    if (data == null) {
      return;
    }

    this.title = data.title;
    this.content = data.content;
  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

}
