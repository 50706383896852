import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthorizeService } from '../../../../api-authorization/authorize.service';
import { Title } from '@angular/platform-browser';
import * as fromI18n from '../../../i18n/reducers';
import { I18nComponent } from '../../../i18n/containers/i18n.component';
import { MatDialog } from '@angular/material';
import { AppointmentComponent } from '../../../management/shared/components/dialogs/appointment/appointment.component';
import { UserRoles } from '../../../../api-authorization/api-authorization.constants';
import { CreditService } from '../../../management/core/services/customer/credit.service';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ILoginMenuObject } from '../../../management/core/interfaces/customer/login-menu-object';
import { th } from 'date-fns/esm/locale';
import { Router } from '@angular/router';

@Component({
  selector: 'app-management-header',
  templateUrl: './management-header.component.html',
  styleUrls: ['./management-header.component.scss'],
  providers: [CreditService]
})
export class ManagementHeaderComponent extends I18nComponent {
  public userRole: string;
  public isCustomer: boolean;
  public isExpanded: boolean = false;
  public currentCredits: number;
  public loginObj: ILoginMenuObject;
  public loginObjSbj: BehaviorSubject<ILoginMenuObject> = new BehaviorSubject<ILoginMenuObject>(null);

  constructor(readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private authorizeService: AuthorizeService,
    private router:Router,
    public titleService: Title, private creditService:CreditService,
    private dialog: MatDialog
  ) {
    super(store, translate);
  }

  ngOnInit() {
    super.ngOnInit();

    this.loginObjSbj.subscribe(value => {
      //console.log("loginObjSbj value=", value);
      if (value != null) {
        this.loginObj = {
          currentCredit: value.currentCredit,
          billingLabel: value.billingLabel,
          creditInfoLabel: value.creditInfoLabel,
          transactionsLabel: value.transactionsLabel,
          logoutLabel: value.logoutLabel
        };
      }
    })

    this.authorizeService.getUserRole().subscribe({
      next: y => {
        this.userRole = y.toLowerCase();
        if (y == UserRoles.Customer || y == UserRoles.Operator) {
          this.userRole = y.toLowerCase();
          this.isCustomer = true;
        }
      }
    });
    this.getLoginMenu();
  }

  openAppointmentDialog() {
    const dialogRef= this.dialog.open(AppointmentComponent, {
      width: "80rem",
      minWidth: "30rem",
      data: null
    });

    dialogRef.afterClosed().subscribe(x => {
      var url = this.router.url;
      if (x == true && `/management/${this.userRole.toLowerCase()}/appointments`) {
        window.location.reload();
      }
    })
  }

  toggle() {

  }

  getLoginMenu() {
    this.translate.get("menu.credit-info").subscribe(x => {
      if (this.loginObj != null) {
        this.loginObj.creditInfoLabel = x;
      }
      else {
        this.loginObj = {
          currentCredit: 0,
          billingLabel: "",
          creditInfoLabel: x,
          transactionsLabel: "",
          logoutLabel: ""
        };
        this.loginObjSbj.next(this.loginObj);
      }
     
    });
    this.translate.get("menu.billingLabel").subscribe(x => {
      if (this.loginObj != null) {
        this.loginObj.billingLabel = x;
      }
      else {
        this.loginObj = {
          currentCredit: 0,
          billingLabel: x,
          creditInfoLabel: "",
          transactionsLabel: "",
          logoutLabel: ""
        };
        this.loginObjSbj.next(this.loginObj);
      }
    });
    this.translate.get("menu.transactionLabel").subscribe(x => {
      if (this.loginObj != null) {
        this.loginObj.transactionsLabel = x;
      }
      else {
        this.loginObj = {
          currentCredit: 0,
          billingLabel: "",
          creditInfoLabel: "",
          transactionsLabel: x,
          logoutLabel: ""
        };
        this.loginObjSbj.next(this.loginObj);
      }
    });

    this.creditService.getCurrentCredit().subscribe(x => {
      if (x == null) {
        return;
      }
      this.currentCredits = x.currentCredits;
      if (this.loginObj != null) {
        this.loginObj.currentCredit = x.currentCredits;
        this.loginObjSbj.next(this.loginObj);
      }
      else {
        this.loginObj = {
          currentCredit: this.currentCredits,
          billingLabel: "",
          creditInfoLabel: "",
          transactionsLabel: "",
          logoutLabel: ""
        };
        this.loginObjSbj.next(this.loginObj);
      }
    });

    this.translate.get("menu.logout").subscribe(x => {
      if (this.loginObj != null) {
        this.loginObj.logoutLabel = x;
      }
      else {
        this.loginObj = {
          currentCredit: 0,
          billingLabel: "",
          creditInfoLabel: "",
          transactionsLabel: "",
          logoutLabel: x
        };
        this.loginObjSbj.next(this.loginObj);
      }
    });
  }
}
