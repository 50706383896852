import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-color-picker-sketch',
  templateUrl: './color-picker-sketch.component.html',
  styleUrls: ['./color-picker-sketch.component.scss']
})
export class ColorPickerSketchComponent implements OnInit {
  @Input() color: string;
  @Input() title: string = '';

  @Output() editedColor = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
    if (this.color == null) {
      this.color = "#FFFFFF";
    }
  }

  onChange($event: any) {
    this.editedColor.emit($event.color.hex);
  }
}
