import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { I18nComponent } from '../../../../../i18n/containers/i18n.component';
import * as fromI18n from '../../../../../i18n/reducers';
import { ISettings } from '../../../../core/interfaces/customer/settings';
import { SettingsService } from '../../../../core/services/customer/settings.service';
import { UserService } from '../../../../core/services/users/user.service';
import { SettingsFormService } from './settings-form.service';
import { SettingsLoaderService } from './settings-loader.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [
    SettingsService,
    UserService,
    SettingsLoaderService,
    SettingsFormService
  ]
})
export class SettingsComponent extends I18nComponent {
  public customerCode: string = null;
  public settings: ISettings = null;

  public notFound: boolean = false;
  public loading: boolean = false;
  public success: boolean = false;

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private settingsFormService: SettingsFormService,
    private settingsLoaderService: SettingsLoaderService,
    private settingsService: SettingsService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private titleService: Title,
  ) {
    super(store, translate);
  }

  ngOnInit() {
    super.ngOnInit();

    this.translate.get("edit.title").subscribe(x => this.titleService.setTitle(x));

    if (this.activatedRoute.snapshot.paramMap.get("customerCode") === null) {
      this.notFound = true;

      return;
    }

    this.customerCode = this.activatedRoute.snapshot.paramMap.get("customerCode");

    this.settingsService.getSettingsByCustomerCode(this.customerCode).subscribe(x => {
      if (x === null || x.customerCode === null) {
        this.userService.getUserByCustomerCode(this.customerCode).subscribe(y => {
          if (y === null || y.id === null) {
            this.notFound = true;
          }
        });

        return;
      }

      this.settings = x;
      this.settingsLoaderService.loadSettings(x);
    });
  }

  get form() {
    return this.settingsFormService.form;
  }

  get maxOperators() {
    return this.settingsFormService.getFormControl('maxOperators');
  }

  get maxOffices() {
    return this.settingsFormService.getFormControl('maxOffices');
  }

  submit() {
    this.loading = true;

    const settings = this.settingsFormService.createSettingsDto(this.settings, this.customerCode);

    this.settingsService.updateSettings(settings).subscribe(x => {
      this.loading = false;
      this.success = x;
    }, error => {
      console.log('error = ', error);
    });
  }
}
