import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IFilterGridData } from '../../../core/interfaces/common/filter-grid-data';
import { IRequest } from '../../../core/interfaces/common/request';
import { ISelection } from '../../../core/interfaces/common/selection';
import { FilterOperatorType } from '../../../core/constants/filter-operator-type';

@Injectable()
export class FilterGridFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      operatorName: [null, Validators.required],
      operatorValue: [null, Validators.required]
    });
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createRequest(
    withPagination: boolean = false,
    customerCode: string = null,
    filters: IFilterGridData[] = [],
    parentCustomerCode: string = null,
    withAvailable: boolean = false,
    page: number = 0,
    pageSize: number = 0,
    sortDirection: string = null,
    sortField: string = null,
    dates: ISelection[] = null,
    withCancelStatus: boolean = false
  ): IRequest {
    const request: IRequest = {
      withAvailable: withAvailable,
      withPagination: withPagination,
      customerCode: customerCode,
      filters: filters,
      parentCustomerCode: parentCustomerCode,
      page: page,
      pageSize: pageSize,
      sortDirection: sortDirection,
      sortField: sortField,
      dates: dates,
      withCancelStatus: withCancelStatus
    }
    return request;
  }

  createFilter(propertyType: string, propertyName: string, operatorName: string, value: string, isExpression?: boolean): IFilterGridData {
    const filter: IFilterGridData = {
      propertyType: propertyType,
      propertyName: propertyName,
      operatorName: operatorName,
      value: value,
      isExpression: isExpression !== null ? isExpression : false
    }

    return filter;
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  filter(filter: IFilterGridData, array: any[]): any[] {
    var data = [];

    switch (filter.operatorName) {

      case "Equals": {
        for (var i = 0; i < array.length; i++) {
          var propertyValue = array[i][filter.propertyName];
          if (filter.propertyType == FilterOperatorType.String) {
            propertyValue = propertyValue.toLowerCase();
          }

          //if array compare with length
          if (filter.propertyType == "Array") {
            propertyValue = propertyValue.length;
          }

          if (propertyValue == this.convertValue(filter)) {
            data.push(array[i]);
          }
        };
        return data;
      }
        
      case "Greater Than":{
          for (var i = 0; i < array.length; i++) {
            var propertyValue = array[i][filter.propertyName];

            //if array compare with length
            if (filter.propertyType == "Array") {
              propertyValue = propertyValue.length;
            }

            if (propertyValue > this.convertValue(filter)) {
              data.push(array[i]);
            }
          };
          return data;
        }
       
      case "Greater Than Or Equals":{
          for (var i = 0; i < array.length; i++) {
            var propertyValue = array[i][filter.propertyName];

            //if array compare with length
            if (filter.propertyType == "Array") {
              propertyValue = propertyValue.length;
            }

            if (propertyValue >= this.convertValue(filter)) {
              data.push(array[i]);
            }
          };
          return data;
      }

      case "Less Than":{
          for (var i = 0; i < array.length; i++) {
            var propertyValue = array[i][filter.propertyName];

            //if array compare with length
            if (filter.propertyType == "Array") {
              propertyValue = propertyValue.length;
            }

            if (propertyValue < this.convertValue(filter)) {
              data.push(array[i]);
            }
          };
          return data;
        }
      case "Less Than Or Equals": {
        for (var i = 0; i < array.length; i++) {
          var propertyValue = array[i][filter.propertyName];

          //if array compare with length
          if (filter.propertyType == "Array") {
            propertyValue = propertyValue.length;
          }

          if (propertyValue <= this.convertValue(filter)) {
            data.push(array[i]);
          }
        };
        return data;
      }
        
      case "Not Equal": {
        for (var i = 0; i < array.length; i++) {
          var propertyValue = array[i][filter.propertyName];
          if (filter.propertyType == FilterOperatorType.String) {
            propertyValue = propertyValue.toLowerCase();
          }

          //if array compare with length
          if (filter.propertyType == "Array") {
            propertyValue = propertyValue.length;
          }

          if (propertyValue != this.convertValue(filter)) {
            data.push(array[i]);
          }
        };
        return data;
      }
       
      case "Starts With": {
        for (var i = 0; i < array.length; i++) {
          if (array[i][filter.propertyName].toLowerCase().startsWith(this.convertValue(filter))) {
            data.push(array[i]);
          }
        };
        return data;
      }
       
      case "Contains": {
        for (var i = 0; i < array.length; i++) {
          if (array[i][filter.propertyName].toLowerCase().includes(this.convertValue(filter))) {
            data.push(array[i]);
          }
        };
        return data;
      }
        
      case "End With": {
        for (var i = 0; i < array.length; i++) {
          if (array[i][filter.propertyName].toLowerCase().endsWith(this.convertValue(filter))) {
            data.push(array[i]);
          }
        };
        return data;
      }
        
      default: {
        return data = array;
      }
       
    }
  }

  private convertValue(filter: IFilterGridData) {
    switch (filter.propertyType) {
      case FilterOperatorType.Number:
      case "Array":
        return +filter.value;
      case FilterOperatorType.Boolean:
        return filter.value.toLowerCase() == "true";
      case FilterOperatorType.Timestamp:
        var selectedDate = new Date(filter.value);
        var ticks = ((selectedDate.getTime() * 10000) + 621355968000000000);

      default:
        return filter.value.toLowerCase();
    }
  }
}
