import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ISelection } from '../../../../../core/interfaces/common/selection';
import { IOfficeOperatorService } from '../../../../../core/interfaces/customer/office-operator-service';
import { IService } from '../../../../../core/interfaces/customer/service';

@Injectable()
export class ServicesWorkingHoursFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      services: this.fb.array([])
    });
  }

  get servicesForm() {
    return this.form.get('services') as FormArray;
  }

  addService(id: string, name: string, oos: IOfficeOperatorService) {
    const obj = this.fb.group({
      id: id,
      name: name,
      isOfficeWorkSchedule: oos == null ? true : oos.isOfficeWorkSchedule,
      oosId: oos == null ? null : oos.id
    });

    this.servicesForm.push(obj);
    this.form.markAsDirty();
  }

  removeService(index: number) {
    this.servicesForm.removeAt(index);
    this.form.markAsDirty();
  }

  resetServices() {
    while (this.servicesForm.length !== 0) {
      this.removeService(0)
    }
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createOfficeOperatorServiceDtos(officeId: string, operatorId: string): IOfficeOperatorService[] {
    var data = this.form.value;
    var services = data.services;

    const officeOperatorServices: IOfficeOperatorService[] = [];

    Object(services).forEach(service => {
      const oos: IOfficeOperatorService = {
        id: service.oosId,
        officeId: officeId,
        operatorId: operatorId,
        serviceId: service.id,
        isOfficeWorkSchedule: service.isOfficeWorkSchedule
      };

      officeOperatorServices.push(oos);
    });

    return officeOperatorServices;
  }
}
