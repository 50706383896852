import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ClientService } from '../../../core/services/customer/client.service';
import { ClientFormService } from './client-form.service';
import { ClientLoaderService } from './client-loader.service';
import { AuthorizeService } from '../../../../../api-authorization/authorize.service';
import { MatSnackBar } from '@angular/material';
import { IClient } from '../../../core/interfaces/customer/client';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss'],
  providers: [
    ClientService,
    ClientFormService,
    ClientLoaderService
  ]
})
export class ClientComponent implements OnInit {
  @Input() id: any = null;
  @Input() isDialog: boolean = false;

  @Output() closeDialog = new EventEmitter<string>();

  public notFound: boolean = false;
  public loading: boolean = false;
  public success: boolean = false;
  public userRole: string = "customer";
  private client: IClient;

  constructor(
    readonly translate: TranslateService,
    private clientFormService: ClientFormService,
    private clientLoaderService: ClientLoaderService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private matSnackBack:MatSnackBar,
    private authorizeService: AuthorizeService,
    private titleService: Title
  ) { }

  ngOnInit() {
    this.authorizeService.getUserRole().subscribe({ next: data => this.userRole = data.toLowerCase() });

    if (this.id == null) {
      if (!this.isDialog) {
        this.translate.get("clients.edit.titleCreate").subscribe(x => this.titleService.setTitle(x));
      }

      return;
    }

    if (!this.isDialog) {
      this.translate.get("clients.edit.titleUpdate").subscribe(x => this.titleService.setTitle(x));
    }

    this.clientService.getClient(this.id).subscribe(x => {
      if (x == null || x.id == null) {
        this.notFound = true;

        return;
      }
      this.client = x;
      this.clientLoaderService.loadClient(x);
    });

  }

  get form() {
    return this.clientFormService.form;
  }

  get firstName() {
    return this.clientFormService.getFormControl('firstName') as FormControl;
  }

  get lastName() {
    return this.clientFormService.getFormControl('lastName') as FormControl;
  }

  get phone() {
    return this.clientFormService.getFormControl('phone') as FormControl;
  }

  get email() {
    return this.clientFormService.getFormControl('email') as FormControl;
  }

  get description() {
    return this.clientFormService.getFormControl('description') as FormControl;
  }

  submit() {
    this.loading = true;

    let client = this.clientFormService.createClientDto(this.id, this.client);

    if (this.id == null) {
      //create
      this.clientService.createWithVerification(client).subscribe(x => {
        this.loading = false;
        this.success = x != null && x.errorCode==null ? true : false;

        if ( x!=null && x.errorCode!=null) {
          this.displayErrorMessage(x.errorCode);
        }

        if (this.isDialog && this.success) {
          this.closeDialog.emit(x.entity);
        }

      }, error => {
        console.log('error = ', error);
      });
    }
    else {
      //update
      this.clientService.updateWithVerification(client).subscribe(x => {
        this.loading = false;

        if (x == null) {
          return;
        }

        this.success = x.entity;

        if (x != null && x.errorCode != null) {
          this.displayErrorMessage(x.errorCode);
        }

      }, error => {
        console.log('error = ', error);
      });
    }
  }

  closeClientDialog() {
    this.closeDialog.emit('');
  }

  goBack() {
    //console.log("userRole=", this.userRole);
    this.router.navigate([`/management/${this.userRole}/clients`])
  }

  displayErrorMessage(errorCode: string) {
    this.translate.get(`clients.edit.${errorCode}`).subscribe(x => {
      if (x != null) {
        this.openSnackBar(x);
      }
      else {
        this.openSnackBar("An error occurred. Please refresh and try again.");
      }
    });
  }

  openSnackBar(message) {
    this.matSnackBack.open(message, "Ok", {
      duration: 5000
    });
  }

}
