import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrl } from '../../constants/shared.constants';
import { ITimeSlot } from '../../interfaces/common/time-slot';
import { ITimeSlotParameters } from '../../interfaces/common/time-slot-parameters';

@Injectable()
export class AgendaService {
  private _baseUrl: string = `${ApiBaseUrl}agenda`;

  constructor(private httpClient: HttpClient) { }

  getTimeSlots(timeSlotParameters: ITimeSlotParameters): Observable<ITimeSlot[]> {
    return this.httpClient.post<ITimeSlot[]>(`${this._baseUrl}`, timeSlotParameters);
  }

  selectTimeSlots(timeSlotParameters: ITimeSlotParameters): Observable<ITimeSlotParameters> {
    return this.httpClient.post<ITimeSlotParameters>(`${this._baseUrl}/select`, timeSlotParameters);
  }
}
