import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';

@Component({
  selector: 'app-dialog-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  public id: any = null;
  public appointment: IAppointment = null;

  constructor(public dialogRef: MatDialogRef<AppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    this.appointment = data;

    if (data != null) {
      this.id = data.id;
    }
  }

  ngOnInit() {
  }

  closeDialog(event) {
    this.dialogRef.close(event);
  }
}
