import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  public message: string;

  public defaultButtons: boolean = false;
  public isHtml: boolean = false;
  public componentLoaded: boolean = false;

  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null) {

    if (data == null) {
      return;
    }

    if (data.hasOwnProperty("message")) {
      this.message = data.message;
    }
    else {
      this.message = data;
    }

    if (data.hasOwnProperty("defaultButtons")) {
      this.defaultButtons = data.defaultButtons;
    }

    if (this.message.includes('</span>')) {
      this.isHtml = true;
    }

    this.componentLoaded = true;
  }

  ngOnInit() {
  }

  continue() {
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
