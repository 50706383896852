import { Injectable } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormControl } from "@angular/forms";
import { IUser } from "../../../../../core/interfaces/users/user";
import { MustMatch } from "../../../../../core/functions/validator-function";
import * as uuid from 'uuid';

@Injectable()
export class UserFormService {
  public form: FormGroup;

  public defaultValidity = new Date(2099, 11, 31);

  constructor(private fb: FormBuilder) {

    this.form = this.fb.group({
      customerCode: uuid.v4(),
      email: [null, [
        Validators.required,
        Validators.pattern("^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$")
      ]],
      phone: [null, [
        Validators.required,
        Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,2})?$')
      ]],
      isActivated: true,
      isLicenseAccepted: false,
      accountExpiresOnUtc: [this.defaultValidity, Validators.required],
      webApiKey: this.generateHexString(64),
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      dilloDid: [null],
      password: [null, [
        Validators.required,
        Validators.pattern("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\\s).{6,}$")
      ]],
      retypePassword: ["", Validators.required],
      customerCodeDillo: [null, Validators.required],
      webApiKeyDillo: [null, Validators.required]
    },
      {
        validator: MustMatch('password', 'retypePassword')
      }
    );
  }

  generateHexString(length) {
    let result = '';
    const characters = 'ABCDEF0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  clearValidators(controlNames: string[]) {
    controlNames.map(x => {
      const control = this.form.get(x) as FormControl;
      control.setValidators(null);
      if (x === "retypePassword") {
        this.form.validator = null;
      }
    })
  }

  createUserDto(id): IUser {
    const data = this.form.value;

    const user: IUser = {
      id: id,
      customerCode: data.customerCode,
      email: data.email,
      phone: data.phone,
      isActivated: data.isActivated,
      isLicenseAccepted: data.isLicenseAccepted,
      accountExpiresOnUtc: data.accountExpiresOnUtc,
      webApiKey: data.webApiKey,
      userName: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      customerCodeDillo: data.customerCodeDillo,
      webApiKeyDillo: data.webApiKeyDillo,
      inboundDilloNumber: data.dilloDid
    };

    return user;
  }
}
