import { MatTableDataSource } from "@angular/material";
import { BehaviorSubject } from "rxjs";
import { IClient } from "../../interfaces/customer/client";

export class ClientDataSource extends MatTableDataSource<IClient> {
  constructor(private subject: BehaviorSubject<IClient[]>) {
    super();
  }

  connect(): BehaviorSubject<IClient[]> {
    return this.subject;
  }

  disconnect() { }
}
