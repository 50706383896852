import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-info',
  templateUrl: './list-info.component.html',
  styleUrls: ['./list-info.component.scss']
})
export class ListInfoComponent implements OnInit {
  public items: string[] = [];
  public title: string = '';
  public titleName: string = '';
  public routerList: string = '';

  constructor(
    private dialogRef: MatDialogRef<ListInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any | null,
    private router: Router,
  ) {
    if (data == null) {
      return;
    }

    this.items = data.items;
    this.title = data.title;
    this.titleName = data.titleName;
    this.routerList = data.routerList;
  }

  ngOnInit() {
  }

  onChangeRoute(id: string) {
    this.dialogRef.close();

    this.router.navigate([`management/customer/${this.routerList}/edit/${id}`]);
  }
}
