export const WeekDaysList: { [key: number]: string } = {
  [0]: 'sunday',
  [1]: 'monday',
  [2]: 'tuesday',
  [3]: 'wednesday',
  [4]: 'thursday',
  [5]: 'friday',
  [6]: 'saturday'
}

export const MonthNames: string[] = ["january", "february", "march", "april", "may", "june",
  "july", "august", "september", "october", "november", "december"
]
