import { Component } from '@angular/core';
import { UserFormService } from './user-form.service';
import { UserLoaderService } from './user-loader.service';
import { I18nComponent } from '../../../../../../i18n/containers/i18n.component';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../../../../core/services/users/user.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import * as fromI18n from '../../../../../../i18n/reducers';
import { FormControl } from '@angular/forms';
import { CustomerErrorCodes } from '../../../../../core/constants/error-codes.constants';
import { BehaviorSubject } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [
    UserFormService,
    UserLoaderService
  ]
})
export class EditComponent extends I18nComponent {
  public id: any = null;
  public notFound: boolean = false;
  public loading: boolean = false;
  public success: boolean = false;
  public hide: boolean = true;

  public validators: string[] = ['password', 'retypePassword'];

  constructor(
    readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService,
    private userFormService: UserFormService,
    private userLoaderService: UserLoaderService,
    private userService: UserService,
    private matSnackBack:MatSnackBar,
    private route: ActivatedRoute,
    private titleService: Title
  ) {
    super(store, translate);
  }

  ngOnInit() {
    super.ngOnInit();

    if (this.route.snapshot.paramMap.get("id") === null) {
      this.translate.get("edit.titleCreate").subscribe(x => this.titleService.setTitle(x));

      return;
    }

    this.userFormService.clearValidators(this.validators);

    this.translate.get("edit.titleUpdate").subscribe(x => this.titleService.setTitle(x));

    this.id = this.route.snapshot.paramMap.get("id");

    this.userService.getUser(this.id).subscribe(x => {
      if (x === null || x.id === null) {
        this.notFound = true;

        return;
      }

      this.userLoaderService.loadUser(x);
    });

  }

  get form() {
    return this.userFormService.form;
  }

  get customerCode() {
    return this.userFormService.getFormControl('customerCode') as FormControl;
  }

  get email() {
    return this.userFormService.getFormControl('email') as FormControl;
  }

  get phone() {
    return this.userFormService.getFormControl('phone') as FormControl;
  }

  get isActivated() {
    return this.userFormService.getFormControl('isActivated') as FormControl;
  }

  get isLicenseAccepted() {
    return this.userFormService.getFormControl('isLicenseAccepted') as FormControl;
  }

  get accountExpiresOnUtc() {
    return this.userFormService.getFormControl('accountExpiresOnUtc') as FormControl;
  }

  get webApiKey() {
    return this.userFormService.getFormControl('webApiKey') as FormControl;
  }

  get userName() {
    return this.userFormService.getFormControl('userName') as FormControl;
  }

  get firstName() {
    return this.userFormService.getFormControl('firstName') as FormControl;
  }

  get lastName() {
    return this.userFormService.getFormControl('lastName') as FormControl;
  }

  get password() {
    return this.userFormService.getFormControl('password') as FormControl;
  }

  get retypePassword() {
    return this.userFormService.getFormControl('retypePassword') as FormControl;
  }

  get customerCodeDillo() {
    return this.userFormService.getFormControl('customerCodeDillo') as FormControl;
  }

  get webApiKeyDillo() {
    return this.userFormService.getFormControl('webApiKeyDillo') as FormControl;
  }

  get dilloDid() {
    return this.userFormService.getFormControl("dilloDid");
  }

  submit() {
    this.loading = true;

    const user = this.userFormService.createUserDto(this.id);

    if (this.id === null) {
      //create
      this.userService.create(user).subscribe(x => {
        this.loading = false;
        this.success = x.entity;

        if (x.entity == false && x.errorCode != null) {
          this.displayErrorMessage(x.errorCode);
        }
      }, error => {
        console.log('error = ', error);
      });
    }
    else {
      //update
      this.userService.update(user).subscribe(x => {
        this.loading = false;
        this.success = x.entity;

        if (x.entity == false && x.errorCode != null) {
          this.displayErrorMessage(x.errorCode);
        }

      }, error => {
        console.log('error = ', error);
      });
    }
  }

  displayErrorMessage(errorCode: string) {
    this.translate.get(`edit.${errorCode}`).subscribe(x => {
      if (x != null) {
        this.openSnackBar(x);
      }
      else {
        this.openSnackBar("An error occurred. Please refresh and try again.");
      }
    });
  }

  openSnackBar(message) {
    this.matSnackBack.open(message, "Ok", {
      duration: 5000
    });
  }
}
