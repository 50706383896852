import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAppointment } from '../../../core/interfaces/customer/appointment';
import { ISettings } from '../../../core/interfaces/customer/settings';

@Injectable()
export class AppointmentFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      date: [null],
      startHour: [null, Validators.required],
      endHour: [null, Validators.required],
      notes: null,
      createdOnUtc: null,
      mainAppointmentId: null,
      clientAppointmentId: null,
      clientStartHour: null,
      clientEndHour: null,
      office: [null, Validators.required],
      service: [null, Validators.required],
      serviceDuration: [null, Validators.required],
      operator: [null, Validators.required],
      status: [null, Validators.required],
      client: [null, Validators.required],
      eventId: null,
      uniqueCode: null
    });
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createAppointmentDto(id, editedAppoitment: IAppointment = null, settings: ISettings = null): IAppointment {
    const data = this.form.value;

    if (data.createdOnUtc==null) {
      data.createdOnUtc = new Date();
    }

    const appointment: IAppointment = {
      id: id,
      customerCode: null,
      date: data.date,
      startHour: data.startHour,
      endHour: data.endHour,
      notes: data.notes,
      createdOnUtc: id !== null ? data.createdOnUtc : new Date(),
      mainAppointmentId: data.mainAppointmentId,
      clientAppointmentId: data.clientAppointmentId,
      clientStartHour: data.clientStartHour,
      clientEndHour: data.clientEndHour,
      office: data.office,
      service: data.service,
      operator: data.operator,
      status: data.status,
      client: data.client,
      notificationSettings: editedAppoitment != null ? editedAppoitment.notificationSettings : null,
      settings: settings,
      eventId: data.eventId,
      uniqueCode: data.uniqueCode
    };

    return appointment;
  }
}
