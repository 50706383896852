import { Component, OnInit } from '@angular/core';
import { I18nComponent } from '../../../i18n/containers/i18n.component';
import { TranslateService } from '@ngx-translate/core';
import * as fromI18n from '../../../i18n/reducers';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-management-layout',
  templateUrl: './management-layout.component.html',
  styleUrls: ['./management-layout.component.scss']
})
export class ManagementLayoutComponent extends I18nComponent {

  constructor(readonly store: Store<fromI18n.State>,
    readonly translate: TranslateService) {
    super(store, translate);

  }
}
