import { Time } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit, OnChanges {
  /** selected time */
  @Input() selectedTime: string;

  /** the minimum time that can be selected */
  @Input() min: string = '00:00';

  /** the maximum time that can be selected */
  @Input() max: string = '23:59';

  @Input() title: string = '';

  /** time to be returned */
  @Output() editedTime = new EventEmitter<string>();

  public timeCtrl: FormControl = new FormControl();

  public enabled: boolean = false;

  public titleClass: string = '';
  public timepickerClass: string = 'col-sm-12 col-md-12 col-lg-12';

  constructor() { }

  ngOnInit() {
    if (this.title) {
      this.titleClass = 'col-sm-12 col-md-6 col-lg-6';
      this.timepickerClass = 'col-sm-12 col-md-6 col-lg-6';
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedTime != undefined) {
      this.timeCtrl.setValue(changes.selectedTime.currentValue);
    }

    if (changes.min != undefined && changes.max != undefined) {
      this.enabled = false;

      if (changes.min.currentValue != changes.max.currentValue) {
        this.enabled = true;
      }
    }
  }

  changeTime($event) {
    this.editedTime.emit($event);
  }
}
