import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IKeyword } from '../../../core/interfaces/common/keyword';

@Injectable()
export class KeywordsFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      keywords: this.fb.array([])
    });
  }

  get keywordsForm() {
    return this.form.get('keywords') as FormArray;
  }

  addKeyword() {
    const keywords = this.fb.group({
      id: [null],
      word: [null, Validators.required]
    });

    //this.keywordsForm.push(keywords);
    this.keywordsForm.insert(-9999999, keywords); //push item to begin of list
    this.form.markAsDirty();
  }

  addKeywordWithValue(keyword: IKeyword) {
    const obj = this.fb.group({
      id: [keyword.id],
      word: [keyword.word, Validators.required]
    });

    this.keywordsForm.push(obj);
    this.form.markAsDirty();
  }

  removeKeyword(index: number) {
    this.keywordsForm.removeAt(index);
    this.form.markAsDirty();
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }

  createKeywordDtos(): IKeyword[] {
    const data = this.form.value;

    const keywords: IKeyword[] = [];

    Object(data.keywords).forEach(x => {
      const keyword: IKeyword = {
        id: x.id,
        word: x.word
      };

      keywords.push(keyword);
    });

    return keywords;
  }
}
