import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Output, EventEmitter, DoCheck } from '@angular/core';
import { CalendarDayViewBeforeRenderEvent, CalendarEvent, CalendarEventTimesChangedEvent, CalendarMonthViewDay, CalendarView, CalendarWeekViewBeforeRenderEvent, DAYS_OF_WEEK } from 'angular-calendar';
import { addHours, differenceInMinutes, isSameDay, isSameMinute, isSameMonth, startOfDay, startOfHour } from 'date-fns';
import { ISelection } from '../../../../core/interfaces/common/selection';
import * as moment from 'moment';
import { select, Store } from '@ngrx/store';
import * as fromI18n from '../../../../../i18n/reducers';
import { BehaviorSubject, Subject } from 'rxjs';
import { MatDialog } from '@angular/material';
import { AppointmentComponent } from '../../dialogs/appointment/appointment.component';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { GetNewDateFromDateAndTime } from '../../../../core/functions/date-function';
import { IGoogleEvents } from '../../../../core/interfaces/common/google-events';
import { WeekViewHourSegment } from 'calendar-utils';

interface EventGroupMeta {
  type: string;
}

// weekStartsOn option is ignored when using moment, as it needs to be configured globally for the moment locale
//moment.updateLocale('en', {
//  week: {
//    dow: DAYS_OF_WEEK.MONDAY,
//    doy: 0,
//  },
//});

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None // hack to get the styles to apply locally
})

export class SchedulerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() operators: ISelection[] = [];
  @Input() events: CalendarEvent[] = [];
  @Input() googleEvents: IGoogleEvents[] = [];
  @Input() officeWorkSchedules: IOfficeOperatorWorkSchedule[];
  @Input() operatorWorkSchedules: any;

  @Input() view: CalendarView;
  @Input() viewDate: Date;

  @Output() viewChange = new EventEmitter<CalendarView>();
  @Output() viewDateChange = new EventEmitter<Date>();

  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef<HTMLElement>;

  //public viewDate = new Date();

  public dayStartHour: number;
  public dayStartMinute: number;
  public dayEndHour: number;
  public dayEndMinute: number;

  public weekStartsOn: number = DAYS_OF_WEEK.MONDAY;

  public operatorName: string = '';

  public activeDayIsOpen: boolean = false;

  public locale: string = 'en';
  public localeSbj: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public refresh: Subject<any> = new Subject();

  constructor(private cdr: ChangeDetectorRef,
    private readonly store: Store<fromI18n.State>,
    private dialog: MatDialog
  ) {
    this.store.pipe(select(fromI18n.getCurrentLanguage)).subscribe(x => {
      this.localeSbj.next(x);
    });

    this.localeSbj.subscribe(x => {
      this.locale = x;
      this.refresh.next();
    });
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.officeWorkSchedules != undefined) {
      this.officeWorkSchedules = changes.officeWorkSchedules.currentValue;

      this.loadOfficeWorkSchedule();
    }

    if (changes.operators != undefined) {
      this.operators = changes.operators.currentValue;
      this.loadOperatorName();
      this.activeDayIsOpen = false;
    }

    if (changes.events != undefined) {
      this.events = changes.events.currentValue;
    }

    if (changes.googleEvents != undefined) {
      this.googleEvents = changes.googleEvents.currentValue;
    }
  }

  ngAfterViewInit() {
    this.scrollToCurrentView();
  }

  viewChanged() {
    this.cdr.detectChanges();
    this.scrollToCurrentView();
  }

  loadOfficeWorkSchedule() {
    var allStartHour = this.officeWorkSchedules.map(function (x) {
      const date: Date = GetNewDateFromDateAndTime(new Date(), x.startHour);

      return date;
    });

    var earliestStartHour = new Date(Math.min.apply(null, allStartHour));

    var allEndHour = this.officeWorkSchedules.map(function (x) {
      const date: Date = GetNewDateFromDateAndTime(new Date(), x.endHour);

      return date;
    });

    var latestStartHour = new Date(Math.max.apply(null, allEndHour));

    this.dayStartHour = earliestStartHour.getHours();
    this.dayStartMinute = earliestStartHour.getMinutes();
    this.dayEndHour = latestStartHour.getHours();
    this.dayEndMinute = latestStartHour.getMinutes();
  }

  loadOperatorName() {
    //console.log("operators=", this.operators, this.view);
    this.operatorName = this.operators.length > 0 ? this.operators[0].text : '';
  }

  private scrollToCurrentView() {
    if (this.view === CalendarView.Week || CalendarView.Day) {
      // each hour is 60px high, so to get the pixels to scroll it's just the amount of minutes since midnight
      const minutesSinceStartOfDay = differenceInMinutes(
        startOfHour(new Date()),
        startOfDay(new Date())
      );
      const headerHeight = this.view === CalendarView.Week ? 60 : 0;
      this.scrollContainer.nativeElement.scrollTop =
        minutesSinceStartOfDay + headerHeight;
    }
  }

  beforeMonthViewRender({
    body,
  }: {
    body: CalendarMonthViewDay<EventGroupMeta>[];
  }): void {
    // month view has a different UX from the week and day view so we only really need to group by the type
    body.forEach((cell) => {
      const groups = {};
      cell.events.forEach((event: CalendarEvent<EventGroupMeta>) => {
        groups[event.meta.type] = groups[event.meta.type] || [];
        groups[event.meta.type].push(event);
      });
      cell['eventGroups'] = Object.entries(groups);
    });
  }

  beforeDayViewRender(body: CalendarDayViewBeforeRenderEvent): void {
    let index = -1;

    body.hourColumns.forEach(hourCol => {
      index = index + 1;

      hourCol.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          //if (!this.segmentIsValid(index, segment.date)) {
          //  segment.cssClass = 'cal-disabled';
          //}

          this.checkSegment(segment, index);
        })
      })
    });
  }

  beforeWeekViewRender(body: CalendarWeekViewBeforeRenderEvent): void {
    body.hourColumns.forEach(hourCol => {
      hourCol.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          //if (!this.segmentIsValid(0, segment.date)) {
          //  segment.cssClass = 'cal-disabled';
          //}

          this.checkSegment(segment, 0);
        })
      })
    });
  }

  eventTimesChanged({ event, newStart, newEnd, }: CalendarEventTimesChangedEvent): void {
    event.start = newStart;
    event.end = newEnd;
    this.events = [...this.events];
  }

  userChanged({ event, newUser }) {
    event.meta.user = newUser;
    this.events = [...this.events];
  }

  dayClicked({
    date,
    events,
  }: {
    date: Date;
    events: CalendarEvent[];
  }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    this.openAppointmentDialog(event.meta.appointment);
  }

  hourSegmentClicked(event) {
    if (!(event.sourceEvent.target.className.includes('cal-disabled')) &&
      !(event.sourceEvent.target.className.includes('cal-gc-event'))) {
      const date: Date = event.date;

      const startHour = ("00" + (date.getHours())).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2);

      const appointment: IAppointment = {
        id: null,
        customerCode: null,
        date: date,
        startHour: startHour,
        endHour: null,
        notes: null,
        createdOnUtc: new Date,
        mainAppointmentId: null,
        clientAppointmentId: null,
        clientStartHour: null,
        clientEndHour: null,
        office: null,
        service: null,
        operator: null,
        status: null,
        client: null,
      }

      //this.openAppointmentDialog(appointment);
    }
  }

  openAppointmentDialog(appointment: IAppointment) {
    this.dialog.open(AppointmentComponent, {
      width: "80rem",
      minWidth: "30rem",
      data: appointment
    });
  }

  //segmentIsValid(index: number, date: Date) {
  //  let isValid: boolean = false;
  //  const day = date.getDay();

  //  if (this.officeWorkSchedules.length > 0) {
  //    const workSchedules = this.officeWorkSchedules.filter(x => x.dayOfTheWeek == day);
  //    if (workSchedules.length > 0) {
  //      let exitLoop: boolean = false;

  //      workSchedules.forEach(schedule => {
  //        if (exitLoop) {
  //          return;
  //        }

  //        const startHour = GetNewDateFromDateAndTime(date, schedule.startHour);
  //        const endHour = GetNewDateFromDateAndTime(date, schedule.endHour);

  //        if (startHour <= date && date <= endHour) {
  //          isValid = true;
  //          exitLoop = true;
  //        }
  //        else {
  //          isValid = false;
  //        }
  //      });
  //    }

  //    if (isValid && this.operatorWorkSchedules != null && this.operatorWorkSchedules.length > 0) {
  //      const operatorWorkSchedules: IOfficeOperatorWorkSchedule[] = this.operatorWorkSchedules[index].operatorWorkSchedules;
  //      if (operatorWorkSchedules.length > 0) {
  //        const workSchedules = operatorWorkSchedules.filter(x => x.dayOfTheWeek == day);
  //        if (workSchedules.length > 0) {
  //          let exitLoop: boolean = false;

  //          workSchedules.forEach(schedule => {
  //            if (exitLoop) {
  //              return;
  //            }

  //            const startHour = GetNewDateFromDateAndTime(date, schedule.startHour);
  //            const endHour = GetNewDateFromDateAndTime(date, schedule.endHour);

  //            if (startHour <= date && date <= endHour) {
  //              isValid = true;
  //              exitLoop = true;
  //            }
  //            else {
  //              isValid = false;
  //            }
  //          });
  //        }
  //      }
  //    }
  //  }

  //  return isValid;
  //}

  checkSegment(segment: WeekViewHourSegment, index: number = 0) {
    const date = segment.date;

    if (this.officeWorkSchedules.length > 0) {
      const workSchedules = this.officeWorkSchedules.filter(x => x.dayOfTheWeek == date.getDay());

      if (workSchedules.length == 0) {
        segment.cssClass = 'cal-disabled';

        return;
      }

      const schedule = workSchedules.find(x =>
        GetNewDateFromDateAndTime(date, x.startHour) <= date && date <= GetNewDateFromDateAndTime(date, x.endHour));

      if (!schedule) {
        segment.cssClass = 'cal-disabled';

        return;
      }

      if (this.operatorWorkSchedules != null && this.operatorWorkSchedules.length > 0) {
        const operatorWorkSchedules: IOfficeOperatorWorkSchedule[] = this.operatorWorkSchedules[index].operatorWorkSchedules;
        if (operatorWorkSchedules.length > 0) {
          const workSchedules = operatorWorkSchedules.filter(x => x.dayOfTheWeek == date.getDay());
          if (workSchedules.length == 0) {
            segment.cssClass = 'cal-disabled';

            return;
          }

          const schedule = workSchedules.find(x =>
            GetNewDateFromDateAndTime(date, x.startHour) <= date && date <= GetNewDateFromDateAndTime(date, x.endHour));

          if (!schedule) {
            segment.cssClass = 'cal-disabled';

            return;
          }
        }
      }

      const operator: ISelection = this.operators[index];
      const googleEvents = this.googleEvents.find(x => x.operator.id == operator.value);

      if (googleEvents.events.length > 0) {
        const event = googleEvents.events.find(x => new Date(x.start.dateTime) <= date && date < new Date(x.end.dateTime));

        if (event) {
          segment.cssClass = 'cal-gc-event';

          return;
        }
      }
    }
  }
}
