import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class MatPaginatorI18n extends MatPaginatorIntl implements OnDestroy {
  private onDestroy$: Subject<boolean> = new Subject();

  constructor(private readonly translate: TranslateService) {
    super();

    this.translate
      .stream([
        "paginator.itemsPerPage",
        "paginator.nextPage",
        "paginator.previousPage",
        "paginator.firstPage",
        "paginator.lastPage"
      ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(translations => {
        this.itemsPerPageLabel = translations["paginator.itemsPerPage"];
        this.nextPageLabel = translations["paginator.nextPage"];
        this.previousPageLabel = translations["paginator.previousPage"];
        this.firstPageLabel = translations["paginator.firstPage"];
        this.lastPageLabel = translations["paginator.lastPage"];
        //this.getRangeLabel = this.getRangeLabel.bind(this);
        this.getRangeLabel = this.rangeLabel;

        this.changes.next();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    {


      //this.getRangeLabel = (page: number, pageSize: number, length: number): string => {
      //  if (length === 0 || pageSize === 0) {
      //    return this.translate.instant("MATERIAL.PAGINATOR.RANGE_LABEL_NO_ITEMS", { length });
      //  }

      //  length = Math.max(length, 0);

      //  const startIndex = page * pageSize;
      //  // If the start index exceeds the list length, do not try and fix the end index to the end.
      //  const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      //  return this.translate.instant("MATERIAL.PAGINATOR.RANGE_LABEL", {
      //    startIndex: startIndex + 1,
      //    endIndex,
      //    length
      //  });
      //}
    }
  }

  rangeLabel = (page: number, pageSize: number, length: number) => {
    if (length == 0 || pageSize == 0) { return `0 ${this.translate.instant("paginator.of")} ${length}`; }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.translate.instant("paginator.of")} ${length}`;
  }
}
