import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import * as fromI18n from '../../reducers' ;
import { Language } from '../../models/language.model';
import { LanguageActions } from '../../actions';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  currentLanguage$: Observable<Language>;

  constructor(private readonly store: Store<fromI18n.State>) {
    this.currentLanguage$ = store.pipe(select(fromI18n.getCurrentLanguage));
  }

  ngOnInit(): void {}

  setLanguage(language: Language) {
    this.store.dispatch(LanguageActions.set({ language }));
  }
}
