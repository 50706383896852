import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IOfficeOperatorService } from '../../../../core/interfaces/customer/office-operator-service';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { IOperator } from '../../../../core/interfaces/customer/operator';
import { IService } from '../../../../core/interfaces/customer/service';

@Injectable()
export class OperatorsWorkingHoursFormService {
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      operators: this.fb.array([])
    });
  }

  get operatorsForm(): FormArray {
    return this.form.get('operators') as FormArray;
  }

  addOperatorWithValue(operator: IOperator, isChecked: boolean, operatorServices: IService[], officeOperatorServices: IOfficeOperatorService[], schedule: IOfficeOperatorWorkSchedule[]) {
    const op = this.fb.group({
      operator: [operator, null],
      isChecked: [isChecked, false],
      operatorServices: [operatorServices, []],
      officeOperatorServices: [officeOperatorServices, []],
      schedule: [schedule, []]
    });

    this.operatorsForm.push(op);
    this.form.markAsDirty();
  }

  getFormControl(property: string): FormControl {
    return this.form.get(property) as FormControl;
  }
}
