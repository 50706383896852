import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AuthorizeRoleGuard } from '../api-authorization/authorize-role.guard';
import { InitialRoutingComponent } from './initial-routing/initial-routing.component';
import { ManagementLayoutComponent } from './layout/management/management-layout/management-layout.component';

const routes: Routes = [
  {
    path: 'management',
    component: ManagementLayoutComponent,
    loadChildren: () => import('./management/management.module').then(mod => mod.ManagementModule),
    //canActivate: [AuthorizeRoleGuard]
  },
  {
    path: 'appointments',
    loadChildren: () => import('./appointments/appointments.module').then(mod => mod.AppointmentsModule),
  },
  {
    path: 'external',
    loadChildren: () => import('./external/external.module').then(mod => mod.ExternalModule),
  },
  {
    path: '',
    pathMatch:'full',
    component:InitialRoutingComponent
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes),
    NgxMaterialTimepickerModule
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
