import { Injectable } from '@angular/core';
import { INotificationSettings } from '../../../core/interfaces/common/notification-settings';
import { NotificationSettingsFormService } from './notification-settings-form.service';

@Injectable()
export class NotificationSettingsLoaderService {

  constructor(private notificationSettingsFormService: NotificationSettingsFormService) { }

  loadNotificationSettings(notificationSettings: INotificationSettings) {
    this.notificationSettingsFormService.form.patchValue({
      id: notificationSettings.id,
      enableEmail: notificationSettings.enableEmail,
      email: notificationSettings.email,
      enableSms: notificationSettings.enableSms,
      smsPhone: notificationSettings.smsPhone,
      enableVoice: notificationSettings.enableVoice,
      voicePhone: notificationSettings.voicePhone
    });

    this.checkValidators(notificationSettings.enableEmail, ['email']);
    this.checkValidators(notificationSettings.enableSms, ['smsPhone']);
    this.checkValidators(notificationSettings.enableVoice, ['voicePhone']);
  }

  checkValidators(setValidators: boolean, validators: string[]) {
    if (setValidators) {
      this.notificationSettingsFormService.setValidators(validators);
    }
    else {
      this.notificationSettingsFormService.clearValidators(validators);
    }
  }
}
