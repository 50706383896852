import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { GetNextDay } from '../../../../core/functions/date-function';
import { IAppointment } from '../../../../core/interfaces/customer/appointment';
import { IOfficeOperatorWorkSchedule } from '../../../../core/interfaces/customer/office-operator-work-schedule';
import { ISettings } from '../../../../core/interfaces/customer/settings';

@Component({
  selector: 'app-date-picker-icon',
  templateUrl: './date-picker-icon.component.html',
  styleUrls: ['./date-picker-icon.component.scss']
})
export class DatePickerIconComponent implements OnInit, OnChanges {
  /** selected date */
  @Input() viewDate: Date;
  @Input() allOfficeOperatorWorkSchedule: IOfficeOperatorWorkSchedule[] = [];
  @Input() appointment: IAppointment = null;

  @Input() useMinDate: boolean = false;

  /** date to be returned */
  @Output() viewDateChange = new EventEmitter<Date>();

  public date: FormControl = new FormControl();
  public minDate: Date = new Date();

  constructor() { }

  ngOnInit() {
    if (this.viewDate != null) {
      this.date.setValue(new Date(this.viewDate));
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDate != undefined) {
      this.viewDate = changes.viewDate.currentValue;
      this.date.setValue(new Date(this.viewDate));
    }

    if (changes.appointment != undefined && changes.appointment !=null) {
      this.appointment = changes.appointment.currentValue;
    }

    if (changes.allOfficeOperatorWorkSchedule != undefined && changes.allOfficeOperatorWorkSchedule != null) {
      this.allOfficeOperatorWorkSchedule = changes.allOfficeOperatorWorkSchedule.currentValue;
    }
  }

  onDisableDates(date: any): boolean {
    const selectedDate = new Date(date);
    const day = selectedDate.getDay();

    let schedules = this.allOfficeOperatorWorkSchedule.filter(x => x.officeId == this.appointment.office.id);
    return schedules.findIndex(x=>x.dayOfTheWeek===day)>=0;
  }

  
}
