import { Action, createReducer, on } from '@ngrx/store';
import { Language } from '../models/language.model';
import { LanguageActions } from '../actions';


export const languageFeatureKey = 'language';

export interface State {
  currentLanguage: Language
}

export const initialState: State = {
  currentLanguage: getUserLanguage() as Language
};


export const reducer = createReducer(
  initialState,
  on(LanguageActions.set, (state, { language }) => ({
    ...state,
    currentLanguage: language
  }))
);

export const getLanguage = (state: State) => state.currentLanguage;

function getUserLanguage(): string {
  const navigatorLanguage = navigator.language.toLowerCase();

  if (navigatorLanguage.indexOf('en') > -1) {
    return 'en';
  }

  if (navigatorLanguage.indexOf('it') > -1) {
    return 'it';
  }

  if (navigatorLanguage.indexOf('ro') > -1) {
    return 'ro';
  }

  return 'en';
}
